import jwt_decode from 'jwt-decode';
import { AppSettings } from './app.settings';
import { isJwtExpired } from 'jwt-check-expiration';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { AppRoles } from './app.roles';


export class AppUtilities {

  private static decrypt(textToDecrypt : string){
    try{
      let secretKey = environment.SecretKeyForEncryptionDescryption;
      return CryptoJS.AES.decrypt(textToDecrypt, secretKey.trim()).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return null
    }
  }

  public static compare(property) {
    return function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1;
      } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  /**
   * Gets decoded access token
   * @returns decoded access token
   */
  public static getDecodedAccessToken(): any {
    try {
      const data = JSON.parse(this.decrypt(localStorage.getItem('authData')));
      const encodedToken = data.token; //accessToken
      return jwt_decode(encodedToken);
    } catch (Error) {
      return null;
    }
  }


   /**
   * Inserts the values into another string.
   * @param theString string to format
   * @param values values to add in the string
   */
    public static StringFormat(theString: string, values: string[]): string {
      for (let i = 0; i < values.length; i++) {
        // "gm" = RegEx options for Global search (more than one instance)
        // and for Multiline search
        const regEx = new RegExp("\\{" + i + "\\}", "gm");
        theString = theString.replace(regEx, values[i]);
      }

      return theString;
    }

  /**
   *
   * @returns true if user is Authenticated and token did not expire
   */
   public static isUserAuthenticated() {
    if (this.decrypt(localStorage.getItem(AppSettings.authDataKey)) && this.decrypt(localStorage.getItem(AppSettings.authDataKey)) != undefined) {
      const authData = this.decrypt(localStorage.getItem(AppSettings.authDataKey));
      const authDataObj = JSON.parse(authData);
      const encodedToken = authDataObj.token;
      if (!isJwtExpired(encodedToken)) {
        return true
      }
    }
    return false
  }


    /**
   *
   * @returns authentication token if user is Authenticated and token did not expire
   * otherwise returns null
   */
    public static getAuthToken() {
      if (this.decrypt(localStorage.getItem(AppSettings.authDataKey)) && this.decrypt(localStorage.getItem(AppSettings.authDataKey)) != undefined) {
        const authData = this.decrypt(localStorage.getItem(AppSettings.authDataKey));
        const authDataObj = JSON.parse(authData);
        const encodedToken = authDataObj.token;
        if (!isJwtExpired(encodedToken)) {
          return authDataObj.token;
        }
      }
      return null
    }

    /**
     * Format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    public static formatBytes(bytes, decimals) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


  /**
   * Sums app utilities
   * @param object
   * @param key
   * @returns
   */
  public static sum(object, key) {
    return object.reduce((a, b) => (a) + (b[key] || 0), 0);
  }

  /** We Assume that powerbi roles end with SUPERINTENDENT and primary user role
   * ends with ADMIN, otherwise return the primary role without changes
   */
  public static getPowerbiRole() {
    let roleObj  = this.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey));
    if (roleObj != undefined && roleObj != null) {
      let res = JSON.parse(roleObj)
      let roleId=res.primaryRoleAssignedId;
      let roleName = res.roleName;
      //Assign the Private HEI Admin and Vice Presidents the same role as HEI Superintendent
      if(roleId == AppRoles.HED.PHEI_ADMIN || roleId==AppRoles.HED.VP_ACADEMIC ||
       roleId==AppRoles.HED.VP_ADMINISTRATION || roleId==AppRoles.HED.VP_DEVELOPMENT){
        return 'HEI_SUPERINTENDENT';
      }
      // if(roleName=='ETA_ADMIN'){
      //   return 'MINISTRY_SUPERINTENDENT';
      // }

      let newRole = roleName.replace("ADMIN", "SUPERINTENDENT");
      return newRole;
    }
  }

  /** Get the Role Id of the connected user
   */
  public static getUserRoleId() {
    let roleObj  = this.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey));
    if (roleObj != undefined && roleObj != null) {
      let res = JSON.parse(roleObj)
      let roleId=res.primaryRoleAssignedId;
      return roleId;
    }
  }



  /**Gets the selected user institution from local storage */
  public static getSelectedInstitution() {
    let selectedInstitution = this.decrypt(localStorage.getItem(AppSettings.institutionKey));
    return selectedInstitution;
  }


}
