import { Component, OnInit } from '@angular/core';
import { Toast, ToasterService } from 'angular2-toaster';
import { UploadLogoService } from '../../../../../../services/upload-logo.service'
@Component({
  selector: 'app-nav-search',
  templateUrl: './nav-search.component.html',
  styleUrls: ['./nav-search.component.scss']
})
export class NavSearchComponent implements OnInit {
  allFiles: any;

  constructor(private logoService: UploadLogoService,
    private toasterService: ToasterService) {
    
  }

  ngOnInit() {
    //this.getFiles();
  }

  getFiles(){
    this.logoService.getFiles().then(res => {
      this.allFiles = res;
    })
  }

  uploadLogo(logo){
    this.logoService.upload(logo).then(res => {
      this.callToaster('success', null, "Logo added",5000 );
    })
  }

  /**
   * Calls toaster
   * @param toasterTitle
   * @param toasterBody
   */
  callToaster(type, toasterTitle, toasterBody,timeout) {
      const toaster: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
      };
      this.toasterService.pop(toaster);
  }
}
