<div class="auth-wrapper">
  <div class="auth-content">
    <div class="auth-bg">
      <span class="r"></span>
      <span class="r s"></span>
      <span class="r s"></span>
      <span class="r"></span>
    </div>
    <div class="card">
      <div class="card-body text-center">
          <form [formGroup]="accountInfo" (ngSubmit)="onSubmit()">
        <div class="mb-4">
          <i class="feather icon-mail auth-icon"></i>
        </div>
        
        <h4 class="mb-4">{{ 'connectionConfirmation.messageVerification' | translate }}</h4>
        <div class="input-group mb-3">
          <input (keyup)="checkButton()" type="number" class="form-control"  formControlName="code"  placeholder="{{ 'connectionConfirmation.Code' | translate }}" required>
        </div>
        <button  [disabled]="disableSendButton !== false" pButton pRipple icon="pi pi-check" label="Confirm" class="p-button-text valid-button" ></button>
          </form>
      </div> 
    </div>
  </div> 
</div>
   