<div class="row">
    <div class="col-sm-12">


        <app-card cardTitle="{{ 'PolicyGoals.PolicyGoals' | translate }}" [options]="false">

            <div class="form-row">
                <div class="form-group col-md-3">
                </div>
                <div class="form-group col-md-3 label-align">
                    <label for="inputAcademicYear">
                        <h5 class="policy-title"> {{ 'PolicyGoals.SelectStakeholder' | translate }} </h5>
                    </label>
                    <!-- Select Stakeholders-->
                    <ng-select [items]="stakeholders" id='stakeholder' bindLabel="stakeholderDescription" autofocus
                        placeholder="{{ 'PolicyGoals.SelectStakeholder' | translate }}" bindValue="stakeholderId"
                        [(ngModel)]="selectedStakeHolders" (change)="stakeHoldersChanged()">
                    </ng-select>
                </div>
                <div class="form-group col-md-3 label-align">
                    <label *ngIf="isHed == true" for="inputAcademicYear">
                        <h5 class="policy-title"> {{ 'PolicyGoals.SelectHEIClass' | translate }} </h5>
                    </label>
                    <!-- Select Stake Holders-->
                    <ng-select *ngIf="isHed== true" [items]="heiClasses" id='heiClasses' bindLabel="className" autofocus
                        placeholder="{{ 'PolicyGoals.SelectHEIClass' | translate }}" bindValue="classId"
                        [(ngModel)]="selectedHeiClass" (change)="heiClassChanged()">
                    </ng-select>
                </div>
                <div class="form-group col-md-3">
                </div>
            </div>


            <p-table [resizableColumns]="true" [value]="policyList" responsiveLayout="scroll"
                dataKey="policyObjectiveName" *ngIf="policyList">
                <ng-template pTemplate="caption">
                    <div class="row">
                        <div class="col-md-3">

                        </div>
                        <div class="col-md-3 offset-md-6  global-search" style="text-align: right;">
                            <span *ngIf="policyList != undefined &&  policyList != null && policyList.length > 0"
                                class="p-input-icon-left p-ml-auto">
                                <span name="edit" class="fa fa-pencil-alt fa-lg green ml-2" title="Edit Data"
                                    (click)="editRecords()"></span>
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pResizableColumn style="width: 77px;">{{ 'PolicyGoalsTable.Action' | translate }}</th>
                        <th pResizableColumn pSortableColumn="policyObjectiveDescription" style="width: 150px;">
                            {{ 'PolicyGoalsTable.PolicyDescription' | translate }} <p-sortIcon
                                field="policyObjectiveDescription">
                            </p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 120px;" pSortableColumn="weight">{{ 'PolicyGoalsTable.Weight'
                            | translate }}<p-sortIcon field="weight"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 120px;" pSortableColumn="minScore">{{
                            'PolicyGoalsTable.MinScore' | translate }}<p-sortIcon field="minScore"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 150px;" pSortableColumn="baseScore">{{
                            'PolicyGoalsTable.BaseScore' | translate }}<p-sortIcon field="baseScore"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 150px;" pSortableColumn="targetScore">{{
                            'PolicyGoalsTable.TargetScore' | translate }}<p-sortIcon field="targetScore"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 120px;" pSortableColumn="maxScore">{{
                            'PolicyGoalsTable.MaxScore' | translate }}<p-sortIcon field="maxScore"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-expanded="expanded">
                    <tr>
                        <td>
                            <button (click)="showDetails(product)" type="button" pButton pRipple [pRowToggler]="product"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>
                        <td>
                            <div>
                                <span class="policy-description"> {{product.policyObjectiveDescription}}</span>
                            </div>
                        </td>
                        <td class="label-align">{{product.weight}}</td>
                        <td class="label-align">{{product.minScore}}</td>
                        <td class="label-align">{{product.baseScore}}</td>
                        <td class="label-align">{{product.targetScore}}</td>
                        <td class="label-align">{{product.maxScore}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-product>
                    <tr>
                        <td style="background-color: #a5bae1" colspan="7">
                            <div class="p-p-3">
                                <p-table [resizableColumns]="true" [value]="policyDetailsList" responsiveLayout="scroll"
                                    id="performanceIndicatorDescription" dataKey="id" *ngIf="policyDetailsList">
                                    <ng-template style=" background-color:  #dfe5ef;" pTemplate="caption">
                                        <div class="row">
                                            <div class="col-md-3">
                                            </div>
                                            <div class="col-md-3 offset-md-6  global-search" style="text-align: right;">
                                                <span class="p-input-icon-left p-ml-auto">
                                                    <span name="edit" class="fa fa-pencil-alt fa-lg green ml-2"
                                                        title="Edit Data" (click)="editDetails()"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                    <tr>
                        <th style=" background-color:  #eaf0fb;" pResizableColumn
                            pSortableColumn="performanceIndicatorDescription">{{ 'PolicyGoalsTable.IndicatorDescription'
                            | translate }}<p-sortIcon field="performanceIndicatorDescription"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 120px; background-color:  #eaf0fb;" pSortableColumn="weight">
                            {{ 'PolicyGoalsTable.Weight' | translate }} <p-sortIcon field="weight"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 120px; background-color:  #eaf0fb;"
                            pSortableColumn="minScore">{{ 'PolicyGoalsTable.MinScore' | translate }}<p-sortIcon
                                field="minScore"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 150px; background-color:  #eaf0fb;"
                            pSortableColumn="baseScore">{{ 'PolicyGoalsTable.BaseScore' | translate }}<p-sortIcon
                                field="baseScore"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 150px; background-color:  #eaf0fb;"
                            pSortableColumn="targetScore">{{ 'PolicyGoalsTable.TargetScore' | translate }}<p-sortIcon
                                field="targetScore"></p-sortIcon>
                        </th>
                        <th pResizableColumn style="width: 120px; background-color:  #eaf0fb;"
                            pSortableColumn="maxScore">{{ 'PolicyGoalsTable.MaxScore' | translate }}<p-sortIcon
                                field="maxScore"></p-sortIcon>
                        </th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order>
                    <tr [pEditableRow]="policyDetailsList">
                        <td class="body-tr-background">
                            <div>
                                <span class="policy-description"> {{order.performanceIndicatorDescription}}</span>
                            </div>
                        </td>
                        <td class="body-tr-background label-align">{{order.weight}}</td>
                        <td class="body-tr-background label-align">{{order.minScore}}</td>
                        <td class="body-tr-background label-align">{{order.baseScore}}</td>
                        <td class="body-tr-background label-align">{{order.targetScore}}</td>
                        <td class="body-tr-background label-align">{{order.maxScore}}</td>
                    </tr>
                </ng-template>
            </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
            </p-table>

    </app-card>

</div>

</div>


<p-dialog [(visible)]="productDialog" [baseZIndex]="9999" [style]="{width: '60%'}" header="Update Policy Goals"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">

        <div class="form-row">

            <div class="form-group col-3 text-align">
                <label>{{ 'PolicyGoalsTable.MinScore' | translate }}</label>
                <input type="number" class="form-control" pInputText id="minScore" min="0"
                    oninput="validity.valid||(value='');" [(ngModel)]="minScore" autofocus />
            </div>
            <div class="form-group col-3 text-align">
                <label>{{ 'PolicyGoalsTable.BaseScore' | translate }}</label>
                <input type="number" class="form-control" pInputText id="baseScore" min="0"
                    oninput="validity.valid||(value='');" [(ngModel)]="baseScore" autofocus />
            </div>
            <div class="form-group col-3 text-align">
                <label>{{ 'PolicyGoalsTable.TargetScore' | translate }}</label>
                <input type="number" class="form-control" pInputText id="targetScore" min="0"
                    oninput="validity.valid||(value='');" [(ngModel)]="targetScore" autofocus />
            </div>
            <div class="form-group col-3 text-align">
                <label>{{ 'PolicyGoalsTable.MaxScore' | translate }}</label>
                <input type="number" class="form-control" pInputText id="maxScore" min="0"
                    oninput="validity.valid||(value='');" [(ngModel)]="maxScore" autofocus />
            </div>
        </div>

        <!--Name -->
        <div *ngFor="let policy of policyListClone">
            <div class="form-row">
                <div style="align-self: end;" class="form-group col-8">
                    <label for="firstName">{{policy.policyObjectiveDescription}}</label> <br>
                </div>
                <div class="form-group col-4 text-align">
                    <label>{{ 'PolicyGoalsTable.Weight' | translate }}</label>
                    <input type="number" class="form-control" pInputText id="{{policy.policyObjectiveId}}" min="0"
                        [(ngModel)]="policy.weight" autofocus />
                </div>

            </div>
        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button style="color: green;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="saveProduct()"></button>
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="productDetailsDialog" [style]="{width: '60%'}" [maximizable]="true" [baseZIndex]="10000"
    header="Update Performance Indicators" [modal]="true" styleClass="testimonials p-fluid">
    <ng-template pTemplate="content">

        <!--Name -->
        <div *ngFor="let policy of policyDetailsListClone">
            <div class="form-row">

                <div class="form-group col-lg-4">
                    <label for="firstName">{{policy.performanceIndicatorDescription}}</label> <br>
                </div>
                <div class="form-group col-md label-align">
                    <label>{{ 'PolicyGoalsTable.Weight' | translate }}</label>
                    <input type="number" class="form-control" pInputText id="{{policy.performanceIndicatorId}}" min="0"
                        oninput="validity.valid||(value='');" [(ngModel)]="policy.weight" autofocus />
                </div>

                <div class="form-group col-md label-align">
                    <label>{{ 'PolicyGoalsTable.MinScore' | translate }}</label>
                    <input type="number" class="form-control" pInputText id="{{policy.performanceIndicatorId}}" min="0"
                        oninput="validity.valid||(value='');" [(ngModel)]="policy.minScore" autofocus />
                </div>
                <div class="form-group col-md label-align">
                    <label>{{ 'PolicyGoalsTable.BaseLine' | translate }}</label>
                    <input type="number" class="form-control" pInputText id="{{policy.performanceIndicatorId}}" min="0"
                        oninput="validity.valid||(value='');" [(ngModel)]="policy.baseScore" autofocus />
                </div>
                <div class="form-group col-md label-align">
                    <label>{{ 'PolicyGoalsTable.TargetScore' | translate }}</label>
                    <input type="number" class="form-control" pInputText id="{{policy.performanceIndicatorId}}" min="0"
                        oninput="validity.valid||(value='');" [(ngModel)]="policy.targetScore" autofocus />
                </div>
                <div class="form-group col-md label-align">
                    <label>{{ 'PolicyGoalsTable.MaxScore' | translate }}</label>
                    <input type="number" class="form-control" pInputText id="{{policy.performanceIndicatorId}}" min="0"
                        oninput="validity.valid||(value='');" [(ngModel)]="policy.maxScore" autofocus />
                </div>

            </div>
        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideDetailsDialog()"></button>
        <button style="color: green;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="saveDetails()"></button>
    </ng-template>
</p-dialog>