import { institution } from './../models/institution/institution';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WelcomeServicesService {

  constructor(private http: HttpClient) { }


  getAllUsersSteps(institutionId):Promise<any> {
    return this.http.get<any>(`${environment.getStepsUrl}/${institutionId}`).toPromise()
    .then(response => response as any);
  }

  GetFilesToValidateByInstitution(institutionId):Promise<any> {
    return this.http.get<any>(`${environment.GetFilesToValidateByInstitution}/${institutionId}`).toPromise()
    .then(response => response as any);
  }

  hasViewDashboardRole():Promise<any> {
    return this.http.get<any>(`${environment.viewDashboardRole}`).toPromise()
    .then(response => response as any);
  }

  getUserInstitutions():Promise<any> {
    return this.http.get<any>(`${environment.addInstitution.getUserInstitutions}`).toPromise()
    .then(response => response as any);
  }

  getValidationProgress(institutionId):Promise<any> {
    return this.http.get<any>(`${environment.getValidationProgress}/${institutionId}`).toPromise()
    .then(response => response as any);
  }

  GetSubmissionProgressByInstitution(institutionId):Promise<any> {
    return this.http.get<any>(`${environment.GetSubmissionProgressByInstitution}/${institutionId}`).toPromise()
    .then(response => response as any);
  }

  insertLogMethod(url, description, lang):Promise<any> {
    const data = {
      "historyUrl" : url, "historyText": description , "languageId" : lang
    }
     return this.http.post<any>(`${environment.insertLog}`, data).toPromise()
    .then(response => response as any).catch(error=>{
      return error
    });
  }

  getUserRegions():Promise<any> {
    return this.http.get<any>(`${environment.getUserRegions}`).toPromise()
    .then(response => response as any);
  }
  
  getUserZones():Promise<any> {
    return this.http.get<any>(`${environment.getUserZones}`).toPromise()
    .then(response => response as any);
  }

  getUserWoredas():Promise<any> {
    return this.http.get<any>(`${environment.getUserWoredas}`).toPromise()
    .then(response => response as any);
  }
}
