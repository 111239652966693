import { AuthenticationService } from './../../../../../services/authentication/authentication.service';
import { Component, DoCheck, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { DattaConfig } from '../../../../../app-config';
import { DataService } from './../../../../../services/data.service';
import { User } from './../../../../../models/User'
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from './../../../../../common/app.settings';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { Dialog } from 'primeng/dialog';
import { Location } from '@angular/common';
import { UploadLogoService } from 'src/app/services/upload-logo.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppUtilities } from 'src/app/common/app.utilities';
import { AccountsService } from 'src/app/services/authentication/accounts.service';
import { UserService } from 'src/app/services/user.service';
import { Toast, ToasterService } from 'angular2-toaster';
import { LoaderService } from 'src/app/services/interceptor/loader.service';
import { UserInstitution } from './../../../../../models/institution/userInstitution'
import {  AppRoles } from './../../../../../common/app.roles';

import { WelcomeServicesService } from './../../../../../services/welcome-services.service';
import { environment } from 'src/environments/environment';
import { InstitutionService } from 'src/app/services/institution.service';
import { AESEncryptDecryptService } from 'src/app/services/security/aesencrypt-decrypt.service';
import { Customization } from './../../../../../models/customization'

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public dattaConfig: any;
  username: string;
  selected: string;
  formGroup: FormGroup;
  dataDialog: boolean = false;


  public styleSelectorToggle: boolean; // open configuration menu
  public layoutType: string; // layout type
  public rtlLayout: any; // rtl type
  public menuFixedLayout: any; // menu/navbar fixed flag
  public headerFixedLayout: any; // header fixed flag
  public boxLayout: any; // box layout flag
  public isColoredIcon: any; // menu icon color
  public headerBackgroundColor: string; // header background color
  public navbarBackgroundColor: string; // navbar background color
  public brandBackgroundColor: string; // brand/logo background color
  public navBackgorundImage: any; // navbar background image

  public menuDropdownIcon: string; // navbar background image
  public menuListIcon: string; // navbar background image

  public navActiveColor: string;
  public navTitleColor: string;
  public menuTitleHide: any;

  public headerBackColor: string;

  public isConfig: boolean;
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews;
  imageInfos;
  userId: any;
  userDetails: any;
  registrationForm = this.fb.group({
    file: [null]
  })
  imageUrl: string | ArrayBuffer;


  institutionList: any;
  selectedInstitution: any;
  token: any;
  currentUserDetails: any;
  selectedRegion: any;
  regionList: any;
  selectedZone: any;
  zoneList: any;
  selectedWoreda: string;
  woredaList: any;
  defAcademicYearId: any;
  currentLanguageId: string;
  currentLanguage: string;
  customization : Customization;
  constructor(
    config: NgbDropdownConfig,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private dataService: DataService,
    private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private uploadLogoService: UploadLogoService,
    private accountsService: AccountsService,
    private userService: UserService,
    public welcomeService: WelcomeServicesService,
    private institutionService: InstitutionService,
    private toasterService: ToasterService,
    private loaderService: LoaderService,
    private aesEncryptDecryptService: AESEncryptDecryptService) {

    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.dattaConfig = DattaConfig.config;
    this.setThemeLayout();
  }

  ngOnInit() {
    this.dataService.getCurrentLang().subscribe(data => {
      const lang = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.languageKey));
      if (lang != null) {
        if (lang == AppSettings.amhariLang) {
          this.currentLanguageId = '234'
          this.currentLanguage = "am"
        } else {
          this.currentLanguageId = '123'
          this.currentLanguage = "en"
        }
      }
      else {
        this.currentLanguageId = '123'
        this.currentLanguage = "en"
      }
      this.selected = this.currentLanguage
    });

    this.selectedInstitution = null

    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)) != undefined && this.aesEncryptDecryptService.decrypt(localStorage.getItem('credentials')) != null) {
      let res = JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)))
      this.currentUserDetails = res;
    }

    if (environment.categ == "TVET") {
      this.setRegions();
      this.setZones();
      this.setWoredas();
    }


    if (JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.regionKey))) != null) {
      let region = JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.regionKey)));
      this.selectedRegion = region
    } else {
      this.dataService.getCurrentRegion().subscribe(region => {
        this.selectedRegion = region;
      })
    }

    if (JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.zoneKey))) != null) {
      let selectedZone = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.zoneKey));
      selectedZone = selectedZone.replace("\\","");
      this.selectedZone = JSON.parse(selectedZone);
    } else {
      this.dataService.getCurrentZone().subscribe(zone => {
        this.selectedZone = zone;
        this.setTvetInstitutionsByZone();
      })
    }

    if (JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.woredaKey))) != null) {
      let selectedWoreda = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.woredaKey));
      selectedWoreda = selectedWoreda.replace("\\","");
      this.selectedWoreda = JSON.parse(selectedWoreda);
    }
    this.dataService.getCurrentWoreda().subscribe(woreda => {
      this.selectedWoreda = woreda;
      this.setTvetInstitutionsByWoreda();
    })
    
    if (this.selectedRegion) {
      this.setTvetInstitutions()
    } else if (this.selectedWoreda){
      this.setTvetInstitutionsByWoreda()
    } else if (this.selectedZone){
      this.setTvetInstitutionsByZone()
    } else {
      this.welcomeService.getUserInstitutions().then(result => {
        this.institutionList = result;
        result[0] && result[0].languageId ? this.institutionList = result.filter(d => d.languageId == this.currentLanguageId) : ''
        this.institutionList.sort(AppUtilities.compare("name"));
      })
    } 

    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey)) != null) {
      let institutionId = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey))
      this.selectedInstitution = parseInt(institutionId);
    }


    this.dataService.getCurrentInstitution().subscribe(institutionId => {
      this.selectedInstitution = parseInt(institutionId);
      if (environment.categ == 'TVET'){
        if (this.selectedRegion) {
          this.setTvetInstitutions()
        } else if (this.selectedWoreda){
          this.setTvetInstitutionsByWoreda()
        } else if (this.selectedZone){
          this.setTvetInstitutionsByZone()
        } else {
          this.welcomeService.getUserInstitutions().then(result => {
            this.institutionList = result;
            result[0] && result[0].languageId ? this.institutionList = result.filter(d => d.languageId == this.currentLanguageId) : ''
            this.institutionList.sort(AppUtilities.compare("name"));
          })
        }
      }
    })

    this.setMyAccount()
    this.formGroup = this.fb.group({
      files: [null, FileUploadValidators.filesLimit(2)]
    })
    this.dataService.getAcessToken().subscribe(data => {

      let userData: User = data;
      this.username = userData.username;
    });
    this.selected = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.languageKey));
    // Get logo
    //this.imageInfos = this.uploadLogoService.getFiles();

    //#region Customize the style
    this.styleSelectorToggle = false;

    this.layoutType = this.dattaConfig['layout-type'];
    this.setLayout(this.layoutType);

    this.isColoredIcon = this.dattaConfig['nav-icon-color'];
    this.changeIconColor(this.isColoredIcon);

    this.headerBackgroundColor = this.dattaConfig['header-back-color'];
    this.navbarBackgroundColor = this.dattaConfig['nav-back-color'];
    this.brandBackgroundColor = this.dattaConfig['nav-brand-color'];
    this.navBackgorundImage = this.dattaConfig['nav-back-image'];

    this.setHeaderBackground(this.headerBackgroundColor);
    this.setNavbarBackground(this.navbarBackgroundColor);
    this.setBrandBackground(this.brandBackgroundColor);
    this.setBackgroundImage(this.navBackgorundImage);

    this.rtlLayout = this.dattaConfig['rtl-layout'];
    this.changeRtlLayout(this.rtlLayout);

    this.menuFixedLayout = this.dattaConfig['nav-fixed-layout'];
    if (this.dattaConfig['layout'] === 'vertical') {
      this.changeMenuFixedLayout(this.menuFixedLayout);
    }

    this.headerFixedLayout = this.dattaConfig['header-fixed-layout'];
    this.changeHeaderFixedLayout(this.headerFixedLayout);

    this.boxLayout = this.dattaConfig['box-layout'];
    this.changeBoxLayout(this.boxLayout);

    this.menuDropdownIcon = this.dattaConfig['nav-dropdown-icon'];
    this.setMenuDropdownIcon(this.menuDropdownIcon);

    this.menuListIcon = this.dattaConfig['nav-list-icon'];
    this.setMenuListIcon(this.menuListIcon);

    this.navActiveColor = this.dattaConfig['nav-active-list-color'];
    this.setNavActiveColor(this.navActiveColor);

    this.navTitleColor = this.dattaConfig['nav-list-title-color'];
    this.setNavTitleColor(this.navTitleColor);

    this.menuTitleHide = this.dattaConfig['nav-list-title-hide'];
    this.changeMenuTitle(this.menuTitleHide);

    if (this.dattaConfig['pre-layout'] !== '' && this.dattaConfig['pre-layout'] !== null) {
      this.setPreBuildLayout(this.dattaConfig['pre-layout']);
    }
    //#endregion
  }

  setTvetInstitutionsByZone(){
    let zone = JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.zoneKey)));
    if (zone) {
      this.accountsService.getDefaultAcademicYear().then(d => {
        this.defAcademicYearId = d.academicYearId
        this.institutionService.getTvetInstitutionsByZoneYear(zone.zoneId, this.defAcademicYearId).then(res => {
          this.institutionList = res.filter(d => d.languageId == this.currentLanguageId)
          this.institutionList.map(data => {
            data.regionId = data.region
            data.zoneId = data.zone
            return data;
          })
          this.institutionList.sort(AppUtilities.compare("name"));
        })
      })
    }
  }

  setTvetInstitutionsByWoreda(){
    let woreda = JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.woredaKey)));
    if (woreda) {
      this.accountsService.getDefaultAcademicYear().then(d => {
        this.defAcademicYearId = d.academicYearId
        this.accountsService.getTvetInstitutionsByDistrictLevel1Year(woreda.districtLevel1, this.defAcademicYearId).then(res => {
          this.institutionList = res.filter(d => d.languageId == this.currentLanguageId)
          this.institutionList.map(data => {
            data.regionId = data.region
            data.zoneId = data.zone
            return data;
          })
          this.institutionList.sort(AppUtilities.compare("name"));
        })
      })
    }
  }

  setTvetInstitutions() {
    let region = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.regionForWoredaOrZoneKey));
    if (region) {
      this.accountsService.getDefaultAcademicYear().then(d => {
        this.defAcademicYearId = d.academicYearId
        let token = AppUtilities.getDecodedAccessToken();
        if (token.roleId == AppRoles.TVET.ROCAA_ADMIN){ 
          this.institutionService.getTvetInstitutionsAssessmentCenterByRegion(region, this.currentLanguage).then(res => {
            this.institutionList = res
            this.institutionList.map(data => {
              data.regionId = data.region
              data.zoneId = data.zone
              return data;
            })
            this.institutionList.sort(AppUtilities.compare("name"));
          })
        } else {
          this.institutionService.getTvetInstitutionsByRegionYear(region, this.defAcademicYearId).then(res => {
            this.institutionList = res.filter(d => d.languageId == this.currentLanguageId)
            this.institutionList.map(data => {
              data.regionId = data.region
              data.zoneId = data.zone
              return data;
            })
            this.institutionList.sort(AppUtilities.compare("name"));
          })
        }
      })
    }
  }

  setRegions() {
    this.welcomeService.getUserRegions().then(result => {
      this.regionList = result;
    })
  }

  setWoredas() {
    this.welcomeService.getUserWoredas().then(result => {
      this.woredaList = result;
    })
  }

  setZones() {
    this.welcomeService.getUserZones().then(result => {
      this.zoneList = result;
      console.log(result);
      
    })
  }

  onChatToggle(friend_id) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('datta-rtl')) {
      this.dattaConfig['rtl-layout'] = true;
    } else {
      this.dattaConfig['rtl-layout'] = false;
    }
  }

  logout() {
    this.authenticationService.logout();
    const token = AppUtilities.getDecodedAccessToken();
    this.welcomeService.insertLogMethod(window.location.pathname, 'Log out with user ID:' + token.userId, '123')
      .then(res => {
      })
  }

  logo() {
    this.selectedFileNames = [];
    this.registrationForm = this.fb.group({
      file: [null]
    });
    this.dataDialog = true;
  }

  /*   upload(){
      this.formGroup.value.files;
      this.formGroup.setValue([]);
    } */

  /**
   * Changes language
   */
  changeLanguage(): void {
    localStorage.removeItem(AppSettings.languageKey);
    localStorage.setItem(AppSettings.languageKey, this.aesEncryptDecryptService.encrypt(this.selected.toString()));
    this.translate.setDefaultLang(this.selected);
    this.dataService.changeCurrentLang(this.selected);
    this.router.navigate(["/welcome"]);
    setTimeout(() => {
      location.reload();
    }, 50);
  }

  hideDialog() {
    this.dataDialog = false;
  }

  saveData() {

  }

  /**
   * Institutions changed
   */
  institutionChanged() {
    this.dataDialog = false;
    localStorage.removeItem(AppSettings.institutionKey);
    localStorage.setItem(AppSettings.institutionKey, this.aesEncryptDecryptService.encrypt(this.selectedInstitution.toString()))
    this.dataService.changeCurrentInstitution(this.selectedInstitution);

    this.router.navigate(["/welcome"]);
    setTimeout(() => {
      location.reload();
    }, 50);
  }

  regionChanged() {
    this.dataDialog = false;
    this.selectedInstitution = null
    localStorage.removeItem(AppSettings.regionKey);
    console.log('selectedRegion', this.selectedRegion);
    localStorage.setItem(AppSettings.regionKey, this.aesEncryptDecryptService.encrypt(JSON.stringify(this.selectedRegion)))
    this.dataService.changeCurrentRegion(this.selectedRegion);

    this.router.navigate(["/welcome"]);
    setTimeout(() => {
      location.reload();
    }, 50);
  }

  zoneChanged() {
    this.dataDialog = false;
    this.selectedInstitution = null
    localStorage.removeItem(AppSettings.zoneKey);
    localStorage.setItem(AppSettings.zoneKey, this.aesEncryptDecryptService.encrypt(JSON.stringify(this.selectedZone)))
    this.dataService.changeCurrentRegion(this.selectedZone);

    this.router.navigate(["/welcome"]);
    setTimeout(() => {
      location.reload();
    }, 50);
  }

  woredaChanged() {
    this.dataDialog = false;
    this.selectedInstitution = null
    localStorage.removeItem(AppSettings.woredaKey);
    localStorage.setItem(AppSettings.woredaKey, this.aesEncryptDecryptService.encrypt(JSON.stringify(this.selectedWoreda.toString())))
    this.dataService.changeCurrentRegion(this.selectedWoreda);

    this.router.navigate(["/welcome"]);
    setTimeout(() => {
      location.reload();
    }, 50);
  }

  refreshCustomization(){
    let customizationLocalStorage =  JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.customizationKey)));
    if (customizationLocalStorage){
      this.customization = customizationLocalStorage;
    } else {
      this.customization = DattaConfig.config;
    }
  }

  //#region Customize the style
  setThemeLayout() {
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }

    switch (current_url) {
      case this.location['_baseHref'] + '/layout/static':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['nav-fixed-layout'] = false;
        this.dattaConfig['header-fixed-layout'] = false;
        break;
      case this.location['_baseHref'] + '/layout/fixed':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['nav-fixed-layout'] = true;
        this.dattaConfig['header-fixed-layout'] = true;
        break;
      case this.location['_baseHref'] + '/layout/nav-fixed':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['nav-fixed-layout'] = true;
        this.dattaConfig['header-fixed-layout'] = false;
        break;
      case this.location['_baseHref'] + '/layout/nav-image':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['nav-back-image'] = 'navbar-image-3';
        break;
      case this.location['_baseHref'] + '/layout/collapse-menu':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['collapse-menu'] = true;
        break;
      case this.location['_baseHref'] + '/layout/horizontal':
        this.dattaConfig['layout'] = 'horizontal';
        break;
      case this.location['_baseHref'] + '/layout/box':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['box-layout'] = true;
        this.dattaConfig['collapse-menu'] = true;
        break;
      case this.location['_baseHref'] + '/layout/rtl':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['rtl-layout'] = true;
        break;
      case this.location['_baseHref'] + '/layout/light':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['layout-type'] = 'menu-light';
        break;
      case this.location['_baseHref'] + '/layout/dark':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['layout-type'] = 'dark';
        this.dattaConfig['nav-back-color'] = 'navbar-dark';
        this.dattaConfig['nav-brand-color'] = 'brand-dark';
        break;
      case this.location['_baseHref'] + '/layout/icon-color':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['layout-type'] = 'menu-light';
        this.dattaConfig['nav-icon-color'] = true;
        break;
      case this.location['_baseHref'] + '/layout/layout-2':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['pre-layout'] = 'layout-2';
        break;
      case this.location['_baseHref'] + '/layout/layout-2-2':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['pre-layout'] = 'layout-2-2';
        break;
      case this.location['_baseHref'] + '/layout/layout-3':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['pre-layout'] = 'layout-3';
        break;
      case this.location['_baseHref'] + '/layout/layout-4':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['pre-layout'] = 'layout-4';
        break;
      case this.location['_baseHref'] + '/layout/layout-4-2':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['pre-layout'] = 'layout-4-2';
        break;
      case this.location['_baseHref'] + '/layout/layout-5h':
        this.dattaConfig['layout'] = 'horizontal';
        this.dattaConfig['layout-type'] = 'menu-light';
        this.dattaConfig['nav-icon-color'] = true;
        this.dattaConfig['header-back-color'] = 'header-blue';
        break;
        case this.location['_baseHref'] + '/layout/layout-5h':
          this.dattaConfig['layout'] = 'horizontal';
          this.dattaConfig['layout-type'] = 'menu-light';
          this.dattaConfig['nav-icon-color'] = true;
          this.dattaConfig['header-back-color'] = 'header-white';
          break; 
      case this.location['_baseHref'] + '/layout/nav-color':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['layout-type'] = 'menu-light';
        this.dattaConfig['nav-icon-color'] = true;
        this.dattaConfig['header-back-color'] = 'header-lightblue';
        this.dattaConfig['nav-brand-color'] = 'brand-lightblue';
        this.dattaConfig['nav-fixed-layout'] = true;
        this.dattaConfig['header-fixed-layout'] = true;
        break;
      case this.location['_baseHref'] + '/layout/layout-6':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['pre-layout'] = 'layout-6';
        this.dattaConfig['layout-type'] = 'menu-light';
        this.dattaConfig['nav-icon-color'] = true;
        this.dattaConfig['nav-brand-color'] = 'brand-lightblue';
        this.dattaConfig['nav-fixed-layout'] = false;
        this.dattaConfig['header-fixed-layout'] = false;
        this.dattaConfig['layout-6-background'] = '#23b7e5';
        break;
      case this.location['_baseHref'] + '/layout/layout-8':
        this.dattaConfig['layout'] = 'vertical';
        this.dattaConfig['pre-layout'] = 'layout-8';
        this.dattaConfig['layout-type'] = 'menu-light';
        this.dattaConfig['header-back-color'] = 'header-lightblue';
        this.dattaConfig['nav-brand-color'] = 'brand-lightblue';
        this.dattaConfig['nav-fixed-layout'] = true;
        this.dattaConfig['header-fixed-layout'] = true;
        this.dattaConfig['nav-active-list-color'] = 'active-lightblue';
        break;
      default:
        break;
    }
  }

  setPreBuildLayout(pre_layout) {
    if (pre_layout === 'layout-6') {
      document.querySelector('.pcoded-navbar').classList.add('menupos-static');
      this.headerBackColor = this.dattaConfig['layout-6-background'];
      this.setHeaderBackColor(this.headerBackColor);
    }

    if (pre_layout !== 'layout-6' && pre_layout !== 'layout-8') {
      this.isConfig = false;
      document.querySelector('.pcoded-navbar').classList.add(pre_layout);
    } else {
      document.querySelector('body').classList.add(pre_layout);
    }
  }

  setHeaderBackColor(color) {
    this.headerBackColor = color;
    (document.querySelector('body') as HTMLElement).style.background = color;
  }

  // change main layout
  setLayout(layout) {
    this.refreshCustomization();

    this.isConfig = true;
    this.setNavbarBackground(this.dattaConfig['nav-back-color']);
    this.setBrandBackground(this.dattaConfig['nav-brand-color']);
    document.querySelector('.pcoded-navbar').classList.remove('menu-light');
    document.querySelector('.pcoded-navbar').classList.remove('menu-dark');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-dark');
    document.querySelector('.pcoded-navbar').classList.remove('brand-dark');
    document.querySelector('body').classList.remove('datta-dark');
    this.setHeaderBackground('header-default');

    this.layoutType = layout;
    if (layout === 'menu-light') {
      this.setNavbarBackground(this.navbarBackgroundColor);
      this.setBrandBackground(this.brandBackgroundColor);
      document.querySelector('.pcoded-navbar').classList.add(layout);
      this.customization['layout-type'] = 'menu-light'
    }
    if (layout === 'dark') {
      document.querySelector('.pcoded-navbar').classList.add('navbar-dark');
      document.querySelector('.pcoded-navbar').classList.add('brand-dark');

      this.setNavbarBackground('navbar-dark');
      this.setBrandBackground('brand-dark');

      if (this.dattaConfig['pre-layout'] !== 'layout-6') {
        this.setHeaderBackground('header-dark');
      }
      document.querySelector('body').classList.add('datta-dark');

      this.customization['layout-type'] = 'dark'
    }
    if (layout === 'reset') {
      this.customization['layout-type'] = 'menu-dark'
      this.customization['nav-back-image'] = false
      this.customization['header-fixed-layout'] = false
      this.customization['box-layout'] = false
      this.customization['header-back-color'] = 'header-default'
      this.customization['nav-back-color'] = 'navbar-default'
      this.customization['nav-brand-color'] = 'brand-default'
      this.reset();
    }
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  reset() {
    document.querySelector('.pcoded-navbar').classList.remove('icon-colored');
    this.ngOnInit();
  }

  setColoredIcon(e) {
    const flag = !!(e.target.checked);
    this.changeIconColor(flag);
  }

  changeIconColor(flag) {
    document.querySelector('.pcoded-navbar').classList.add('icon-colored');
    if (flag) {
      document.querySelector('.pcoded-navbar').classList.add('icon-colored');
    } else {
      document.querySelector('.pcoded-navbar').classList.remove('icon-colored');
    }
  }

  setRtlLayout(e) {
    const flag = !!(e.target.checked);
    this.changeRtlLayout(flag);
  }

  changeRtlLayout(flag) {
    this.refreshCustomization();
    if (flag) {
      document.querySelector('body').classList.add('datta-rtl');
      this.customization['rtl-layout'] = true
    } else {
      document.querySelector('body').classList.remove('datta-rtl');
      console.log(this.customization);
      this.customization['rtl-layout'] = false
    }
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  setMenuFixedLayout(e) {
    const flag = !!(e.target.checked);
    this.changeMenuFixedLayout(flag);
  }

  changeMenuFixedLayout(flag) {
    this.refreshCustomization();
    setTimeout(() => {
      if (flag) {
        document.querySelector('.pcoded-navbar').classList.remove('menupos-static');
        (document.querySelector('#nav-ps-datta') as HTMLElement).style.maxHeight = 'calc(100vh - 70px)';
        this.customization['nav-fixed-layout'] = true
      } else {
        document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        (document.querySelector('#nav-ps-datta') as HTMLElement).style.maxHeight = '100%';
        this.customization['nav-fixed-layout'] = false
      }
      localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
    }, 100);
  }

  setHeaderFixedLayout(e) {
    const flag = !!(e.target.checked);
    this.changeHeaderFixedLayout(flag);
  }

  changeHeaderFixedLayout(flag) {
    this.refreshCustomization();
    if (flag) {
      document.querySelector('.pcoded-header').classList.add('headerpos-fixed');
      document.querySelector('.pcoded-header').classList.add('header-blue');
      this.customization['header-fixed-layout'] = true
    } else {
      document.querySelector('.pcoded-header').classList.remove('headerpos-fixed');
      this.customization['header-fixed-layout'] = false
    }
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  setBoxLayout(e) {
    const flag = !!(e.target.checked);
    this.changeBoxLayout(flag);
  }

  changeBoxLayout(flag) {
    this.refreshCustomization();
    if (flag) {
      document.querySelector('body').classList.add('container');
      document.querySelector('body').classList.add('box-layout');
      this.customization['box-layout'] = true
    } else {
      document.querySelector('body').classList.remove('box-layout');
      document.querySelector('body').classList.remove('container');
      this.customization['box-layout'] = false
    }
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  hideMenuTitle(e) {
    const flag = !!(e.target.checked);
    this.changeMenuTitle(flag);
  }

  changeMenuTitle(flag) {
    this.refreshCustomization();
    if (flag) {
      document.querySelector('.pcoded-navbar').classList.add('caption-hide');
      this.customization['nav-list-title-hide'] = true
    } else {
      document.querySelector('.pcoded-navbar').classList.remove('caption-hide');
      this.customization['nav-list-title-hide'] = false
    }
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  setHeaderBackground(background) {
    this.refreshCustomization();
    this.headerBackgroundColor = background;
    document.querySelector('.pcoded-header').classList.remove('header-blue');
    document.querySelector('.pcoded-header').classList.remove('header-red');
    document.querySelector('.pcoded-header').classList.remove('header-purple');
    document.querySelector('.pcoded-header').classList.remove('header-lightblue');
    document.querySelector('.pcoded-header').classList.remove('header-dark');
    document.querySelector('.pcoded-header').classList.remove('header-white'); 
    if (background !== 'header-default') {
      document.querySelector('.pcoded-header').classList.add(background);
      this.customization['header-back-color'] = background
    }
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  setNavbarBackground(background) {
    this.refreshCustomization();
    this.setBackgroundImage(this.dattaConfig['nav-back-image']);
    this.navbarBackgroundColor = background;
    document.querySelector('.pcoded-navbar').classList.remove('navbar-blue');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-red');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-purple');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-lightblue');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-dark');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-forestgreen');

    // add default menu brand background color
    document.querySelector('.pcoded-navbar').classList.add('brand-default');
    if (background !== 'navbar-default') {
      document.querySelector('.pcoded-navbar').classList.add(background);
      this.customization['nav-back-color'] = background
    }
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  setBrandBackground(background) {
    this.refreshCustomization();
    this.brandBackgroundColor = background;
    document.querySelector('.pcoded-navbar').classList.remove('brand-default');
    document.querySelector('.pcoded-navbar').classList.remove('brand-blue');
    document.querySelector('.pcoded-navbar').classList.remove('brand-red');
    document.querySelector('.pcoded-navbar').classList.remove('brand-purple');
    document.querySelector('.pcoded-navbar').classList.remove('brand-lightblue');
    document.querySelector('.pcoded-navbar').classList.remove('brand-dark');
    document.querySelector('.pcoded-navbar').classList.remove('brand-white');

    document.querySelector('.pcoded-navbar').classList.add(background);
    this.customization['nav-brand-color'] = background
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  setBackgroundImage(image) {
    this.refreshCustomization();
    document.querySelector('.pcoded-navbar').classList.remove('navbar-image-1');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-image-2');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-image-3');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-image-4');
    document.querySelector('.pcoded-navbar').classList.remove('navbar-image-5');
    if (image) {
      this.navBackgorundImage = image;
      document.querySelector('.pcoded-navbar').classList.add(image);
      this.customization['nav-back-image'] = image
    }
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(this.customization));
  }

  setMenuDropdownIcon(icon) {
    document.querySelector('.pcoded-navbar').classList.remove('drp-icon-style1');
    document.querySelector('.pcoded-navbar').classList.remove('drp-icon-style2');
    document.querySelector('.pcoded-navbar').classList.remove('drp-icon-style3');
    if (icon !== 'style1') {
      document.querySelector('.pcoded-navbar').classList.add('drp-icon-' + icon);
    }
  }

  setMenuListIcon(icon) {
    document.querySelector('.pcoded-navbar').classList.remove('menu-item-icon-style1');
    document.querySelector('.pcoded-navbar').classList.remove('menu-item-icon-style2');
    document.querySelector('.pcoded-navbar').classList.remove('menu-item-icon-style3');
    document.querySelector('.pcoded-navbar').classList.remove('menu-item-icon-style4');
    document.querySelector('.pcoded-navbar').classList.remove('menu-item-icon-style5');
    document.querySelector('.pcoded-navbar').classList.remove('menu-item-icon-style6');
    if (icon !== 'style1') {
      document.querySelector('.pcoded-navbar').classList.add('menu-item-icon-' + icon);
    }
  }

  setNavActiveColor(style) {
    this.navActiveColor = style;
    document.querySelector('.pcoded-navbar').classList.remove('active-default');
    document.querySelector('.pcoded-navbar').classList.remove('active-blue');
    document.querySelector('.pcoded-navbar').classList.remove('active-red');
    document.querySelector('.pcoded-navbar').classList.remove('active-purple');
    document.querySelector('.pcoded-navbar').classList.remove('active-lightblue');
    document.querySelector('.pcoded-navbar').classList.remove('active-dark');
    if (style !== 'active-default') {
      document.querySelector('.pcoded-navbar').classList.add(style);
    }
  }

  setNavTitleColor(style) {
    this.navTitleColor = style;
    document.querySelector('.pcoded-navbar').classList.remove('title-default');
    document.querySelector('.pcoded-navbar').classList.remove('title-blue');
    document.querySelector('.pcoded-navbar').classList.remove('title-red');
    document.querySelector('.pcoded-navbar').classList.remove('title-purple');
    document.querySelector('.pcoded-navbar').classList.remove('title-lightblue');
    document.querySelector('.pcoded-navbar').classList.remove('title-dark');
    if (style !== 'title-default') {
      document.querySelector('.pcoded-navbar').classList.add(style);
    }
  }

  //#endregion


  //#region Change Logo
  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFileNames = [];
    this.selectedFiles = event.target.files;

    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.readAsDataURL(this.selectedFiles[i]);

        // When file uploads set it to file formcontrol
        reader.onload = () => {
          this.imageUrl = reader.result;
          this.registrationForm.patchValue({
            file: reader.result,
          });
        }
        this.previews = this.registrationForm.value.file;
        this.selectedFileNames.push(this.selectedFiles[i].name);
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      this.uploadLogoService.upload(file).then(res => {
        //toaster
      })
    }
  }

  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      /* for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      } */
      let user = {
        primaryRoleAssignedId: this.userDetails.primaryRoleAssignedId,
        userId: this.userDetails.userId,
        roleId: this.userDetails.roleId,
        email: this.userDetails.email,
        username: this.userDetails.username,
        firstName: this.userDetails.firstName,
        lastName: this.userDetails.lastName,
        photo: this.userDetails.photo,
        logo: this.registrationForm.value.file ? this.registrationForm.value.file : this.userDetails.logo,
        address: this.userDetails.address,
        emailConfirmed: this.userDetails.emailConfirmed,
        twoFactorEnabled: this.userDetails.twoFactorEnabled,
      }

      if (this.currentUserDetails.roleLevel == "R0" || this.currentUserDetails.roleLevel == "R1") {

        this.userService.updateUserWithoutDataAcessLevel(user).then(res => {

          if (res.status && (res.status < 200 || res.status > 299)) {
            this.callToaster('error', null, "An error occurred while saving Logo",0);
          } else {
            this.callToaster('success', null, "Logo Updated",5000);
            this.accountsService.getRoleByUsername(this.userDetails.username).then(res => {
              localStorage.setItem(AppSettings.roleByUsernameKey, this.aesEncryptDecryptService.encrypt(JSON.stringify(res)));
              this.dataService.changeRoleByUsername(res);
              setTimeout(function () { location.reload(); }, 2000);
            });   
          }
        })
      } else {

        let institutionLogo = {
          institutionId: this.selectedInstitution,
          logo: this.registrationForm.value.file
        }

        this.userService.postInstitutionLogo(institutionLogo).then(res => {

          if (res.status && (res.status < 200 || res.status > 299)) {
            this.callToaster('error', null, "An error occurred while saving Logo",0);
          } else {
            this.callToaster('success', null, "Logo Updated",5000);
            this.accountsService.getRoleByUsername(this.userDetails.username).then(res => {
              localStorage.setItem(AppSettings.roleByUsernameKey, this.aesEncryptDecryptService.encrypt(JSON.stringify(res)));
              this.dataService.changeRoleByUsername(res);
              setTimeout(function () { location.reload(); }, 2000);
            });   
          }
        })

      }


    }
  }
  //#endregion

  setMyAccount() {
    let token = AppUtilities.getDecodedAccessToken();
    this.username = token.username;
    this.userId = token.userId


    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)) != undefined && this.aesEncryptDecryptService.decrypt(localStorage.getItem('credentials')) != null) {
      let res = JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)))
      this.userDetails = res;
    }
     
  }

  profile() {
    this.router.navigateByUrl('/profile');
  }

  /**
   * Calls toaster
   * @param toasterTitle
   * @param toasterBody
   */
  callToaster(type, toasterTitle, toasterBody,timeout) {
    const toaster: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
    };
    this.toasterService.pop(toaster);
  }
}
