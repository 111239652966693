import { Component, OnInit } from '@angular/core';
import { ToasterService, Toast } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormControl } from '@angular/forms';
import * as cloneDeep from 'lodash/cloneDeep';


import { DataService } from './../../../services/data.service';
import { AdminService } from './../../../services/admin.service';

import { AppKeys } from './../../../common/app.key';
import { AppUtilities } from './../../../common/app.utilities';
import { AppSettings } from './../../../common/app.settings';
import { Tile } from 'powerbi-client';

@Component({
  selector: 'app-role-two-factor',
  templateUrl: './role-two-factor.component.html',
  styleUrls: ['./role-two-factor.component.scss']
})
export class RoleTwoFactorComponent implements OnInit {


  defaultLang: string;
  isInfo: Boolean = true;
  rolesList: any = [];

  //Toaster
  toasterUpdateUserSuccess: string;
  toasterUpdateUserError: string;
  toasterAddUserSuccess: string;
  toasterAddUserError: string;
  toasterDeleteUserSuccess: string;
  toasterDeleteUserError: string;

  myGroup: FormGroup; 
  constructor(
    private toasterService: ToasterService,
    private dataService: DataService,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,

  ) { }

  ngOnInit(): void {

    this.myGroup = new FormGroup({
      rememberMe: new FormControl()
    });

    this.dataService.getCurrentLang().subscribe(lng => {
      this.defaultLang = lng;
      this.getAllTranslations().then(res => {

        this.adminService.getRoles(this.defaultLang).then(roleData => {
          this.rolesList = roleData;
        });
      });
    });
  }

  get f() { return this.myGroup.controls; }


  /**
   * Changes tab
   * @param value
   */
  changeTab(value) {

    this.isInfo = true;


  }


  /**
   * Gets all translations
   * @returns all translations
   */
  getAllTranslations(): Promise<any> {
    const keys = AppKeys.roleTwoFactor;
    return this.translateService.get(keys).toPromise().then(
      results => {
        this.toasterUpdateUserSuccess = results[keys[0]];
        this.toasterUpdateUserError = results[keys[1]];
      }
    );
  }


  /**
* Calls toaster
* @param toasterTitle
* @param toasterBody
*/
  callToaster(type, toasterTitle, toasterBody,timeout) {
    const noSelectionTeams: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
    };
    this.toasterService.pop(noSelectionTeams);
  }


  /**
   * Gets name of role
   * @param value 
   * @returns  
   */
  getNameOfRole(value) {
    let title = "";
    let text = value.split("_");
    text.forEach(element => {
      title += " " + element
    });
    return title
  }

  /**
   * Cancels role
   */
  cancelRole() {
    this.adminService.getRoles(this.defaultLang).then(roleData => {
      this.rolesList = roleData;
    })
  }

  /**
   * Saves role
   */
  saveRole() {
    this.adminService.pullDefaultTwoFactorAuth(this.rolesList).then(res => {
      if (res.status == 200) {
        this.callToaster("success", null, this.toasterUpdateUserSuccess,5000)
      } else {
        this.callToaster("error", null, this.toasterUpdateUserError,10000)

      }
    })
  }

}
