<div class="tailwind">
  <div class="flex flex-wrap">
    <div class="w-full lg:w-8/12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{{ 'Profile.MyAccount' | translate }}</h6>
            <button
              class="p-button-text" pButton pRipple icon="pi pi-check"
              type="button" (click)="save()" label="{{ 'Profile.Save' | translate }}">
              
            </button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form [formGroup]="formGroup">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              {{ 'Profile.UserInformation' | translate }}
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    {{ 'Profile.Username' | translate }}
                  </label>
                  <input type="text" formControlName="username"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    autocomplete="new-password" [(ngModel)]="username" />
                  <small class="p-invalid" *ngIf="submitted && !formGroup.controls.username.valid">{{
                        'tvetAccounts.ThisFieldIsRequired' | translate }}.</small>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    {{ 'Profile.EmailAddress' | translate }}
                  </label>
                  <input type="email" formControlName="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    autocomplete="new-password" [(ngModel)]="email" />
                  <small class="p-invalid" *ngIf="submitted && !formGroup.controls.email.valid">{{
                        'tvetAccounts.InvalidEmailAddress' | translate }}.</small>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    {{ 'Profile.FirstName' | translate }}
                  </label>
                  <input type="text" formControlName="firstName"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    autocomplete="new-password" [(ngModel)]="firstName" />
                  <small class="p-invalid" *ngIf="submitted && !formGroup.controls.firstName.valid">{{
                        'tvetAccounts.ThisFieldIsRequired' | translate }}.</small>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    {{ 'Profile.LastName' | translate }}
                  </label>
                  <input type="text" formControlName="lastName"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    autocomplete="new-password" [(ngModel)]="lastName" />
                  <small class="p-invalid" *ngIf="submitted && !formGroup.controls.lastName.valid">{{
                        'tvetAccounts.ThisFieldIsRequired' | translate }}.</small>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    {{ 'Profile.OldPassword' | translate }}
                  </label>
                  <input type="password" formControlName="oldPassword"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    autocomplete="new-password" [(ngModel)]="oldPassword"/>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    {{ 'Profile.NewPassword' | translate }}
                  </label>
                  <input type="password" formControlName="newPassword"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    autocomplete="new-password" [(ngModel)]="password"/>
                    <password-strength-meter  (strengthChange)="strengthChanges($event)" [password]="password" [enableFeedback]="true"></password-strength-meter>
                </div> 
              </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Preferred Languages
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    Languages
                  </label>
                  <ng-select [items]="allLanguages" required ngDefaultControl [(ngModel)]="preferredLanguageId" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   formControlName="preferredLanguageId" bindLabel="languageName" bindValue="languageId"> </ng-select>
                  <small class="p-invalid" *ngIf="submitted && !formGroup.controls.preferredLanguageId.valid">{{
                         'tvetAccounts.ThisFieldIsRequired' | translate }}.</small>
                </div>
              </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />

            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              {{ 'Profile.ContactInformation' | translate }}
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    {{ 'Profile.Address' | translate }}
                  </label>
                  <input type="text" formControlName="address" 
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    [(ngModel)]="address"/>
                </div>
              </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              {{ 'Profile.ProfilePhoto' | translate }}
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="w-full">
                  <!-- Form starts -->
                  <!-- [formGroup]="registrationForm" (ngSubmit)="onSubmit()" -->
                  <form >

                    <div class="group-gap">

                      <!-- Upload image -->
                      <div class="avatar-upload">
                        <div class="avatar-edit">
                          <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg, .gif" #fileInput
                            (change)="uploadPhoto($event)" />
                        </div>
                        <div class="avatar-preview">
                          <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style="justify-content: center;" class="flex flex-wrap">
                    <!-- Submit Button -->
                      <button type="submit" class="p-button-text" (click)="fileInput.click()" pButton pRipple icon="pi pi-check" label="{{ 'Profile.UploadPhoto' | translate }}">
                        
                      </button>
                  </div>
                  </form><!-- Form ends -->

                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-4/12 px-4">
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div class="px-6 pb-4">
          <div class="flex flex-wrap justify-center">
            <div class="w-full px-4 flex justify-center">
              <div class="relative">
                <img alt="..." [src]="photo"
                  class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px" />
              </div>
            </div>
            <div class="w-full px-4 text-center mt-20">
            </div>
          </div>
          <div class="text-center mt-12">
            <h3 class="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
              {{name ? name : username}}
            </h3>
            <div *ngIf="myRegionName" class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
              {{myRegionName}}
            </div>
            <div class="mb-2 text-blueGray-600 mt-10">
              <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
              {{roleName}}
            </div>
            <div *ngIf="institution" class="mb-2 text-blueGray-600">
              <i class="fas fa-university mr-2 text-lg text-blueGray-400"></i>
              {{institution}}
            </div>
          </div>
          <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
              <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12">
                      <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                        {{ 'Profile.ThisAccountWasCreatedOn' | translate }} {{createdDate}}
                      </p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>