import { MainService } from './../../../../../services/main.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../../../services/data.service';
import { AppUtilities } from 'src/app/common/app.utilities';
import { AccountsService } from 'src/app/services/authentication/accounts.service';
import { AppSettings } from 'src/app/common/app.settings';
import { AESEncryptDecryptService } from '../../../../../services/security/aesencrypt-decrypt.service';


@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss']
})
export class NavLeftComponent implements OnInit {
  token: any;
  currentUserDetails: any;
  role: string;
  constructor(private translate: TranslateService,
    private dataService: DataService,
    private mainService: MainService,
    private aesEncryptDecryptService: AESEncryptDecryptService,
    private accountsService: AccountsService
  ) { }

  ngOnInit() {
    this.token = AppUtilities.getDecodedAccessToken();
  }

  ngDoCheck() {
    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)) != undefined && this.aesEncryptDecryptService.decrypt(localStorage.getItem('credentials')) != null) {
      let res = JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)))
      this.assignRole(res)
    }
  }

  roleName;
  assignRole(res) {
    this.currentUserDetails = res;
    this.role = this.currentUserDetails.roleName.split('_').join(' ')
    if (this.role == "MINISTRY ADMIN") {
      this.role = "EXECUTIVE LEVEL"
    } else {
      this.role = this.currentUserDetails.roleName.split('_').join(' ')
    }
  }



}
