<div class="auth-wrapper">
  <div class="auth-content subscribe">
    <div class="auth-bg">
      <span class="r"></span>
      <span class="r s"></span>
      <span class="r s"></span>
      <span class="r"></span>
    </div>
    <div class="card">
      <div class="card-body text-left">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-3">
                <i class="feather icon-lock auth-icon"></i>
              </div>
              <div class="col-md-8 mb-4 mt-3 ">
                <h3 class="mb-4" style='font-size: 35px;'>{{ 'UnauthorizedPage.NotAuthorized' | translate }}</h3>
                <p class="text-muted">{{ 'UnauthorizedPage.ThisURLisvalidButyouarenotauthorizedforthiscontent' | translate }}</p>
              </div>
              <div class="col-md-3 goBack" [routerLink]="['/welcome']">
                 <p><i class="feather icon-corner-down-left"></i> {{ 'UnauthorizedPage.GoBack' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>