import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private http : HttpClient) { }

  getRoles():Promise<any> {
    return this.http.get<any>(`${environment.getAllRolesUrl}`).toPromise()
          .then(response => response as any);
  }

  getUsersRoles():Promise<any> {
    return this.http.get<any>(`${environment.getUsersRolesUrl}`).toPromise()
          .then(response => response as any);
  }

  // getUsers():Promise<any> {
  //   return this.http.get<any>(`${environment.getUsersUrl}`).toPromise()
  //         .then(response => response as any);
  // }



  getRoleByUsername(username){
    return this.http.get(`${environment.getRoleByUsernameUrl}/${username}`).toPromise().then(response => response as any);
  }


  getDefaultAcademicYear(): Promise<any> {
    return this.http.get(`${environment.getDefaultAcademicYear}`).toPromise().then(response => response as any);
  }

  getZones(){
    return this.http.get(`${environment.getZonesUrl}`).toPromise().then(response => response as any);
  }

  getRegions(){
    return this.http.get(`${environment.getRegionsUrl}`).toPromise().then(response => response as any);
  }

  getAllInstitutionTypes(language){
    return this.http.get(`${environment.GetAllInstitutionTypes}/${language}`).toPromise().then(response => response as any);
  }

  getZonesByRegion(regionCode,language){
    return this.http.get(`${environment.getZonesByRegion}/${regionCode}/${language}`).toPromise().then(response => response as any);
  }

  getOwnershipTypes(){
    return this.http.get(`${environment.addInstitution.getAllOwnership}`).toPromise().then(response => response as any);
  }

  getFileCategories(){
    return this.http.get(`${environment.getFileCategoriesUrl}`).toPromise().then(response => response as any);
  }

  getDataAccessLevelsByUserId(userId){
    return this.http.get(`${environment.getDataAccessLevelsByUserIdUrl}/${userId}`).toPromise().then(response => response as any);
  }


  getManagedAccouts(lang, institutionId):Promise<any> {
    if (institutionId){
      return this.http.get<any>(`${environment.getManagedAccouts}/${lang}/${institutionId}`).toPromise()
      .then(response => response as any);
    }
    return this.http.get<any>(`${environment.getManagedAccouts}/${lang}`).toPromise()
    .then(response => response as any);
  }

  getTvetManagedAccouts(lang, regionId):Promise<any> {
    if (regionId){
      return this.http.get<any>(`${environment.getTvetManagedAccouts}/${lang}/${regionId}`).toPromise()
      .then(response => response as any);
    }
    return this.http.get<any>(`${environment.getTvetManagedAccouts}/${lang}`).toPromise()
    .then(response => response as any);
  }

  getDistrictLevel1ByZone(lang, zoneId):Promise<any> {
    return this.http.get<any>(`${environment.getDistrictLevel1ByZone}/${zoneId}/${lang}`).toPromise()
    .then(response => response as any);
  }

  getTvetInstitutionsByDistrictLevel1Year(districtLevel1, year):Promise<any> {
    return this.http.get<any>(`${environment.getTvetInstitutionsByDistrictLevel1Year}/${districtLevel1}/${year}`).toPromise()
    .then(response => response as any);
  }
}
