<nav>
  <div class="navbar-wrapper">
    <app-nav-logo [navCollapsed]="navCollapsed" (onNavCollapse)="navCollapse()"></app-nav-logo>
    <app-nav-content (onNavCollapsedMob)="navCollapseMob()"></app-nav-content>
  </div>
</nav>

<!-- <div *ngIf="logo"  class="div-wrapper">
  <hr>

  <div class="navbar-brand header-logo">
    <ng-container>
      <div [ngClass]="{'fadeOut' : navCollapsed == false, 'fadeIn' : navCollapsed == true}">
        <img class="edligoIconLogo" src="../../../../../../assets/images/EERNIconLogo.png" width="50px" alt="">
      </div>
      <div class="edligoLogo">
        <img src="../../../../../../assets/images/EERN.png" width="125px" alt="">
      </div>
    </ng-container>
  </div>
  
</div> -->

 