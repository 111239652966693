import { academicYear } from './../models/institution/academicYear';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppSettings } from './../common/app.settings';
import { error } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  constructor(private http: HttpClient) { }


  /**
   * Gets all zone
   * @param token
   * @returns all zone
   */
  getAllZones(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getAllZones}/${defaultLang}`).toPromise().then(response => response as any);
  }
  /**
   * Gets all countries
   * @param token
   * @returns all countries
   */
  getAllCountries(): Promise<any> {
    return this.http.get(environment.addInstitution.getCountries).toPromise().then(response => response as any);
  }

  /**
   * Gets all ownership
   * @param token
   * @returns all ownership
   */
  getAllOwnership(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getAllOwnership}/${defaultLang}`).toPromise().then(response => response as any);
  }

  /**
   * Gets all bands
   * @param token
   * @returns all bands
   */
  getAllBands(token): Promise<any> {
    return this.http.get(environment.addInstitution.getBands).toPromise().then(response => response as any);
  }

  /**
   * Gets all categories
   * @param token
   * @returns all categories
   */
  getAllCategories(): Promise<any> {
    return this.http.get(environment.addInstitution.getCategories).toPromise().then(response => response as any);
  }

  /**
   * Gets all academic years
   * @param token
   * @returns all academic years
   */
  getAllAcademicYears(): Promise<any> {
    return this.http.get(environment.addInstitution.getAcademicYears).toPromise().then(response => response as any);
  }

  /**
   * Gets all all institution type
   * @param token
   * @returns all all institution type
   */
  getAllAllInstitutionType(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getAllInstitutionType}/${defaultLang}`).toPromise().then(response => response as any);
  }

  /**
   * Gets distinct public main institutions
   * @param defaultLang 
   * @returns distinct public main institutions 
   */
  getDistinctPublicMainInstitutions(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getDistinctPublicMainInstitutions}/${defaultLang}`).toPromise().then(response => response as any);
  }

  /**
   * Gets distinct private main institutions
   * @param defaultLang 
   * @returns distinct private main institutions 
   */
  getDistinctPrivateMainInstitutions(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getDistinctPrivateMainInstitutions}/${defaultLang}`).toPromise().then(response => response as any);
  }

  /**
   * Gets all universities
   * @param token
   * @returns all universities
   */
  // getAllUniversities(): Promise<any> {
  //   return this.http.get(environment.addInstitution.getuniversities).toPromise().then(response => response as any);
  // }

  // getAllAllCampus(): Promise<any> {
  //   return this.http.get(environment.addInstitution.getCampus).toPromise().then(response => response as any);
  // }

  /**
   * Gets all region
   * @param token
   * @returns all region
   */
  getAllRegion(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getAllRegion}/${defaultLang}`).toPromise().then(response => response as any);
  }


  /**
   * Gets all institution
   * @param token
   * @returns all institution
   */
  getAllInstitution(): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionUrl}`).toPromise().then(response => response as any);
  }

  /**
   * Gets institution with id
   * @param institutionId
   * @param academicYear
   * @param token
   * @returns institution with id
   */
  getInstitutionWithId(institutionId, academicYear,): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionUrl}/${institutionId}/${academicYear}`).toPromise().then(response => response as any);
  }

  /**
   * Adds institution
   * @param data
   * @param token
   * @returns institution
   */
  addInstitution(data): Promise<any> {
    return this.http.post(`${environment.addInstitution.postInstitutionUrl}`, data).toPromise().then(response => response as any).catch(error => {
      return error
    });
  }

  /**
   * Updates institution
   * @param institutionId
   * @param academicYear
   * @param data
   * @param token
   * @returns institution
   */
  updateInstitutionOverview(data): Promise<any> {
    return this.http.put(`${environment.addInstitution.putInstitutionUrl}`, data).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }

  /**
   * Updates institution
   * @param data 
   * @returns institution 
   */
  updateInstitution(data): Promise<any> {
    return this.http.put(`${environment.addInstitution.putInstitutionUrl}`, data).toPromise().then(response => response as any).catch(error => {
      return error
    });
  }

  updateInstitutionDetail(data): Promise<any> {
    return this.http.put(`${environment.addInstitution.putInstitutionDetailsUrl}`, data).toPromise().then(response => response as any).catch(error => {
      return error
    });
  }

  /**
   * Updates tvetinstitution
   * @param data 
   * @returns tvetinstitution 
   */
  updateTVETInstitution(data): Promise<any> {
    return this.http.put(`${environment.addInstitution.putInstitutionUrl}`, data).toPromise().then(response => response as any).catch(error => {
      return error
    });
  }


  /**
   * Deletes institution
   * @param institutionId
   * @param academicYear
   * @param token
   * @returns institution
   */
  deleteInstitution(institutionId, academicYear): Promise<any> {
    return this.http.delete(`${environment.addInstitution.deleteInstitutionUrl}/${institutionId}/${academicYear}`).toPromise().then(response => response as any).catch(error => {
      return error
    });
  }


  /**
   * Gets institution id
   * @param institutionId
   * @param academicYear
   * @param token
   * @returns institution id
   */
  getInstitutionId(institutionId, academicYear): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionIdUrl}/${institutionId}/${academicYear}`).toPromise().then(response => response as any);
  }

  /**
   * Gets institution overview
   * @param institutionId 
   * @returns institution overview 
   */
  getInstitutionOverview(institutionId, defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionOverview}/${institutionId}/${defaultLang}`).toPromise().then(response => response as any);
  }

  getInstitutionData(institutionId, academicYear, lng): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionDetailsUrl}/${institutionId}/${academicYear}/${lng}`).toPromise().then(response => response as any);
  }


  getAccreditationBodies(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getAccreditationBodies}/${defaultLang}`).toPromise().then(response => response as any);
  }


  getInstitutionWithUserId(userId, token): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionWithUserIdUrl}/${userId}`).toPromise().then(response => response as any);
  }

  getPrimaryInstitutionsByUserId(userId): Promise<any> {
    return this.http.get(`${environment.addInstitution.getPrimaryInstitutionsByUserIdUrl}/${userId}`).toPromise().then(response => response as any);
  }

  getPrimaryInstitutions() {
    return this.http.get(`${environment.addInstitution.getPrimaryInstitutionsUrl}`).toPromise().then(response => response as any);
  }

  getChildInstitutions(institutionId, academicYear): Promise<any> {
    return this.http.get(`${environment.addInstitution.getChildInstitutions}/${institutionId}/${academicYear}`).toPromise().then(response => response as any);
  }

  getChildInstitutionsFormatted(institutionId, academicYear, lng): Promise<any> {
    return this.http.get(`${environment.addInstitution.GetChildInstitutionsFormatted}/${institutionId}/${academicYear}/${lng}`).toPromise().then(response => response as any);
  }

  getInstitutionsByUserName(username): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionWithUserNameUrl}/${username}​`).toPromise().then(response => response as any);
  }

  getDistinctChildInstitutions(institutionId, language): Promise<any> {
    return this.http.get(`${environment.addInstitution.getDistinctChildInstitutions}/${institutionId}/${language}`).toPromise().then(response => response as any).catch(error => error);
  }

  getMainInstitutionsByYear(academicYear) {
    return this.http.get(`${environment.addInstitution.getMainInstitutionsByYear}/${academicYear}`).toPromise().then(response => response as any);
  }

  getInstitutionsByYear(academicYear) {
    return this.http.get(`${environment.addInstitution.getInstitutionsByYear}/${academicYear}`).toPromise().then(response => response as any);
  }

  getInstitutionByIdAndAcademicYear(institutionId, academicYear, language) {
    return this.http.get(`${environment.addInstitution.getInstitutionByIdAndAcademicYear}/${institutionId}/${academicYear}/${language}`).toPromise().then(response => response as any);
  }

  getDistinctMainInstitutions() {
    return this.http.get(`${environment.addInstitution.getDistinctMainInstitutions}`).toPromise().then(response => response as any);
  }
  getTvetInstitutionsByRegionYear(regionCode, academicYear) {
    return this.http.get(`${environment.addInstitution.getTvetInstitutionsByRegionYear}/${regionCode}/${academicYear}`).toPromise().then(response => response as any);
  }

  getHeiClasses(defaultLang) {
    return this.http.get(`${environment.addInstitution.getHeiClasses}/${defaultLang}`).toPromise().then(response => response as any);
  }

  getStandars(defaultLang) {
    return this.http.get(`${environment.addInstitution.getStandars}/${defaultLang}`).toPromise().then(response => response as any);
  }



  getInstitutionsDetailsByUserId(userId): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionsDetailsByUserId}/${userId}`).toPromise().then(response => response as any);
  }

  getInstitutionById(userId, defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionById}/${userId}/${defaultLang}`).toPromise().then(response => response as any);
  }


  /**
  * GetDistrictLevel1
  * @param token
  * @returns 
  */
  getDistrictLevel1(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getDistrictLevel1}/${defaultLang}`).toPromise().then(response => response as any);
  }


  /**
  * GetDistrictLevel2
  * @param token
  * @returns 
  */
  getDistrictLevel2(defaultLang): Promise<any> {
    return this.http.get(`${environment.addInstitution.getDistrictLevel2}/${defaultLang}`).toPromise().then(response => response as any);
  }

  getInstitutionChildren(institutionId): Promise<any> {
    return this.http.get(`${environment.getInstitutionChildren}/${institutionId}`).toPromise().then(response => response as any);
  }

  /**
   * Gets institution name by institution id
   * @param institutionId 
   * @returns institution name by institution id 
   */
  getInstitutionNameByInstitutionId(institutionId): Promise<any> {
    return this.http.get(`${environment.addInstitution.getInstitutionNameByInstitutionId}/${institutionId}`).toPromise().then(response =>
      response as any);
  }

  getTvetInstitutionsAssessmentCenterByRegion(regionId, defaultLang) {
    return this.http.get(`${environment.getTvetInstitutionsAssessmentCenterByRegion}/${regionId}/${defaultLang}`).toPromise().then(response => response as any);
  }
  getAuditsOutcomes(defaultLang) {
    return this.http.get(`${environment.getAuditsOutcomes}/${defaultLang}`).toPromise().then(response => response as any);
  }

  /**
   * Gets processed file stats
   * @param academicYear 
   * @param academicPeriod 
   * @returns processed file stats 
   */
  getProcessedFileStats(academicYear, academicPeriod): Promise<any> {
    return this.http.get(`${environment.addInstitution.getProcessedFileStats}/${academicYear}/${academicPeriod}`).toPromise().then(response => response as any);
  }

  getTvetInstitutionsByZoneYear(zoneId, academicYear) {
    return this.http.get(`${environment.addInstitution.getTvetInstitutionsByZoneYear}/${zoneId}/${academicYear}`).toPromise().then(response => response as any);
  }
  getTvetInstitutionsByDistrictLevel1Year(districtLevel1Id, academicYear) {
    return this.http.get(`${environment.addInstitution.getTvetInstitutionsByDistrictLevel1Year}/${districtLevel1Id}/${academicYear}`).toPromise().then(response => response as any);
  }

  getAssessmentCentersByRegionYear(regionId, academicYear) {
    return this.http.get(`${environment.addInstitution.getAssessmentCentersByRegionYear}/${regionId}/${academicYear}`).toPromise().then(response => response as any);
  }

  getCampusesByInstitutionYear(parentInstitutionId, academicYear, lang) {
    return this.http.get(`${environment.getCampusesByInstitutionYear}/${parentInstitutionId}/${academicYear}/${lang}`).toPromise().then(response => response as any);
  }
}
