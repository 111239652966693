import { AppUtilities } from './../../common/app.utilities';
import { DataService } from './../data.service';
import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from './loader.service';
import { finalize, catchError, filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppSettings } from 'src/app/common/app.settings';
import { Router } from '@angular/router';
import { Toast, ToasterService } from 'angular2-toaster';
import { RequestCacheService } from '../request-cache.service'


@Injectable({
  providedIn: 'root'
})

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private dataService: DataService,
    public loaderService: LoaderService,
    private router: Router,
    private toasterService: ToasterService,
    private cache: RequestCacheService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = AppUtilities.getAuthToken();
    //Add token to header if token exists and is valid
    if (token != null && token != undefined) {
      req = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${token}`)
      });
    }
    if (!AppUtilities.isUserAuthenticated && req.url != environment.signInUrl) {
      this.router.navigate([AppSettings.loginRoute]);
    }

    this.loaderService.show();
    return next.handle(req)
      .pipe(
        tap(resp => {
          if (resp instanceof HttpErrorResponse) {
            if (resp.status === 401) {

            }
            return throwError(resp.statusText);
          } else if (resp instanceof HttpResponse) {

          }
        }, error => {
          error.error && error.error.detail && error.url.indexOf('PostMenuRole') == -1 ? this.callToaster('error', null, error.error.detail, 10000) : '';
        }),
        finalize(() => this.loaderService.hide())
      )
  }

  sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
        }

      })
    );
  }

  /** Handle error
  * @param err Http call error
  */
  private handleError(err: any, url: string): void {
    if (err instanceof HttpErrorResponse) {
      if (err.error instanceof Error) {
        // this.loggerService.error('An error occurred', err.error.message);
      } else {
        // this.loggerService.error(`Backend returned code ${err.status} (${err.statusText}), url was ${err.url}, body was: ${err.error}`);

        if (err.status === 401) {
          // Redirect to the login route

          // this.loggerService.error('User is not allowed to access those informations');
          //this.handleSession();
        } else if (err.status === 400) {
          // Something went wrong
        }
        // TODO: Handle All error status
      }
    }
  }

  /**
 * Calls toaster
 * @param toasterTitle
 * @param toasterBody
 */
  callToaster(type, toasterTitle, toasterBody, timeout) {
    console.log(type, toasterTitle, toasterBody, timeout) 
    const toaster: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
    };
    this.toasterService.pop(toaster);
  }
}
 