import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DattaConfig} from '../../../../app-config';
import {UploadLogoService} from "../../../../services/upload-logo.service";
import { DataService} from "../../../../services/data.service";

import{AppSettings} from "../../../../common/app.settings";
import { AESEncryptDecryptService } from 'src/app/services/security/aesencrypt-decrypt.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavCollapsedMob = new EventEmitter();
  public dattaConfig: any;
  public navCollapsed;
  public navCollapsedMob;
  public windowWidth: number;
  logo:any;
  constructor(
    private uploadLogoService:UploadLogoService,
    private dataService:DataService,
    private aesEncryptDecryptService : AESEncryptDecryptService
  ) {
 

    this.dattaConfig = DattaConfig.config;
    this.windowWidth = window.innerWidth;
    this.navCollapsed = (this.windowWidth >= 992) ? this.dattaConfig['collapse-menu'] : false;
    this.navCollapsedMob = false;
  }

  ngOnInit() {
    this.logo =null;
    if(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey)) != null){ 
      this.loadImage(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey)));
    }
  

    this.dataService.getCurrentInstitution().subscribe(institutionId => {
      this.loadImage(institutionId)
    });
    
    if (!this.logo){
      if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)) != undefined && this.aesEncryptDecryptService.decrypt(localStorage.getItem('credentials')) != null) {
        let data = JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)))
        this.logo = data.photo && data.photo.length > 0 ? data.photo : null
      }
    }
    
  }

  loadImage(institutionId){
    this.uploadLogoService.getInstitutionId(institutionId).then(res=>{
      if(res){ 
        this.logo= res.logo ;
      }
    })
  }



  navCollapse() {
    if (this.windowWidth >= 992) {
      this.navCollapsed = !this.navCollapsed;
      this.onNavCollapse.emit();
    }
  }

  navCollapseMob() {
    if (this.windowWidth < 992) {
      this.onNavCollapsedMob.emit();
    }
  }
}
