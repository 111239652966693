import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { AuthenticationService } from './../../../services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  accountInfo : FormGroup;
  constructor(    
    private authenticationService: AuthenticationService,  
    private router: Router,

      private formBuilder: FormBuilder,
 
    ) { }

  ngOnInit(): void {
    this.accountInfo = this.formBuilder.group({
      email: ['',Validators.required],
      passwordSendInEmail:['',Validators.required], 
      newPasswordCreated:['',Validators.required],
    })

  }

  onSubmit(){ 
    // this.authenticationService.getConfirmAccounts(this.accountInfo.value.email,this.accountInfo.value.passwordSendInEmail,this.accountInfo.value.newPasswordCreated).then(res=>{
    //    this.router.navigate(["/auth/login"]); 
    // })
  } 

}
  