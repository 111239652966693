<ul class="navbar-nav">
  <li appToggleFullScreen>
    <div *ngIf="currentUserDetails!=undefined " class="big-container">
      <div class="logo-outsid pro-head">
        <img [src]="currentUserDetails && currentUserDetails.photo ? currentUserDetails.photo : 'assets/images/moe-logo-300ppi.png'"
          class="" alt="User-Profile-Image">
      </div>
      <div class="container user-details"> 
        <span class="user-name">
          {{currentUserDetails.firstName}} {{currentUserDetails.lastName}} 
        </span>
        <span class="Admin">
         {{ role}}
        </span>
      </div>
    </div> 
    
  </li>
  <li class="nav-item">
    <app-nav-search></app-nav-search>
  </li>
</ul>
 