import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppSettings } from './../common/app.settings';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  /**
   * Gets all users
   * @returns all users
  */
  getAllUsers():Promise<any> {
    return this.http.put<any>(`${environment.getUsersRolesUrl}`,null).toPromise()
    .then(response => response as any);
  }

  /**
   * Gets all region
   * @returns all region
   */
  getAllRegions():Promise<any> {
    return this.http.put<any>(`${environment.getRegionsUrl}`,null).toPromise()
    .then(response => response as any);
  }


  /**
   * Gets all sub region
   * @param token
   * @returns all sub region
   */
  // getAllSubRegion(token,code): Promise<any> {
  //   let t = localStorage.getItem(AppSettings.authDataKey);
  //   const myArr = t.split('"');
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + token
  //     })
  //   };
  //   return this.http.get(`${environment.apiSpringUrl}/subRegion/getSubRegions/${code}`, httpOptions).toPromise().then(response => response as any);
  // }

  /**
   * Gets all groups
   * @param token
   * @returns all groups
   */
  // getAllGroups(token): Promise<any> {
  //   let t = localStorage.getItem(AppSettings.authDataKey);
  //   const myArr = t.split('"');
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + token
  //     })
  //   };
  //   return this.http.get(`${environment.apiSpringUrl}/group/getAllGroup`, httpOptions).toPromise().then(response => response as any);
  // }

  /**
   * Gets all institution
   * @param token
   * @returns all institution
   */
  getAllInstitution():Promise<any> {
    return this.http.put<any>(`${environment.addInstitution.getDistinctMainInstitutions}`,null).toPromise()
    .then(response => response as any);
  }

  getUserRole():Promise<any> {
    return this.http.put<any>(`${environment.getUsersRolesUrl}`,null).toPromise()
    .then(response => response as any);
  }


  /**
   * Adds user
   * @param data
   * @returns user
   */
  addUser(data): Promise<any> {
    return this.http.post(`${environment.addUserUrl}`, data).toPromise()
    .then(response => response as any).catch(error=>{
      return error
    });

  }

  //updateUser(userInfo, userInfoAndPassword, role ,roleId , token): Promise<any> {

    //let promises: Promise<any>[] = [];

    // promises.push(this.http.put(`${environment.apiSpringUrl}/user/updateUserInformation`, userInfo).toPromise());
    // if (userInfoAndPassword != null) {
    //   promises.push(this.http.put(`${environment.apiSpringUrl}/user/updateUserInformationAndPassword`, userInfoAndPassword).toPromise());
    // }
    // promises.push(this.http.put(`${environment.apiSpringUrl}/user/updateUsersRoles/${roleId}/${userInfo.userId}` , role).toPromise());

    ​

    //return Promise.all(promises).then(results => {
     //  return results

    //}).catch(error => {
     //  return error;
    //});
  //}

  /**
   * Deletes user
   * @param idUser
   */

  deleteUser(idUser):Promise<any> {
    return this.http.delete<any>(`${environment.deleteUserUrl}/${idUser}`).toPromise()
    .then(response => response as any);
  }

  updateUser(data):Promise<any> {
    return this.http.put<any>(`${environment.updateUserAndAccessLevels}`,data).toPromise()
    .then(response => response as any);
  }

  updateUserWithoutDataAcessLevel(data):Promise<any> {
    return this.http.put<any>(`${environment.updateUser}`,data).toPromise()
    .then(response => response as any);
  }

 
  postInstitutionLogo(institution ) {
    return this.http.post(`${environment.postInstitutionLogo}`,institution).toPromise().then(response => response as any);
  }

  checkOldPassword(password){
    let pswd = {
      "password": password
    }
    return this.http.post(`${environment.checkOldPassword}`,pswd).toPromise().then(response => response as any);
  }

  getLanguages():Promise<any> {
    return this.http.get<any>(`${environment.getLanguages}`).toPromise().then(response => response as any);
  }

  resetPasswordByEmail(email):Promise<any> {
    return this.http.put<any>(`${environment.resetPasswordByEmail}/${email}`, email).toPromise()
    .then(response => response as any);
  }
}

