import { AppSettings } from './../../../../common/app.settings';
import { AppUtilities } from './../../../../common/app.utilities';
import { Title } from '@angular/platform-browser';
import { MainService } from './../../../../services/main.service';
import { Injectable } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { Router } from '@angular/router';
import { WelcomeServicesService } from '../../../../services/welcome-services.service';
import { AESEncryptDecryptService } from 'src/app/services/security/aesencrypt-decrypt.service';
export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  order?: any;
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

// Get children of a menu item
function getChildren(menus: Object[], item: Object): any[] {
  const submenu = menus.filter(m => m['parentId'] == item['menuId']);
  //let sortedSubmenu = submenu.sort((a:any, b:any) => (a.order > b.order) ? 1 : -1)
  return submenu;
}


//Dynamically read menu items from database and put them in the
//Correct format to be read and displayed by the template
@Injectable()
export class NavigationItem {
  public navItems: NavigationItem[] = [];

  constructor(
    private mainService: MainService,
    public welcomeService: WelcomeServicesService,
    private router: Router,
    private dataService: DataService,
    private aesEncryptDecryptService: AESEncryptDecryptService
  ) {
    let institutionId
    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey))){
      this.navItems = [] as NavigationItem[];
      institutionId = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey))
      console.log(institutionId);
      const lang = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.languageKey));
      this.setNavItems(lang, institutionId);
      this.dataService.changeMenuList(this.navItems);
    }
    


    this.loadMenuByInstitution(null) // In case : instutionId is undefined
    
    this.dataService.getCurrentInstitution().subscribe(institutionId => {
      this.loadMenuByInstitution(institutionId)
    }); 

    //Load menu and change it based on userRole change
/*     this.dataService.getUserRole().subscribe(userRole => {
      
      if (!this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey))) {
        this.navItems = [] as NavigationItem[];
        const lang = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.languageKey));
        this.setNavItemsByRoleName(lang, userRole);
        this.dataService.changeMenuList(this.navItems);
      }
    }); */

  }

   loadMenuByInstitution(institutionId) {
    this.navItems = [] as NavigationItem[];
    const lang = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.languageKey));
    this.setNavItems(lang, institutionId);
    this.dataService.changeMenuList(this.navItems);
    // this.router.navigate(["/welcome"]);
  } 


  setNavItems(lang, institutionId) {
    this.mainService.getUserMenus(lang, institutionId).then(menus => {

      const firstLevelMenus = menus.filter(m => m["menuLevel"] == 1).sort((m1, m2) => m1.order - m2.order);

      firstLevelMenus.forEach(item => {
        let navItem = <NavigationItem>{
          id: item.menuId,
          title: item.menuDescription,
          icon: item.menuIcon,
          order: item.order,
          //type: 'collapse',
          //url: item.menuUrl,
        }

        let menuType = '';
        const firstChildren = getChildren(menus, item);
        if (firstChildren.length == 0) {
          navItem.type = 'item';
          navItem.url = item.menuUrl;
        } else {
          navItem.type = 'collapse';
          let childrenLevelOne: NavigationItem[] = [];
          let sortedFirstChildren = firstChildren.sort((c1, c2) => c1.order - c2.order);
          sortedFirstChildren.forEach(child => {
            const secondChildren = getChildren(menus, child);
            let childNavItem = <NavigationItem>{
              id: child.menuId,
              title: child.menuDescription,
              // type: 'item',
              // url: child.menuUrl,
            };
            if (secondChildren.length == 0) {
              childNavItem.type = 'item';
              childNavItem.url = child.menuUrl;
            } else {  //we have third level menu
              let childrenLevelTwo: NavigationItem[] = [];
              let sortedSecondChildren = secondChildren.sort((c1, c2) => c1.order - c2.order);
              sortedSecondChildren.forEach(child => {
                let childNavItem = <NavigationItem>{
                  id: child.menuId,
                  title: child.menuDescription,
                  type: 'item',
                  url: child.menuUrl,
                };
                if (childrenLevelTwo.length == 0) {
                  childrenLevelTwo.push(childNavItem);
                } else {
                  let find = childrenLevelTwo.findIndex(nav => nav.id == childNavItem.id);
                  if (find == -1) {
                    childrenLevelTwo.push(childNavItem);
                  }
                }
              });
              childNavItem.type = 'collapse';
              childNavItem.children = childrenLevelTwo;
            }
            if (childrenLevelOne.length == 0) {
              childrenLevelOne.push(childNavItem);
            } else {
              let find = childrenLevelOne.findIndex(nav => nav.id == childNavItem.id);
              if (find == -1) {
                childrenLevelOne.push(childNavItem);
              }
            }
          });
          navItem.children = childrenLevelOne;

        }
        if (this.navItems.length == 0) {
          this.navItems.push(navItem);
        } else {
          let find = this.navItems.findIndex(nav => nav.id == navItem.id);
          if (find == -1) {
            this.navItems.push(navItem);
          }
        }
      });
    });
  }

/*   setNavItemsByRoleName(lang, institutionId) {
    this.mainService.getMenusByRoleName(lang, institutionId).subscribe(menus => {

      const firstLevelMenus = menus.filter(m => m["menuLevel"] == 1).sort((m1, m2) => m1.order - m2.order);

      firstLevelMenus.forEach(item => {
        let navItem = <NavigationItem>{
          id: item.menuId,
          title: item.menuDescription,
          icon: item.menuIcon,
          //type: 'collapse',
          //url: item.menuUrl,
        }

        let menuType = '';
        const firstChildren = getChildren(menus, item);
        if (firstChildren.length == 0) {
          navItem.type = 'item';
          navItem.url = item.menuUrl;
        } else {
          navItem.type = 'collapse';
          let childrenLevelOne: NavigationItem[] = [];
          let sortedFirstChildren = firstChildren.sort((c1, c2) => c1.order - c2.order);
          sortedFirstChildren.forEach(child => {
            const secondChildren = getChildren(menus, child);
            let childNavItem = <NavigationItem>{
              id: child.menuId,
              title: child.menuDescription,
              // type: 'item',
              // url: child.menuUrl,
            };
            if (secondChildren.length == 0) {
              childNavItem.type = 'item';
              childNavItem.url = child.menuUrl;
            } else {  //we have third level menu
              let childrenLevelTwo: NavigationItem[] = [];
              let sortedSecondChildren = secondChildren.sort((c1, c2) => c1.order - c2.order);
              sortedSecondChildren.forEach(child => {
                let childNavItem = <NavigationItem>{
                  id: child.menuId,
                  title: child.menuDescription,
                  type: 'item',
                  url: child.menuUrl,
                };
                if (childrenLevelTwo.length == 0) {
                  childrenLevelTwo.push(childNavItem);
                } else {
                  let find = childrenLevelTwo.findIndex(nav => nav.id == childNavItem.id);
                  if (find == -1) {
                    childrenLevelTwo.push(childNavItem);
                  }
                }
              });
              childNavItem.type = 'collapse';
              childNavItem.children = childrenLevelTwo;
            }
            if (childrenLevelOne.length == 0) {
              childrenLevelOne.push(childNavItem);
            } else {
              let find = childrenLevelOne.findIndex(nav => nav.id == childNavItem.id);
              if (find == -1) {
                childrenLevelOne.push(childNavItem);
              }
            }
          });
          navItem.children = childrenLevelOne;

        }
        if (this.navItems.length == 0) {
          this.navItems.push(navItem);
        } else {
          let find = this.navItems.findIndex(nav => nav.id == navItem.id);
          if (find == -1) {
            this.navItems.push(navItem);
          }
        }
      });
    });
  } */

  get() {
    return this.navItems; // NavigationItems;
  }
}
