import { ReportingModule } from './views/report/reporting.module';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { ProfileComponent } from './views/profile/profile.component';
import { MenuGuard } from './guards/menu.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full'
      },
      {
        path: 'welcome',
        loadChildren: () => import('./views/extra/sample-page/sample-page.module').then(m => m.SamplePageModule),
      },
      {
        path: 'management',
        loadChildren: () => import('./views/management/management.module').then(m => m.ManagementModule)
      },
      {
        path: 'history',
        loadChildren: () => import('./views/history/history.module').then(m => m.HistoryModule)
      },
      {
        path: 'general-data',
        loadChildren: () => import('./views/general-data/general-data.module').then(m => m.GeneralDataModule)
      },
      {
        path: 'student',
        loadChildren: () => import('./views/student/student.module').then(m => m.StudentModule)
      },
      {
        path: 'kpis',
        loadChildren: () => import('./views/kpis/kpis.module').then(m => m.KpisModule)
      },
      {
        path: 'institution',
        loadChildren: () => import('./views/institution/institution.module').then(m => m.InstitutionModule)
      },
      {
        path: 'staff',
        loadChildren: () => import('./views/staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: 'unesco',
        loadChildren: () => import('./views/unesco/unesco.module').then(m => m.UnescoModule)
      },
      {
        path: 'upload',
        loadChildren: () => import('./views/upload/upload.module').then(m => m.UploadModule)
      },
      {
        path: 'validate',
        loadChildren: () => import('./views/validate/validate.module').then(m => m.ValidateModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'todo',
        loadChildren: () => import('./views/todo/todo.module').then(m => m.TodoModule)
      },
      {
        path: 'standardization',
        loadChildren: () => import('./views/standardization/standardization.module').then(m => m.standardizationModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.modules').then(m => m.AdminModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/report/reporting.module').then(m => m.ReportingModule)
      },
      {
        path: 'ministry',
        loadChildren: () => import('./views/ministry/ministry.module').then(m => m.MinistryModule)
      },
      {
        path: 'details',
        loadChildren: () => import('./views/details/details.module').then(m => m.DetailsModule)
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate:[AuthGuard],
      },
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./views/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
