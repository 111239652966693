<div class="auth-wrapper">
  <div class="auth-content">
    <div class="auth-bg">
      <span class="r"></span>
      <span class="r s"></span>
      <span class="r s"></span>
      <span class="r"></span>
    </div>
    <div class="card">
      <div class="card-body text-center">
        <form   (ngSubmit)="onSubmit()">
          <div class="mb-4">
            <i class="feather icon-mail auth-icon"></i>
            <h3 class="mb-4">{{ 'connectionConfirmation.EmailConfirmation' | translate }}</h3> 
          </div> 
          <div class="input-group mb-3  div-password">
            <input type="email" (keyup)="checkButton()" class="form-control input-password"  [ngModelOptions]="{standalone: true}"  [(ngModel)]="data.email"  placeholder="Email" required>
            <small class="p-invalid" *ngIf="submitted && (!data.email || (data.password && data.email.length == 0))  ">{{ 'AccountCrud.ThisFieldIsRequired' | translate }}.</small>  

          </div> 
        
 
          <div class="input-group mb-3 div-password">
            <input (keyup)="checkButton()" type="password" class="form-control input-password" [ngModelOptions]="{standalone: true}"  [(ngModel)]="data.passwordSendInEmail"
              placeholder="Password Sent In Email" required>
              <small class="p-invalid" *ngIf="submitted && (!data.passwordSendInEmail ||  (data.passwordSendInEmail && data.passwordSendInEmail.length==0))  "><br>{{ 'AccountCrud.ThisFieldIsRequired' | translate }}.</small>     
  
          </div> 
          <div class="input-group mb-3 div-password">
            <input (keyup)="checkButton()" placeholder="{{ 'AccountCrud.Password' | translate }}" class="form-control input-password" type="password" [ngModelOptions]="{standalone: true}" pInputText id="newPassword" [(ngModel)]="data.password" autocomplete="new-password" required />
            <password-strength-meter (strengthChange)="strengthChanges($event)" [password]="data.password" [enableFeedback]="true"></password-strength-meter>           
            <small class="p-invalid" *ngIf="submitted && (!data.password || (data.password && data.password.length == 0))  ">{{ 'AccountCrud.ThisFieldIsRequired' | translate }}.</small>  
          </div>  
          <div class="input-group mb-3 div-password">
            <input (keyup)="checkButton()" class="form-control input-password" placeholder="{{ 'AccountCrud.ConfirmPassword' | translate }}" type="password" [ngModelOptions]="{standalone: true}" pInputText id="confPassword" [(ngModel)]="data.confPassword" autocomplete="new-password" required />
            <small class="p-invalid" *ngIf="submitted && !data.userId && (!data.confPassword || (data.confPassword && data.confPassword.length == 0))">{{ 'AccountCrud.ThisFieldIsRequired' | translate }}.</small>
            <small class="p-invalid" *ngIf="submitted && (data.password && data.password.length!=0) && (data.confPassword != data.password)"><br>{{ 'connectionConfirmation.fieldSimilarPassword' | translate }}.</small>     
          </div>

       
          <button [disabled]="disableSendButton !== false" pButton pRipple icon="pi pi-check" label="Confirm" class="p-button-text valid-button" ></button>
          <!-- <button class="btn btn-primary mb-4">Reset Password</button> -->
        </form>
      </div>
    </div>
  </div>
</div>