import { Component, OnInit } from '@angular/core';
import { AccountsService } from './../../../services/authentication/accounts.service';
import { FormBuilder, FormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { ToasterService, Toast } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Dialog } from 'primeng/dialog';
import { AppKeys } from './../../../common/app.key';
import { AppUtilities } from './../../../common/app.utilities';
import { DatePipe, registerLocaleData } from '@angular/common';

import * as cloneDeep from 'lodash/cloneDeep';

import { DataService } from './../../../services/data.service';
import { PolicygoalsService } from './../../../services/policygoals.service';
import { AppSettings } from 'src/app/common/app.settings';
import { environment } from 'src/environments/environment';

//models
import { policyDetails } from './../../../models/policyDetails';
import { policy } from './../../../models/policy';
import { stakeholders } from './../../../models/stakeholders';

@Component({
  selector: 'app-policy-goals',
  templateUrl: './policy-goals.component.html',
  styleUrls: ['./policy-goals.component.scss']
})
export class PolicyGoalsComponent implements OnInit {

  //Toaster
  toasterUpdateUserSuccess: string;
  toasterUpdateUserError: string;
  toasterAddUserSuccess: string;
  toasterAddUserError: string;
  toasterDeleteUserSuccess: string;
  toasterDeleteUserError: string;
  toasterSumPerformanceError: string;
  toasterSumPerformanceIndicator: string;
  defaultLang: string;

  policyList: policy[];

  policyDetailsList: policyDetails[];


  policyListClone: policy[];

  policyDetailsListClone: policyDetails[];

  productDialog: Boolean;
  submitted: Boolean;

  productDetailsDialog: Boolean;

  selectedPolicy: policy;

  stakeholders: stakeholders[];

  selectedStakeHolders: policy;
  heiClasses: any;
  selectedHeiClass: any;
  isHed: boolean = false;

  minScore :number=0;
  baseScore :number=0;
  targetScore :number=0;
  maxScore :number=0;

  constructor(
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private policyGoalsService: PolicygoalsService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private accountsService: AccountsService,
  ) { }


  ngOnInit(): void {
    this.dataService.getCurrentLang().subscribe(res => {
      this.defaultLang = res;
      this.getAllTranslations().then(res => {
        this.loadData();
      });
    });
  }

  loadData() {

    let promises: Promise<any>[] = [];

    promises.push(this.policyGoalsService.getStakeholders())

    promises.push(this.policyGoalsService.getHeiClasses(this.defaultLang));

    Promise.all(promises).then(results => {
      if (environment.categ == "HED") {
        this.stakeholders = cloneDeep(results[0].filter(stake => stake.stakeholderId == 100 || stake.stakeholderId == 200));
        this.heiClasses = cloneDeep(results[1].filter(classes => classes.classId != 0));
      } else {
        this.stakeholders = cloneDeep(results[0].filter(stake => stake.stakeholderId == 300 || stake.stakeholderId == 400));

      }
    });
  }


  getAllTranslations(): Promise<any> {
    const keys = AppKeys.policyGoals;
    return this.translateService.get(keys).toPromise().then(
      results => {
        this.toasterUpdateUserSuccess = results[keys[0]];
        this.toasterUpdateUserError = results[keys[1]];
        this.toasterDeleteUserSuccess = results[keys[2]];
        this.toasterDeleteUserError = results[keys[3]];
        this.toasterSumPerformanceError = results[keys[4]];
        this.toasterSumPerformanceIndicator = results[keys[5]];
      }
    );
  }

  /**
   * Stakes holders changed
   */
  stakeHoldersChanged() {
    if (Number(this.selectedStakeHolders) == 200) {
      this.isHed = true;
      this.selectedHeiClass = null;
      this.policyList = []
    } else {
      this.isHed = false;
      this.selectedHeiClass = 0;
    }


    if (this.selectedHeiClass != undefined && this.selectedHeiClass != null) {
      this.policyGoalsService.getPolicyObjectivesByStakeholdeAndClass(this.selectedStakeHolders, this.selectedHeiClass).then(results => {
        this.policyList = cloneDeep(results);
        this.policyListClone = cloneDeep(results);

        this.minScore =this.policyListClone[0].minScore;
        this.baseScore =this.policyListClone[0].baseScore;
        this.targetScore =this.policyListClone[0].targetScore;
        this.maxScore=this.policyListClone[0].maxScore;

      })
    }

  }

  /**
   * Heis class changed
   */
  heiClassChanged() {
    if (this.selectedStakeHolders != undefined && this.selectedStakeHolders != null) {
      this.policyGoalsService.getPolicyObjectivesByStakeholdeAndClass(this.selectedStakeHolders, this.selectedHeiClass).then(results => {
        this.policyList = cloneDeep(results);
        this.policyListClone = cloneDeep(results);
        
        this.minScore =this.policyListClone[0].minScore;
        this.baseScore =this.policyListClone[0].baseScore;
        this.targetScore =this.policyListClone[0].targetScore;
        this.maxScore=this.policyListClone[0].maxScore;
      })
    }
  }

  showDetails(product) {
    this.selectedPolicy = product
    this.policyGoalsService.getPerformanceIndicatorsByPolicy(product.policyObjectiveId, this.selectedHeiClass, this.defaultLang).then(res => {
      this.policyDetailsList = cloneDeep(res);
      this.policyDetailsListClone = cloneDeep(res);
    });
  }


  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }
  hideDetailsDialog() {
    this.productDetailsDialog = false;
  }

  editRecords() {
    this.productDialog = true;
  }


  editDetails() {
    this.productDetailsDialog = true;
  }

  saveProduct() {
    let total = AppUtilities.sum(this.policyListClone, "weight")

    this.policyListClone.filter(policy => policy.minScore = this.minScore);
    this.policyListClone.filter(policy => policy.maxScore = this.maxScore);
    this.policyListClone.filter(policy => policy.targetScore = this.targetScore);
    this.policyListClone.filter(policy => policy.baseScore = this.baseScore);

    let findMaxScore = this.policyListClone.filter(policy => policy.maxScore > policy.minScore);
    let findTargetScore = this.policyListClone.filter(policy => policy.targetScore >= policy.minScore && policy.targetScore <= policy.maxScore)
    let findBseScore = this.policyListClone.filter(policy => policy.baseScore >= policy.minScore && policy.baseScore <= policy.maxScore)


    let findNegativeValue= this.policyListClone.filter(policy => policy.maxScore >=0 && policy.minScore >=0 && policy.baseScore >=0  && policy.targetScore >=0 );

    if(findNegativeValue.length != this.policyListClone.length){
      this.callToaster("error", null, "Value must be positive",0);
    }else  if (findMaxScore.length != this.policyListClone.length) {
      this.callToaster("error", null, "Max score needs to be higher than min score",0);
    } else if (findTargetScore.length != this.policyListClone.length) {
      this.callToaster("error", null, "Target score needs to be between min score and max score",0);
    } else if (findBseScore.length != this.policyListClone.length) {
      this.callToaster("error", null, "Base score needs to be between min score and max score",0);
    }
    else if (total == 100) {
      this.policyGoalsService.UpdatePolicyObjectivesList(this.policyListClone, this.selectedHeiClass).then(res => {
        if (res.status == "Policy Objectives Updated") {
          this.callToaster("success", null, this.toasterUpdateUserSuccess,5000);
          this.policyList = cloneDeep(this.policyListClone);
          this.productDialog = false;
          this.productDetailsDialog = false;
        }
      });
    } else {
      this.callToaster("error", null, this.toasterSumPerformanceError,0);
    }
  }


  saveDetails() {

    let total = AppUtilities.sum(this.policyDetailsListClone, "weight")

    let policy: policy;
    policy = cloneDeep(this.selectedPolicy);
    policy.performanceIndicators = this.policyDetailsListClone;

    let findMaxScore = this.policyDetailsListClone.filter(policy => policy.maxScore > policy.minScore);
    let findTargetScore = this.policyDetailsListClone.filter(policy => policy.targetScore >= policy.minScore && policy.targetScore <= policy.maxScore)
    let findBseScore = this.policyDetailsListClone.filter(policy => policy.baseScore >= policy.minScore && policy.baseScore <= policy.maxScore)

    let findNegativeValue= this.policyDetailsListClone.filter(policy => policy.maxScore >=0 && policy.minScore >=0 && policy.baseScore >=0  && policy.targetScore >=0 );

    if(findNegativeValue.length != this.policyDetailsListClone.length){
      this.callToaster("error", null, "Value must be positive",0);
    }else if (findMaxScore.length != this.policyDetailsListClone.length) {
      this.callToaster("error", null, "Max score needs to be higher than min score",0);
    } else if (findTargetScore.length != this.policyDetailsListClone.length) {
      this.callToaster("error", null, "Target score needs to be between min score and max score",0);
    } else if (findBseScore.length != this.policyDetailsListClone.length) {
      this.callToaster("error", null, "Base score needs to be between min score and max score",0);
    }
    else
      if (total == 100) {
        this.policyGoalsService.UpdatePolicyObjectives(this.policyDetailsListClone, this.selectedHeiClass).then(res => {
          if (res.status == "Performance Indicators Updated") {
            this.callToaster("success", null, this.toasterUpdateUserSuccess,5000);
            this.policyDetailsList = this.policyDetailsListClone;
            this.productDetailsDialog = false;
            this.productDialog = false;
          }
        })

      } else {
        this.callToaster("error", null, this.toasterSumPerformanceIndicator,0);
      }
  }



  /**
* Calls toaster
* @param toasterTitle
* @param toasterBody
*/
  callToaster(type, toasterTitle, toasterBody,timeout) {
    const noSelectionTeams: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
    };
    this.toasterService.pop(noSelectionTeams);
  }


}
