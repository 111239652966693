import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toast, ToasterService } from 'angular2-toaster';
import { AppUtilities } from 'src/app/common/app.utilities';
import { AccountsService } from 'src/app/services/authentication/accounts.service';
import { InstitutionService } from 'src/app/services/institution.service';
import { LoaderService } from 'src/app/services/interceptor/loader.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userId: any;
  myRoleLevel: any;
  myRegionId: any;
  roleName: any;
  username: any;
  email: any;
  firstName: any;
  lastName: any;
  createdDate: any;
  name: string;
  institutionId: any;
  institutionName: any;
  institution: any;
  userDetails: any;
  defAcademicYear: any;
  myRegionName: any;
  formGroup: FormGroup;

  registrationForm = this.fb.group({
    file: [null]
  })
  @ViewChild('fileInput') el: ElementRef;
  imageUrl;
  editFile: boolean = true;
  removeUpload: boolean = false;
  address: any;
  password: any;
  primaryRoleAssignedId: any;
  photo: any;
  strengthChange: any;
  oldPassword: any;
  allLanguages: any;
  preferredLanguageId: any;
  submitted = false;

  constructor(private accountsService: AccountsService,
    private datePipe: DatePipe,
    private institutionService: InstitutionService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private toasterService: ToasterService,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      username: [this.username, Validators.required],
      email: [this.email, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required]],
      firstName: [this.firstName, Validators.required],
      lastName: [this.lastName, Validators.required],
      oldPassword: ['',],
      newPassword: ['',],
      address: ['',],
      preferredLanguageId:[,],
    });
    this.setMyAccount();
    this.setRegions();
    this.setLanguages();
  }

  setMyAccount() {
    this.loaderService.show()
    let token = AppUtilities.getDecodedAccessToken();
    this.username = token.username;
    this.userId = token.userId
    this.accountsService.getRoleByUsername(this.username).then(res => {
      this.userDetails = res;
      this.userDetails.photo ? this.imageUrl = this.userDetails.photo : this.imageUrl = 'assets/images/user/avatar-1.svg'
      this.userDetails.photo ? this.photo = this.userDetails.photo : this.photo = 'assets/images/user/avatar-1.svg'
      this.primaryRoleAssignedId = res.primaryRoleAssignedId;
      this.myRoleLevel = res.roleLevel
      this.myRegionId = res.region
      this.email = res.email
      this.address = res.address
      this.firstName = res.firstName;
      this.lastName = res.lastName;
      this.firstName || this.lastName ? this.name = this.firstName + ' ' + this.lastName : null;
      this.roleName = res.roleName.split('_').join(' ');
      this.createdDate = this.datePipe.transform(res.createdDate, ' MMMM dd, yyyy');
      this.institutionId = res.institutionId;
      this.institutionName = res.institutionName;
      this.preferredLanguageId = res.preferredLanguageId
      this.loaderService.hide()
      this.setInstitution()
    });
  }

  setInstitution() {
    if (this.institutionName && this.institutionName.length > 0) {
      this.institution = this.institutionName;
    } else if (this.institutionId && this.institutionId.length > 0) {
      this.accountsService.getDefaultAcademicYear().then(d => {
        this.defAcademicYear = d.academicYearCode;
        this.institutionService.getMainInstitutionsByYear(this.defAcademicYear).then(res => {
          this.institution = res.filter(data => this.institutionId == data.institutionId);
        })
      })
    } else {
      this.institution = null;
    }
  }

  setRegions() {
    this.accountsService.getRegions().then(res => {
      let myRegion = res.filter(d => d.regionCode == this.myRegionId);
      this.myRegionName = myRegion.regionName
    })
  }

  setLanguages(){
    this.userService.getLanguages().then(res => {
      this.allLanguages = res
    })
  }

  uploadPhoto(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.registrationForm.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }


  strengthChanges(value) {
    this.strengthChange = value;
  }

  save() {
    let validOldPassword = true;
    let validForm = true
    this.submitted = true;
    this.userDetails.preferredLanguageId = this.preferredLanguageId
    this.userDetails.userId = this.userId
    this.userDetails.primaryRoleAssignedId = this.primaryRoleAssignedId
    this.userDetails.email = this.email
    this.userDetails.firstName = this.firstName
    this.userDetails.lastName = this.lastName
    this.userDetails.address = this.address
    this.userDetails.username = this.username

    if (this.formGroup.valid){
      if (this.password && this.password.length > 0 && this.registrationForm.value.file) {
        this.userDetails.password = this.password
        this.userDetails.photo = this.registrationForm.value.file
      }
      else if (this.password && this.password.length > 0) {
        this.userDetails.password = this.password
      }
      else if (this.registrationForm.value.file) {
        this.userDetails.photo = this.registrationForm.value.file
      }
  
      if (!this.oldPassword && this.password && this.password.length > 0){
        validForm = false
        this.callToaster('error', null, "Please enter the old password",10000);
      } else if (!this.password && this.oldPassword && this.oldPassword.length > 10000){
        validForm = false
        this.callToaster('error', null, "Please enter the new password",10000);
      } else if (this.strengthChange < 3) {
        validForm = false
        this.callToaster('error', null, "Please enter a stronger password",10000);
      } else if( this.password && this.password.length > 0 ){
        this.userService.checkOldPassword(this.oldPassword).then(data => {
          if (!data || (data && data.status && data.status == 500)){
            validOldPassword = false;
            validForm = false
            this.callToaster('error', null, "Invalid old password",10000);
          } 
        }).catch(error => {
          validForm = false
          validOldPassword = false;
        }).finally(() => {
          if (validForm && validOldPassword && (!this.strengthChange || this.strengthChange >= 3) ){
            this.userService.updateUserWithoutDataAcessLevel(this.userDetails).then(res => {
              if (res.status && (res.status < 200 || res.status > 299)) {
                this.callToaster('error', null, "An error occurred while saving details",10000);
              } else {
                this.callToaster('success', null, "Account Updated",5000);
                this.submitted = false;
                setTimeout(function () { location.reload(); }, 2000);
              }
            })
          }
        })
      } else {
        this.userService.updateUserWithoutDataAcessLevel(this.userDetails).then(res => {
          if (res.status && (res.status < 200 || res.status > 299)) {
            this.callToaster('error', null, "An error occurred while saving details",10000);
          } else {
            this.callToaster('success', null, "Account Updated",5000);
            this.submitted = false;
            setTimeout(function () { location.reload(); }, 2000);
          }
        })
      }
    } else {
      this.callToaster('error', null, "Please check the fields",10000);
    }

  }

  /**
   * Calls toaster
   * @param toasterTitle
   * @param toasterBody
   */
  callToaster(type, toasterTitle, toasterBody,timeout) {
    const toaster: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
    };
    this.toasterService.pop(toaster);
  }

}
