import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const maxAge = 1000;

@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {

  cache = new Map();

  constructor() { }

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) { return undefined; }

    const isExpired = cached.lastRead < (Date.now() - maxAge);
    const expired = isExpired ? 'expired ' : '';
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {

    if (!req.url.toString().includes('helper') && !req.url.toString().includes('ui')) {
      const url = req.urlWithParams;
      const entry = { url, response, lastRead: Date.now() };
      this.cache.set(url, entry);

      const expired = Date.now() - maxAge;
      this.cache.forEach(expiredEntry => {
        if (expiredEntry.lastRead < expired) {
          this.cache.delete(expiredEntry.url);
        }
      });
    }
  }
  
}
