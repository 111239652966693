import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppUtilities } from 'src/app/common/app.utilities';
import { AccountsService } from 'src/app/services/authentication/accounts.service';

import { UploadLogoService } from "../../../../../services/upload-logo.service";
import { DataService } from "../../../../../services/data.service";

import { AppSettings } from "../../../../../common/app.settings";
import { AESEncryptDecryptService } from 'src/app/services/security/aesencrypt-decrypt.service';

@Component({
  selector: 'app-nav-logo',
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.scss']
})
export class NavLogoComponent implements OnInit {
  @Input() navCollapsed: boolean;
  @Output() onNavCollapse = new EventEmitter();
  public windowWidth: number;
  username: any;
  userId: any;
  userDetails: any;
  logo: any;

  constructor(
    private accountsService: AccountsService,
    private uploadLogoService: UploadLogoService,
    private dataService: DataService,
    private aesEncryptDecryptService: AESEncryptDecryptService
  ) {
    this.windowWidth = window.innerWidth;


    this.logo = null;
    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey)) != null) {
      this.loadImage(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.institutionKey)));
    }

    this.dataService.getCurrentInstitution().subscribe(institutionId => {
      this.loadImage(institutionId)
    });

    this.setMyAccount()



  }

  ngOnInit() {

  }

  loadImage(institutionId) {
    this.uploadLogoService.getInstitutionId(institutionId).then(res => {
      if (res != undefined && res != null) {
        this.logo = res.logo;
      }
    })
  }


  navCollapse() {
    if (this.windowWidth >= 992) {
      this.navCollapsed = !this.navCollapsed;
      this.onNavCollapse.emit();
    }
  }

  setMyAccount() {
    let token = AppUtilities.getDecodedAccessToken();
    this.username = token.username;
    this.userId = token.userId;
    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)) != null) {
      this.userDetails = JSON.parse(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.roleByUsernameKey)));
    }
    this.dataService.getRoleByUsername().subscribe(result => {
      
      this.userDetails =result;
        });
    console.log(this.userDetails)
  }
}
