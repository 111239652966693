import { AppUtilities } from './../common/app.utilities';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ToasterService, Toast } from 'angular2-toaster';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private http: HttpClient,
              private datePipe: DatePipe,
              private toasterService: ToasterService) {}

  /****************************************************************************/
  getMenus() {
    return this.http.get<any>(environment.menuUrl);
  }
  getMenusLanguage(language) {
    return this.http.get<any>(`${environment.menuUrl}/${language}`);
  }
  getMenusByRoleName(language, role) {
    return this.http.get<any>(`${environment.menuRyRoleNameUrl}/${language}/${role}`);
  }

/*   getMenusByInstitutionId(language, institutionId) {
    return this.http.get<any>(`${environment.menuByInstitutionId}/${language}/${institutionId}`);
  }

  getMenusByInstitutionIdMethod(language, institutionId):Promise<any> {

    return this.http.get<any>(`${environment.menuByInstitutionId}/${language}/${institutionId}`).toPromise()

    .then(response => response as any);

  } */

  getUserMenus(language, institutionId):Promise<any> {
    if (institutionId){
      return this.http.get<any>(`${environment.getUserMenusUrl}/${language}/${institutionId}`).toPromise()
      .then(response => response as any);
    }

    return this.http.get<any>(`${environment.getUserMenusUrl}/${language}`).toPromise()
      .then(response => response as any);
  }

  public formatFile(file){
    if(file.creationDate !=null) {
      file.creationDateConverted = this.datePipe.transform(file.creationDate, 'dd/MM/yyyy').toString();
    }else{
      file.creationDateConverted='';
    }
    file.fileSizeConverted = AppUtilities.formatBytes(file.fileSize, 2).toString();
    if(file.stepExecutionId !=null) { //file has been processed
      file.correctRecords = file.writeCount;
      file.incorrectRecords = file.readCount - file.writeCount;
      file.endTimeConverted = this.datePipe.transform(file.endTime, 'dd/MM/yyyy HH:mm:ss').toString()
    }else{
      file.endTimeConverted='';
      file.correctRecords='';
      file.incorrectRecords='';
    }
    file.fileStatusConverted = file.fileStatus=='NONE'?'':file.fileStatus;
    return file;
  }

  public formatFilesList(filesList) {
    let result = filesList.map(f => {
      return this.formatFile(f);
    });
    return result;
  }


  /**
  * Calls toaster
  * @param toasterTitle
  * @param toasterBody
  */
   callToaster(type, toasterTitle, toasterBody,timeout) {
    const noSelectionTeams: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
    };
    this.toasterService.pop(noSelectionTeams);
  }

  successToaster(message){
    this.callToaster('success', null, message,5000);
  }
  errorToaster(message){ 
    this.callToaster('error', null, message,10000);
  }

} 
