import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { AccountsComponent } from './accounts/accounts.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTableModule, DataTableResourceModule } from 'ornamentum';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from '../../theme/shared/shared.module';
import { ConnectionConfirmationComponent } from './connection-confirmation/connection-confirmation.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ToastModule } from 'primeng/toast';

import { InputMaskModule } from 'primeng/inputmask';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ExpiredPasswordComponent } from './expired-password/expired-password.component';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'accounts',
        component: AccountsComponent,
      },
    ]
  },
  //{ path: '**', redirectTo: 'login' }
];

@NgModule({
  declarations: [
    LoginComponent,
    AccountsComponent,
    ConnectionConfirmationComponent,
    EmailConfirmationComponent,
    ResetPasswordComponent,
    ExpiredPasswordComponent,
    UnauthorizedPageComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    AuthenticationRoutingModule,
    CommonModule,
    SharedModule,
    FileUploadModule,
    MultiSelectModule,
    TooltipModule,
    DataTableModule.forRoot(),
    DataTableResourceModule.forRoot(),
    ButtonModule,
    TableModule,
    InputMaskModule,
    ToastModule,

    DialogModule,
    PasswordStrengthMeterModule,
    NgSelectModule,
  ],
  providers: [
  ]
})
export class AuthenticationModule { }
