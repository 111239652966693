<div class="navbar-brand header-logo">
  <a [ngClass]="{'on' : navCollapsed}" id="mobile-collapse" (click)="navCollapse()" [routerLink]="['/dashboard/default/']" class="b-brand">
    <ng-container  *ngIf="userDetails && !userDetails.logo && logo== null">
      <div [ngClass]="{'fadeOut' : navCollapsed == false, 'fadeIn' : navCollapsed == true}" >
        <img class="edligoIconLogo" src="../../../../../../assets/images/EERNIconLogo.png" width="50px" alt=""> 
      </div>  
      <div class="edligoLogo">
        <img src="../../../../../../assets/images/EERN.png" width="125px" alt="">
      </div>
    </ng-container >

    <ng-container  *ngIf="logo != null">
      <div [ngClass]="{'fadeOut' : navCollapsed == false, 'fadeIn' : navCollapsed == true}" >
        <img class="edligoIconLogo" [src]="logo" width="50px" alt=""> 
      </div>  
      <div *ngIf="!navCollapsed" class="edligoLogo" >
        <img [src]="logo" alt="">
      </div>
    </ng-container >

    <ng-container  *ngIf="userDetails && userDetails.logo && logo == null">
      <div [ngClass]="{'fadeOut' : navCollapsed == false, 'fadeIn' : navCollapsed == true}" >
        <img class="edligoIconLogo" [src]="userDetails.logo" width="50px" alt=""> 
      </div>  
      <div *ngIf="!navCollapsed" class="edligoLogo" >
        <img [src]="userDetails.logo" width="125px" alt="">
      </div>
    </ng-container >
    <!-- <span class="b-title">EDLIGO</span> -->
  </a>
  <a href="javascript:" class="mobile-menu" [ngClass]="{'on' : navCollapsed}" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
</div>
