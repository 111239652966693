export class AppKeys {

    public static login = [
        'Login.userName',
        'Login.password'
    ];
    public static users = [
        'Users.ROLE_ADMIN',
        'Users.ROLE_MINISTRY_SUPERINTENDENT',
        'Users.ROLE_STATE_SUPERINTENDENT',
        'Users.ROLE_REGION_SUPERINTENDENT',
        'Users.ROLE_SUBREGION_SUPERINTENDENT',
        'Users.ROLE_GROUP_SUPERINTENDENT',
        'Users.ROLE_INSTITUTION_SUPERINTENDENT',
        'Users.ROLE_INSTITUTION_ADMIN',
        'Users.ROLE_INSTITUTION_DATA_ENTRY',
        'Users.ListOfUsers',
        'Users.Email',
        'Users.userName',
        'Users.Role',
        'Users.Actions',
        'Users.PaginationLimit',
        'Users.PaginationRage',
        'Users.PaginationOf',
        'Users.TableNextTooltip',
        'Users.TablePreviousTooltip',
        'Users.TableLastTooltip',
        'Users.TableFirstTooltip',
        'Users.TableShowHideColumn',
        'Users.TableSearch',
        'Users.ToasterUpdateUserSuccess',
        'Users.ToasterUpdateUserError',
        'Users.ToasterAddUserSuccess',
        'Users.ToasterAddUserError',
        'Users.ToasterDeleteUserSuccess',
        'Users.ToasterDeleteUserError',
        "Users.DELETETITLE",
        "Users.DELETETEXTE",
        "Users.DELETECONFIRM",
        "Users.DELETECANCEL",
        "Users.ROLR_DATA_VALIDATION"
    ];
    public static institution = [
        'Institution.ToasterUpdateUserSuccess',
        'Institution.ToasterUpdateUserError',
        'Institution.ToasterDeleteUserSuccess',
        'Institution.ToasterDeleteUserError',
        "Institution.DELETETITLE",
        "Institution.DELETETEXTE",
        "Institution.DELETECONFIRM",
        "Institution.DELETECANCEL",
    ]

    public static StudyPrograms = [
        "StudyProgram.StudyPrograms",
        "StudyProgram.TrainingPrograms",
    ]


    public static policyGoals = [
        'PolicyGoals.ToasterUpdateUserSuccess',
        'PolicyGoals.ToasterUpdateUserError',
        'PolicyGoals.ToasterDeleteUserSuccess',
        'PolicyGoals.ToasterDeleteUserError',
        'PolicyGoals.SumPerformance',
        'PolicyGoals.SumPerformanceIndicator'
    ]

    public static etl = [
        'Etl.ToasterUpdateUserSuccess',
        'Etl.ToasterUpdateUserError',
        'Etl.ToasterDeleteUserSuccess',
        'Etl.ToasterDeleteUserError',
    ]


    public static menuUser = [
        'MenuUser.ToasterUpdateUserSuccess',
        'MenuUser.ToasterUpdateUserError',
        'MenuUser.ToasterDeleteUserSuccess',
        'MenuUser.ToasterDeleteUserError',
        "MenuUser.DELETETITLE",
        "MenuUser.DELETETEXTE",
        "MenuUser.DELETECONFIRM",
        "MenuUser.DELETECANCEL",
        "MenuUser.EXPORTTEAMSINPROGRESSBODY"
    ]


    public static upload = [
        'Upload.successfulUpload',
        'Upload.failedUpload'
    ]

    public static population = [
        'Population.ToasterUpdateUserSuccess',
        'Population.ToasterUpdateUserError',
        'Population.ToasterDeleteUserSuccess',
        'Population.ToasterDeleteUserError',
        "Population.DELETETITLE",
        "Population.DELETETEXTE",
        "Population.DELETECONFIRM",
        "Population.DELETECANCEL",
    ]

    public static literacy = [
        'Literacy.ToasterUpdateUserSuccess',
        'Literacy.ToasterUpdateUserError',
        'Literacy.ToasterDeleteUserSuccess',
        'Literacy.ToasterDeleteUserError',
        "Literacy.DELETETITLE",
        "Literacy.DELETETEXTE",
        "Literacy.DELETECONFIRM",
        "Literacy.DELETECANCEL",
    ]

    public static schoolPopulation = [
        'SchoolPopulation.ToasterUpdateUserSuccess',
        'SchoolPopulation.ToasterUpdateUserError',
        'SchoolPopulation.ToasterDeleteUserSuccess',
        'SchoolPopulation.ToasterDeleteUserError',
        "SchoolPopulation.DELETETITLE",
        "SchoolPopulation.DELETETEXTE",
        "SchoolPopulation.DELETECONFIRM",
        "SchoolPopulation.DELETECANCEL",
    ]

    public static roleTwoFactor = [
        'RoleTwoFactor.ToasterUpdateUserSuccess',
        'RoleTwoFactor.ToasterUpdateUserError',
    ]


}