<div class="auth-wrapper">
    <div class="auth-content subscribe">
        <div class="card">
            <div class="row no-gutters">
                <div class="col-md-4 col-lg-6 d-none d-md-flex d-lg-flex theme-bg align-items-center justify-content-center">
                    <img src="../../../../../assets/icon/lock.svg" alt="lock images" class="img-fluid">
                </div>
                <div class="col-md-8 col-lg-6">
                    <div class="card-body text-center">
                        <div class="row justify-content-center">
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="col-sm-12">
                                    <h3 class="mb-4"> {{ 'Login.login' | translate }}</h3>
                                    <div class="input-group mb-3">
                                        <span class="input-icon">
                                            <i aria-hidden="true" class="fas fa-user-alt"></i>
                                        </span>
                                        <input  type="text" class="form-control username-input" [ngClass]="{ 'is-invalid': submitted && form.username.errors }" formControlName="username" placeholder="{{userNamePH}}">
                                    </div>
                                    <div *ngIf="submitted && form.username.errors" class="form-errors text-danger">
                                        <small>{{ 'Login.UsernameIsRequired' | translate }}</small>
                                    </div>
                                    <div class="input-group mb-4">
                                        <span class="input-icon">
                                            <i aria-hidden="true" class="fas fa-unlock-alt"></i>
                                        </span>
                                        <input type="password" class="form-control password-input" [ngClass]="{ 'is-invalid': submitted && form.password.errors }" formControlName="password" placeholder="{{passwordPH}}">
                                    </div>
                                    <div *ngIf="submitted && form.password.errors" class="form-errors text-danger">
                                        <small>{{ 'Login.PasswordIsRequired' | translate }}</small>
                                    </div>
                                   
                                    <div class="form-group">
                                        <button type="submit" [disabled]="loading" class="btn btn-primary shadow-2 mb-4 btn-block">{{ 'Login.login' | translate }}</button>        
                                        <!-- <img *ngIf="loading" class="pl-2 spinner" src="../../../../../assets/images/spinner.svg" /> -->
                                        <p class="mb-0 text-muted"> 
                                            <!-- <a [routerLink]="['/auth/emailconfirmation']">Activate Account</a> -->
                                            <button type="button" [routerLink]="['/auth/emailconfirmation']" class="btn btn-secondary  btn-block">{{ 'LoginInterface.ActivateAccount' | translate }}</button>
                                        </p>
                                      <!--   <p class="mb-0 text-muted"> <a [routerLink]="['/auth/resetpassword']">Reset Password</a></p>-->

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 