import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {DataService} from './services/data.service';
import { AppUtilities } from './common/app.utilities';
import { AppSettings } from './common/app.settings';
import { ToasterConfig } from 'angular2-toaster';
import { AESEncryptDecryptService } from './services/security/aesencrypt-decrypt.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'edligo_analytics';

  // Toaster configuration
  public toasterconfig: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: true,
    timeout: 5000,
  });

  constructor(private router: Router, private translate: TranslateService, private dataService:DataService,
    private aesEncryptDecryptService: AESEncryptDecryptService) {

    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.languageKey))!= null) {
      this.translate.setDefaultLang(this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.languageKey)));
      let defaultLang = this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.languageKey));
      this.dataService.changeCurrentLang(defaultLang);
    } else {
      localStorage.setItem(AppSettings.languageKey, this.aesEncryptDecryptService.encrypt(AppSettings.englishLang));
      this.translate.setDefaultLang(AppSettings.englishLang);
      this.dataService.changeCurrentLang(AppSettings.englishLang);
    }
  }


  ngOnInit() {
    const token = AppUtilities.getDecodedAccessToken();
    this.dataService.changeAcessToken(token);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

}
