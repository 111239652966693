import { environment } from '../../environments/environment';

export class AppSettings {

    // Routes
    public static loginRoute = "/auth/login";

    //Language value
    public static englishLang = "en";
    public static amhariLang = "am";

    //LocalStorage Keys
    public static languageKey = "language";
    public static credentialsKey = "credentials";
    public static authDataKey = "authData";
    public static institutionKey = "institution";
    public static regionKey = "region";
    public static zoneKey = "zone";
    public static roleKey = "role";
    public static woredaKey = "woreda";
    public static regionForWoredaOrZoneKey = "regionForWoredaOrZone";
    public static roleByUsernameKey = "roleByUsername";
    public static customizationKey = "customization";
    public static academicYearPeriodForValidation = "academicYearPeriodForValidation"
    
    //ng select
    public static ngSelectId="ngSelectId";

    public static isTvet = "TVET"

    //Template
    public static dataSpecifications = "DataSpecifications.xlsx";

    public static mosheInstitutionTypes = [
        {
            institutionType: "University",
            institutionTypeCode: "UNIV"
        },
        {
            institutionType: "University College",
            institutionTypeCode: "UCOL"
        },
        {
            institutionType: "College",
            institutionTypeCode: "HCOL"
        },
        {
            institutionType: "Institute",
            institutionTypeCode: "HINST"
        },
        // {
        //     institutionType: "School",
        //     institutionTypeCode: "SCHL"
        // },
    ]

    public static mosheInstitutionTypesForInstitutions = [
        {
            institutionType: "College",
            institutionTypeCode: "HCOL"
        },
        {
            institutionType: "Institute",
            institutionTypeCode: "HINST"
        },
        {
            institutionType: "School",
            institutionTypeCode: "SCHL"
        },
    ]
}
