import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConnectionConfirmationComponent } from './connection-confirmation/connection-confirmation.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { LoginComponent } from './login/login.component';
//import {  ExpiredPasswordComponent } from './expired-password/expired-password.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'loginconfirmation',
        component: ConnectionConfirmationComponent,
      },
      {
        path: 'emailconfirmation',
        component: EmailConfirmationComponent,
      },
      {
        path: 'resetpassword',
        component: ResetPasswordComponent,
      },
      {
        path: 'unauthorizedPage',
        component: UnauthorizedPageComponent,
      },
/*       {
        path: 'expiredpassword',
        component: ExpiredPasswordComponent,
      } */
    ] 
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
