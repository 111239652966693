<app-navigation class="pcoded-navbar" [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.dattaConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}" (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavCollapsedMob)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light" (onNavCollapsedMob)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container mt-3">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <!-- Commented by Amir Zghidi -->
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
        <div *ngIf="!disableFooter" class="footerStyle">
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Commented by Chiheb BEN AMMAR -->
<!-- <app-configuration></app-configuration> -->
