let categ = 'HED';
// let categ = 'TVET';

// const baseUrl = 'http://20.52.55.75';
const baseUrl = 'http://196.189.149.121';

let backendBaseUrl = `${baseUrl}:5000`; //`http://hed-backend.edligo.org`; //'https://demo.edligo.net'; 
let reportdBaseUrl = `${baseUrl}:5300`;
let rlsReportBaseUrl = 'http://196.189.149.123:5302'; //'http://130.61.117.188:5302'; //'https://demo-v2.edligo.net'; //`${baseUrl}:5302`;
if (categ == 'TVET') {
  backendBaseUrl = `${baseUrl}:7000`;
}

export const environment = {
  production: true,
  categ: categ,

  apiPowerbiUrl: backendBaseUrl,
  groupId: '2bc97832-3892-4b27-9bc0-595102a24c2d',  //New Group Id

  //Secret key for encryption & descryption
  SecretKeyForEncryptionDescryption: "7'6;+HA>np,SqGPG8zUF*F,cS><c/,^,dQv?~",

  embedInfoUrl: `${backendBaseUrl}/embedinfo/GetEmbedInfo`, //Ok
  getInfoByReportIdUrl: `${backendBaseUrl}/embedinfo/GetInfoByReportId`, //Ok
  //embedInfoByIdsUrl:`${backendBaseUrl}/embedinfo/GetEmbedInfoByIds`, //Ok
  embedInfoByIdsUrl: `${reportdBaseUrl}/getEmbedTokenByIds`, //Ok
  //rowLevelSecurityEmbedInfoByIdsUrl:`${backendBaseUrl}/embedinfo/GetRowLevelSecurityEmbedInfoByIds`, //Ok
  rowLevelSecurityEmbedInfoByIdsUrl: `${rlsReportBaseUrl}/getEmbedTokenWithLRS`, //Ok

  getLanguages: `${backendBaseUrl}/api/Data/getLanguages`,
  resetPassword: `${backendBaseUrl}/api/users/ResetPassword`, //Ok
  twoFactorAuthenticationVerification: `${backendBaseUrl}/api/users/TwoFactorAuthenticationVerification`, //Ok
  confirmAccounts: `${backendBaseUrl}/api/users/confirmYourEmail`, //Ok
  signInUrl: `${backendBaseUrl}/api/users/signin`, //Ok
  getUserMenusUrl: `${backendBaseUrl}/api/Menu/GetUserMenus`, //Ok
  menuUrl: `${backendBaseUrl}/api/Menu/GetMenus`, //Ok
  menuDashboardUrl: `${backendBaseUrl}/api/Menu/GetDashboardMenus`, //Ok
  menuRyRoleNameUrl: `${backendBaseUrl}/api/Menu/GetMenusByRoleName`,  //Ok
  menuByInstitutionId: `${backendBaseUrl}/api/Menu/GetUserMenusByInstitution`,  //Ok
  getMainMenusByRoleUrl: `${backendBaseUrl}/api/Menu/getMainMenusByRole`, //Ok

  addUserUrl: `${backendBaseUrl}/api/Users/Signup`, //Ok
  deleteUserUrl: `${backendBaseUrl}/api/Users/Delete`, //Ok

  getManagedAccouts: `${backendBaseUrl}/api/users/GetManagedAccouts`, //Ok
  getTvetManagedAccouts: `${backendBaseUrl}/api/users/GetTvetManagedAccouts`, //Ok

  menus: {
    addMenus: `${backendBaseUrl}/api/Menu/PostMenuRole`,
    removeMenus: `${backendBaseUrl}/api/Menu/DeleteMenuRole?menuRoleId`,

  },

  getInstitutionChildren: `${backendBaseUrl}/api/Institution/getInstitutionChildren`,
  getAllParentInstitution: `${backendBaseUrl}/api/Institution/getAllParentInstitution`,
  getFilesByInstitutionIds: `${backendBaseUrl}/file/getFilesByInstitutionIds`,
  getDeleteFilePublicUrl: `${backendBaseUrl}/Deletion/DeleteFilePublic`,

  getAuditsOutcomes: `${backendBaseUrl}/api/Data/GetAuditOutcome`,
  resetPasswordByEmail: `${backendBaseUrl}/api/Users/ResetPasswordByEmail`,
  calculateKPI: `${backendBaseUrl}/api/Kpi/CalculateKpis`,

  getPublicMainInstitutionsByYear: `${backendBaseUrl}/api/Institution/GetPublicMainInstitutionsByYear`,
  getPrivateMainInstitutionsByYear: `${backendBaseUrl}/api/Institution/GetPrivateMainInstitutionsByYear`,

  getBatchCategories: `${backendBaseUrl}/File/GetBatchCategories`,


  getProcessedFileStats: `${backendBaseUrl}/api/Institution/GetProcessedFileStats`,

  getAggregationFileUploadedByInstitution: `${backendBaseUrl}/api/Institution/getAggregationFileUploadedByInstitution`,
  getAggregationFileUploadedByCollege: `${backendBaseUrl}/api/Institution/getAggregationFileUploadedByCollege`,


  GetAggregatedSubmittedFilesByInstitution: `${backendBaseUrl}/api/Institution/GetAggregatedSubmittedFilesByInstitution`,
  GetAggregatedValidatedFilesByInstitution: `${backendBaseUrl}/api/Institution/GetAggregatedValidatedFilesByInstitution`,


  GetAggregatedSubmittedFilesByCollege: `${backendBaseUrl}/api/Institution/GetAggregatedSubmittedFilesByCollege`,
  GetAggregatedValidatedFilesByCollege: `${backendBaseUrl}/api/Institution/GetAggregatedValidatedFilesByCollege`,

  getDetailedSubmittedFileByInstitution: `${backendBaseUrl}/api/Institution/GetDetailedSubmittedFileByInstitution`,
  getDetailedValidatedFileByInstitution: `${backendBaseUrl}/api/Institution/GetDetailedValidatedFileByInstitution`,

  getDetailedSubmittedFileByCollege: `${backendBaseUrl}/api/Institution/GetDetailedSubmittedFileByCollege`,
  getDetailedValidatedFileByCollege: `${backendBaseUrl}/api/Institution/GetDetailedValidatedFileByCollege`,

  //http://localhost:5000/api/Institution//6/6/102

  GetValidatedFileStats: `${backendBaseUrl}/api/Institution/GetValidatedFileStats`,

  getFinancialDataByYear: `${backendBaseUrl}/api/Finance/GetFinancialDataByYear`,
  addFinancialData: `${backendBaseUrl}/api/Finance/AddFinancialData`,
  updateFinancialData: `${backendBaseUrl}/api/Finance/UpdateFinancialData`,
  deleteFinancialDataByYear: `${backendBaseUrl}/api/Finance/DeleteFinancialDataByYear`,

  getStepsUrl: `${backendBaseUrl}/File/GetUploadProgressByInstitution`, //Ok
  insertLog: `${backendBaseUrl}/HistoryAccess/InsertLog`, //Ok
  getFilesByInstitutionUrl: `${backendBaseUrl}/File/GetFilesByInstitution`,

  viewDashboardRole: `${backendBaseUrl}/api/Users/HasViewDashboardsRole`, //Ok
  getValidationProgress: `${backendBaseUrl}/File/GetValidationProgressByInstitution`, //Ok
  GetSubmissionProgressByInstitution: `${backendBaseUrl}/File/GetSubmissionProgressByInstitution`, //Ok
  GetUserUploadedFilesByInstitution: `${backendBaseUrl}/File/GetUserUploadedFilesByInstitution`, //Ok
  GetUserValidatedFilesByInstitution: `${backendBaseUrl}/File/GetUserValidatedFilesByInstitution`, //Ok

  downloadFile: `${backendBaseUrl}/File/DownloadFile`, //Ok
  downloadTemplateFileUrl: `${backendBaseUrl}/File/DownloadTemplateFile`, //Ok

  getMinistryFilesOverview: `${backendBaseUrl}/MinistryInstitutionOverview/GetFiles`,
  getMinistryFilesDetails: `${backendBaseUrl}/MinistryInstitutionDetails/GetFiles`,
  processOverviewFiles: `${backendBaseUrl}/MinistryInstitutionOverview/Process`,
  processDetailsFiles: `${backendBaseUrl}/MinistryInstitutionDetails/Process`,
  getMinistryFilesToValidateByBatch: `${backendBaseUrl}/File/GetMinistryFilesToValidateByBatch`,


  getMinistryFilesToDelete: `${backendBaseUrl}/File/getMinistryFilesToDelete`,

  admin: {
    getRoles: `${backendBaseUrl}/api/Data/GetAdminRoles`,
    pullDefaultTwoFactorAuth: `${backendBaseUrl}/api/Users/PatchDefaultTwoFactorAuth`,

  },
  addInstitution:
  {
    getCountries: `${backendBaseUrl}/api/Data/GetCountries`, //Ok
    getAllOwnership: `${backendBaseUrl}/api/Data/GetOwnershipTypes`, //Ok
    getAllInstitutionType: `${backendBaseUrl}/api/Data/GetAllInstitutionTypes`, //Ok
    getCategories: `${backendBaseUrl}/api/Data/GetCategories`, //Ok
    getBands: `${backendBaseUrl}/api/Data/GetLocalBands`, //Ok
    getInstitutionUrl: `${backendBaseUrl}/api/Institution/GetInstitutions`,  //OK
    getInstitutionIdUrl: `${backendBaseUrl}/api/Institution/GetInstitutionByIdYear`, //Ok
    getInstitutionWithIdUrl: `${backendBaseUrl}/api/Institution/GetInstitutionByIdYear`, //Ok
    getInstitutionWithUserIdUrl: `${backendBaseUrl}/api/Institution/GetPrimaryInstitutionDetailsByUserId`, //Ok
    getInstitutionWithUserNameUrl: `${backendBaseUrl}/api/Institution/GetInstitutionsByUserName`, //Ok
    getPrimaryInstitutionsByUserIdUrl: `${backendBaseUrl}/api/Institution/GetPrimaryInstitutionsByUserId`, //Ok,
    getPrimaryInstitutionsUrl: `${backendBaseUrl}/api/Institution/GetUsersPrimaryInstitution`, //Ok,
    getChildInstitutions: `${backendBaseUrl}/api/institution/getchildInstitutions`, //Ok
    GetChildInstitutionsFormatted: `${backendBaseUrl}/api/institution/GetInstitutionHierarchy`,
    getAccreditationBodies: `${backendBaseUrl}/api/Data/GetAccreditationBodies`, //Ok
    putInstitutionUrl: `${backendBaseUrl}/api/Institution/updateInstitutionOverview`, //Ok
    putInstitutionDetailsUrl: `${backendBaseUrl}/api/Institution/UpdateInstitutionDetails`, //
    postInstitutionUrl: `${backendBaseUrl}/api/Institution/PostInstitution`, //Ok
    deleteInstitutionUrl: `${backendBaseUrl}/api/Institution/DeleteInstitutionDetail`, //Ok
    getAcademicYears: `${backendBaseUrl}/api/Data/GetAcademicYears`, //Ok
    getCurrentAndPreviousAcademicYears: `${backendBaseUrl}/api/Data/GetCurrentAndPreviousAcademicYears`, //Ok
    getAllZones: `${backendBaseUrl}/api/Data/GetZones`, //Ok
    getAllRegion: `${backendBaseUrl}/api/Data/GetRegions`, //Ok
    getDistrictLevel1: `${backendBaseUrl}/api/Data/GetDistrictLevel1`, //Ok
    getDistrictLevel2: `${backendBaseUrl}/api/Data/GetDistrictLevel2`, //Ok
    getDistinctChildInstitutions: `${backendBaseUrl}/api/Institution/GetDistinctChildInstitutions`, //Ok
    getMainInstitutionsByYear: `${backendBaseUrl}/api/Institution/GetMainInstitutionsByYear`, //Ok
    getInstitutionsByYear: `${backendBaseUrl}/api/Institution/GetInstitutionsByYear`, //Ok
    getInstitutionByIdAndAcademicYear: `${backendBaseUrl}/api/Institution/GetInstitutionByIdYear`, //Ok
    getDistinctMainInstitutions: `${backendBaseUrl}/api/Institution/GetDistinctMainInstitutions`, //Ok
    getTvetInstitutionsByRegionYear: `${backendBaseUrl}/api/Institution/GetTvetInstitutionsByRegionYear`, //Ok
    getTvetInstitutionsByZoneYear: `${backendBaseUrl}/api/Institution/getTvetInstitutionsByZoneYear`, //Ok
    getTvetInstitutionsByDistrictLevel1Year: `${backendBaseUrl}/api/Institution/GetTvetInstitutionsByDistrictLevel1Year`, //Ok
    getAssessmentCentersByRegionYear: `${backendBaseUrl}/api/Institution/GetAssessmentCentersByRegionYear`, //Ok
    getInstitutionOverview: `${backendBaseUrl}/api/Institution/GetInstitutionOverview`, //Ok
    getInstitutionDetailsUrl: `${backendBaseUrl}/api/Institution/GetInstitutionDetails`, //Ok
    getCampus: `${backendBaseUrl}/campus/getCampus`, //No
    getHeiClasses: `${backendBaseUrl}/api/Data/GetHeiClasses`,
    getStandars: `${backendBaseUrl}/api/Data/GetStandars`,
    getUserInstitutions: `${backendBaseUrl}/api/institution/getUserInstitutions`,
    getInstitutionsDetailsByUserId: `${backendBaseUrl}/api/Institution/GetInstitutionsDetailsByUserId`,
    getInstitutionById: `${backendBaseUrl}/api/Institution/getInstitutionById`,
    getDistinctPublicMainInstitutions: `${backendBaseUrl}/api/Institution/GetDistinctPublicMainInstitutions`,
    getDistinctPrivateMainInstitutions: `${backendBaseUrl}/api/Institution/GetDistinctPrivateMainInstitutions`,
    getInstitutionNameByInstitutionId: `${backendBaseUrl}/api/Institution/GetInstitutionNameByInstitutionId`,
    getProcessedFileStats: `${backendBaseUrl}/api/Institution/GetProcessedFileStats`,
  },

  getAllIscedBands: `${backendBaseUrl}/api/RefData/getAllIscedBands`,
  getIscedBands: `${backendBaseUrl}/api/RefData/IscedBands/en`,
  getTrainingStudyFields: `${backendBaseUrl}/api/RefData/TrainingStudyFields`,
  getTvetSectors: `${backendBaseUrl}/api/RefData/TvetSectors`,
  getIsicIndustrySectors: `${backendBaseUrl}/api/RefData/IsicIndustrySectors`,
  getLocalBands: `${backendBaseUrl}/api/RefData/LocalBands`,
  getCategories: `${backendBaseUrl}/api/RefData/Categories`,
  getStudyPrograms: `${backendBaseUrl}/api/RefData/StudyPrograms`,
  addStudyProgram: `${backendBaseUrl}/api/Data/AddStudyProgram`,
  deleteRefPrograms: `${backendBaseUrl}/api/RefData/deleteRefPrograms`,
  updateStudyProgram: `${backendBaseUrl}/api/Data/UpdateStudyProgram`,


  getUserRegions: `${backendBaseUrl}/api/Users/GetUserRegions`,
  getUserZones: `${backendBaseUrl}/api/Users/GetUserZones`,
  getUserWoredas: `${backendBaseUrl}/api/users/GetUserWoredas`,

  getDistrictLevel1ByZone: `${backendBaseUrl}/api/data/GetDistrictLevel1ByZone`,
  getTvetInstitutionsByDistrictLevel1Year: `${backendBaseUrl}/api/Institution/GetTvetInstitutionsByDistrictLevel1Year`, //Ok

  getZonesUrl: `${backendBaseUrl}/api/data/getzones`, //Ok
  getZonesByRegion: `${backendBaseUrl}/api/Data/GetZonesByRegion`,
  getRegionsUrl: `${backendBaseUrl}/api/data/getregions`, //Ok
  getAllStudents: `${backendBaseUrl}/api/data/GetStudents`, //Ok
  getAcademicYearsUrl: `${backendBaseUrl}/api/Data/GetAcademicYears`, //Ok
  getDefaultAcademicYear: `${backendBaseUrl}/api/Data/GetDefaultAcademicYear`, //Ok
  getAcademicPeriodsUrl: `${backendBaseUrl}/api/Data/GetAcademicPeriods`, //Ok
  getAllRolesUrl: `${backendBaseUrl}/api/Data/GetRoles`, //Ok
  getUsersUrl: `${backendBaseUrl}/api/Data/GetUsers`, //Ok
  updateUserUrl: `${backendBaseUrl}/api/users/UpdateUser`,
  getUsersRolesUrl: `${backendBaseUrl}/api/Data/GetUsersRoles`, //Ok
  getRoleByUsernameUrl: `${backendBaseUrl}/api/Data/GetRoleByUsername`, //Ok
  getAllInstitutionsUrl: `${backendBaseUrl}/api/Institution/GetInstitutions`, //Ok
  GetAllInstitutionTypes: `${backendBaseUrl}/api/Data/GetAllInstitutionTypes`, //Ok
  //getPostInstitutionUrl:`${backendBaseUrl}/api/Institution/PostInstitution`,
  deleteFileByIdUrl: `${backendBaseUrl}/File/DeleteFileById`,
  getProcessedFilesStatsUrl: `${backendBaseUrl}/Statistics/getProcessedFilesStats`,
  submitFileForValidationUrl: `${backendBaseUrl}/File/SubmitFileForValidation`,
  getFilesToVlidateByBatchInstitutionUrl: `${backendBaseUrl}/File/GetFilesToValidateByBatchInstitution`,
  returnFileForReviewUrl: `${backendBaseUrl}/File/ReturnFileForReview`,
  validateFileUrl: `${backendBaseUrl}/File/ValidateFile`,
  getFileCategoriesUrl: `${backendBaseUrl}/api/Users/GetFileCategories`,
  getDataAccessLevelsByUserIdUrl: `${backendBaseUrl}/api/Users/GetDataAccessLevelsByUserId`,
  updateUserAndAccessLevels: `${backendBaseUrl}/api/Users/UpdateUserAndAccessLevels`,
  GetFilesToValidateByInstitution: `${backendBaseUrl}/file/GetFilesToValidateByInstitution`,
  updateUser: `${backendBaseUrl}/api/Users/UpdateUser`,
  checkOldPassword: `${backendBaseUrl}/api/users/CheckOldPassword`,
  // getUploadValidateRoles: `${backendBaseUrl}/api/users/GetUploadValidateRoles?institutionId`,
  getUploadValidateRolesByInstitutionUrl: `${backendBaseUrl}/api/users/GetUploadValidateRolesByInstitution`,
  getAuthGuardUploadUrl: `${backendBaseUrl}/api/users/IsUploadAuthorized`,
  getAuthGuardValidationUrl: `${backendBaseUrl}/api/users/IsValidationAuthorized`,
  getInstitutionLogo: `${backendBaseUrl}/InstitutionOverview/getInstitutionLogo`,
  postInstitutionLogo: `${backendBaseUrl}/api/Institution/setInstitutionLogo`,
  getInstitutionIdLogo: `${backendBaseUrl}/api/Institution/getInstitutionLogo`,

  DownloadFileStats: `${backendBaseUrl}/api/Institution/DownloadFileStats`,

  deleteRecordsByIdAndJobExecutionUrl: `${backendBaseUrl}/StudentOverview/deleteRecordsByIdAndJobExecution`,



  getAreaTypesUrl: `${backendBaseUrl}/api/Data/GetAreaTypes`,
  getGendersUrl: `${backendBaseUrl}/api/Data/GetGenders`,
  updateStudentOverviewUrl: `${backendBaseUrl}/StudentOverview/UpdateStudentOverview`,
  validateStudentDataUrl: `${backendBaseUrl}/StudentOverview/ValidateData`,

  getTvetInstitutionsAssessmentCenterByRegion: `${backendBaseUrl}/api/Institution/GetTvetInstitutionsAssessmentCenterByRegion`,

  getCampusesByInstitutionYear: `${backendBaseUrl}/api/Institution/GetCampusesByInstitutionYear`,
  population:
  {
    addPopulationUrl: `${backendBaseUrl}/Population/AddPopulation`,
    deletePopulationUrl: `${backendBaseUrl}/Population/DeletePopulationByYear`,
    getPopulationViewByYearUrl: `${backendBaseUrl}/Population/GetPopulationByYear`,
    putPopulation: `${backendBaseUrl}/Population/UpdatePopulation`,
    getPopulationByYearIdAndPopulationGroupIdUrl: `${backendBaseUrl}/Population/GetPopulationByYearIdAndPopulationGroupId`,
    getPopulationGroupUrl: `${backendBaseUrl}/Population/GetGroupPopulation`,
    getTotalFemalePopulationByAcademicYearUrl: `${backendBaseUrl}/Population/GetTotalFemalePopulationByAcademicYear`,
    getTotalMalePopulationByAcademicYearUrl: `${backendBaseUrl}/Population/GetTotalMalePopulationByAcademicYear`,

  },
  literacy:
  {
    addLiteracyUrl: `${backendBaseUrl}/Literacy/AddLiteracy`,
    deleteLiteracyByYearUrl: `${backendBaseUrl}/Literacy/DeleteLiteracyByYearByGroup`,
    getLiteracyByYearUrl: `${backendBaseUrl}/Literacy/GetLiteracyByYear`,
    getLiteracyRecordUrl: `${backendBaseUrl}/Literacy/GetLiteracyRecord`,
    updateLiteracyUrl: `${backendBaseUrl}/Literacy/UpdateLiteracy`,
    getLiteracyGroupUrl: `${backendBaseUrl}/Literacy/GetLiteracyGroup`,

  },
  schoolPopulation:
  {
    addSchoolPopulationUrl: `${backendBaseUrl}/SchoolPopulation/AddSchoolPopulation`,
    deleteSchoolPopulationUrl: `${backendBaseUrl}/SchoolPopulation/DeleteSchoolPopulationByYearByGroup`,
    getSchoolPopulationByYearUrl: `${backendBaseUrl}/SchoolPopulation/GetSchoolPopulationByYear`,
    getSchoolPopulationRecordUrl: `${backendBaseUrl}/SchoolPopulation/GetSchoolPopulationRecord`,
    updateSchoolPopulationUrl: `${backendBaseUrl}/SchoolPopulation/UpdateSchoolPopulation`,
    getSchoolGroupUrl: `${backendBaseUrl}/SchoolPopulation/GetSchoolGroup`,

  },
  kpi: {
    getPolicyObjectivesByStakeholdeAndClassUrl: `${backendBaseUrl}/api/Kpi/GetPolicyObjectivesByStakeholdeAndClass`,
    getStakeholdersUrl: `${backendBaseUrl}/api/Kpi/GetStakeholders`,
    getPolicyObjectivesUrl: `${backendBaseUrl}/api/Kpi/GetPolicyObjectives`,
    getPerformanceIndicatorsByPolicyUrl: `${backendBaseUrl}/api/Kpi/GetPerformanceIndicatorsByPolicyAndClass`,
    UpdatePolicyObjectivesListUrl: `${backendBaseUrl}/api/Kpi/UpdatePolicyObjectivesList`,
    UpdatePolicyObjectiveUrl: `${backendBaseUrl}/api/Kpi/UpdatePerformanceIndicators`,
  },


  getApplicants: `${backendBaseUrl}/applicant/getApplicants`,
  // getStudentsList: `${backendBaseUrl}/api/Data/GetStudentsList`,
  getStudentsList: `${backendBaseUrl}/StudentOverview/GetStagingLazyLoading`,
  getInstitutionList: `${backendBaseUrl}/api/Data/GetInstitutionsList`,
  // getStaffList: `${backendBaseUrl}/api/Data/GetStaffList`,
  getStaffList: `${backendBaseUrl}/StaffAttrition/GetValidLazyLoading`,

  // http://localhost:7000/StudentOverview/GetStagingLazyLoading
}
