
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadLogoService {

  constructor(private http: HttpClient) { }

  upload( file: File ):Promise<any>{
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.http.post(`${environment}`, formData).toPromise()
    .then(response => response)
    .catch((error: any) => console.log(error));
  }

  getFiles():Promise<any> {
    return this.http.get<any>(`${environment}/files`).toPromise()
    .then(response => response as any);
  };

  

  getInstitutionId(institutionId): Promise<any> {
    return this.http.get(`${environment.getInstitutionIdLogo}/${institutionId}`).toPromise().then(response => {
      return response
    });
  }

      

}
