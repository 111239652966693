export class AppRoles {
    public static HED = {
        'SYSTEM_ADMIN' : 100,
        'MINISTRY_SUPERINTENDENT' : 109,
        'MINISTRY_ADMIN' : 110,
        'HEI_SUPERINTENDENT' : 119,
        'HEI_ADMIN' : 120,
        'VP_ACADEMIC' : 130,
        'VP_ADMINISTRATION' : 131,
        'VP_DEVELOPMENT' : 132,
        'COLLEGE_SUPERINTENDENT' : 139,
        'COLLEGE_ADMIN' : 140,
        'CAMPUS_ADMIN' : 141,
        'DEPARTMENT_SUPERINTENDENT' : 150,
        'DEPARTMENT_ADMIN' : 151,
        'ETA_ADMIN' : 310,
        'PHEI_ADMIN' : 320,
        'PHEI_DEPARTMENT_ADMINN' : 330, // Has been removed from the database
        'DATA_UPLOAD' : 800,
        'DATA_VALIDATION' : 810,
        'ROLE_MINISTRY_SUPERINTENDENT' : 910,
        'GENERAL_staging' : 920,
        'ETA_SUPERINTENDENT' : 309,
        'CAMPUS_SUPERINTENDENT' : 142,
        'VP_DEVELOPMENT_SUPERINTENDENT': 137,
        'VP_ACADEMIC_SUPERINTENDENT': 135,
        'VP_ADMINISTRATION_SUPERINTENDENT': 136
    }

    public static TVET = {
        'SYSTEM_ADMIN' : 100,
        'FTA_SUPERINTENDENT' : 209,
        'RTA_SUPERINTENDENT' : 219,
        'ZONE_SUPERINTENDENT' : 229,
        'DISTRICT_SUPERINTENDENT' : 239,
        'TVET_SUPERINTENDENT' : 249,
        'DATA_UPLOAD' : 800,
        'DATA_VALIDATION' : 810,
        'CAMPUS_SUPERINTENDENT' : 142,
        'ROCAA_SUPERINTENDENT' : 253,
        'ASSESSMENT_CENTER_SUPERINTENDENT' : 261,
        'MINISTRY_SUPERINTENDENT' : 199,
        'CAMPUS_ADMIN' : 141,
        'MINISTRY_ADMIN' : 200,
        'FTA_ADMIN' : 210,
        'RTA_ADMIN' : 220,
        'ZONE_ADMIN' : 230,
        'DISTRICT_ADMIN' : 240,
        'TVET_ADMIN' : 250,
        'ASSESSMENT_CENTER_ADMIN' : 251,
        'ROCAA_ADMIN' : 252
    };
}
