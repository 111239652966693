import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NgxSpinnerService} from "ngx-spinner";

@Injectable()
export class LoaderService {
  public status: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (false);

  constructor(private ngxSpinnerService: NgxSpinnerService) {}

  // display(value: boolean) {
  //   this.status.next(value);
  //   if (value === true) {
  //     this.ngxSpinnerService.show();
  //   } else {
  //     this.ngxSpinnerService.hide();
  //   }
  // }

  show(){
    this.ngxSpinnerService.show();
  }

  hide(){
    this.ngxSpinnerService.hide();
  }
}
