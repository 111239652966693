import { AuthenticationService } from './../../../services/authentication/authentication.service';
import { AESEncryptDecryptService } from './../../../services/security/aesencrypt-decrypt.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from './../../../services/data.service';
import { AppUtilities } from './../../../common/app.utilities';
import { AppSettings } from './../../../common/app.settings';
import { AppKeys } from './../../../common/app.key';
import { TranslateService } from '@ngx-translate/core';
import { WelcomeServicesService } from './../../../services/welcome-services.service'; 
import { AccountsService } from 'src/app/services/authentication/accounts.service';
import { UserService } from 'src/app/services/user.service';
 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  error = '';
  submitted: boolean = false;
  userNamePH: string;
  passwordPH: string;

  userInfo :any;


  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private translateService: TranslateService,
    private accountsService: AccountsService, 
    private aesEncryptDecryptService: AESEncryptDecryptService,
    private welcomeService: WelcomeServicesService,
    private userService:UserService,
    private translate: TranslateService) {

  }

  ngOnInit(): void {

    this.getAllTranslations().then(res => {
    });

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      SaveCredentials: [true]
    })

    //if (AppUtilities.isUserAuthenticated()) {
   //   this.router.navigate(["/welcome"]);
   // }

    if (this.aesEncryptDecryptService.decrypt(localStorage.getItem('credentials'))) {
      let savedCredentials = this.aesEncryptDecryptService.decrypt(localStorage.getItem('credentials'));
      savedCredentials = JSON.parse(savedCredentials);
      this.loginForm.get("username").setValue(savedCredentials.username);
      this.loginForm.get("password").setValue(savedCredentials.password);
    }
  }

  get form() {
    return this.loginForm.controls;
  }

  getAllTranslations(): Promise<any> {
    const keys = AppKeys.login;
    return this.translateService.get(keys).toPromise().then(
      results => {
        this.userNamePH = results[keys[0]];
        this.passwordPH = results[keys[1]];
      }
    );
  }

  login() {
    this.loading = true;
    let form = this.loginForm;
    let credentials = form.value;

    localStorage.removeItem(AppSettings.zoneKey);
    localStorage.removeItem(AppSettings.regionKey);
    localStorage.removeItem(AppSettings.institutionKey);
    localStorage.removeItem(AppSettings.woredaKey);
    localStorage.removeItem(AppSettings.regionForWoredaOrZoneKey);
    localStorage.removeItem(AppSettings.roleKey);

    this.authenticationService.getAuthData(credentials).then(data => {
       if (data) {
         localStorage.setItem(AppSettings.authDataKey, this.aesEncryptDecryptService.encrypt(JSON.stringify(data)));
        const decodedtoken = AppUtilities.getDecodedAccessToken();
        // We should only have one role per user
        const userRole = decodedtoken.roleName;
  
        this.dataService.changeAcessToken(decodedtoken);
 
        const token = AppUtilities.getDecodedAccessToken();
        
        this.accountsService.getRoleByUsername(token.username).then(res => {
          this.userService.getLanguages().then(data => {
            let lanCode = data.filter(d => d.languageId == res.preferredLanguageId)[0]
            !lanCode ? lanCode='en' : lanCode=lanCode.languageCode.toString()
            localStorage.setItem(AppSettings.languageKey, this.aesEncryptDecryptService.encrypt(lanCode.toString()))
            this.translate.setDefaultLang(lanCode.toString());
            this.dataService.changeCurrentLang(lanCode.toString());
          })
          localStorage.setItem(AppSettings.roleByUsernameKey, this.aesEncryptDecryptService.encrypt(JSON.stringify(res)));
          this.dataService.changeRoleByUsername(res);
       });   
 
        if(token.twofactorenabled && token.twofactorenabled.toLowerCase() != "false" ){

          this.router.navigate(["/auth/loginconfirmation"]);

          }else{
            this.router.navigate(["/welcome"]);

          }
        this.welcomeService.insertLogMethod(window.location.pathname, 'Login with user ID:' + token.userId, '123')
          .then(res => {
          })
      }

      //throw 'waiting here'

      if (credentials.SaveCredentials) {
        localStorage.setItem(AppSettings.credentialsKey, this.aesEncryptDecryptService.encrypt(JSON.stringify(credentials)));
      } else {
        this.aesEncryptDecryptService.decrypt(localStorage.getItem(AppSettings.credentialsKey)) ? localStorage.removeItem(AppSettings.credentialsKey) : '';
      }
      this.loading = false;

      // console.log('is expired', isJwtExpired(encodedToken));/auth/loginconfirmation
      // if(! isJwtExpired(encodedToken)){
      // }
    })
      .catch((error) => {
        this.loading = false; 
      })
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.login();
  }
}
