import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AESEncryptDecryptService } from '../security/aesencrypt-decrypt.service';
import { AppSettings } from './../../common/app.settings';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  currentUserSubject: any;
  currentUser: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private aesEncryptDecryptService: AESEncryptDecryptService
  ) { }


  getAuthData(credentials: any): Promise<any> {
    return this.http.post(environment.signInUrl, credentials).toPromise()
      .then(response => response)
      .catch((error: any) => {return console.log(error)});
  }


  logout() {
    // remove user from local storage
    
    localStorage.removeItem(AppSettings.institutionKey)
    localStorage.removeItem(AppSettings.authDataKey); 
    let customization = JSON.parse(localStorage.getItem(AppSettings.customizationKey));
    localStorage.clear()
    localStorage.setItem(AppSettings.customizationKey, JSON.stringify(customization))
    setTimeout(function(){ location.reload(); }, 50); 
    this.router.navigateByUrl(AppSettings.loginRoute);
  }

   getConfirmAccounts(payload): Promise<any> {
    return this.http.post(`${environment.confirmAccounts}`, payload).toPromise().then(response => response as any);
  } 
 
  getTwoFactorAuthenticationVerification(username,code): Promise<any> {
    return this.http.get(`${environment.twoFactorAuthenticationVerification}/${username}/${code}`).toPromise().then(response => response as any);
  } 
 
  getExpiredPassword(email,OldPassword,NewPassword ): Promise<any> {
    

    return this.http.get(`${environment.resetPassword}/${email}/${OldPassword}/${NewPassword}`).toPromise().then(response => response as any);

  } 

} 
