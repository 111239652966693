import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
 * Current lang of data service
 */
  private currentLang: any = new ReplaySubject<any>(null);

  /**
   * Id report of data service
   */
  private idReport: any = new ReplaySubject<any>(null);
  /**
   * Acess Token of data service
   */
  private acessToken: any = new ReplaySubject<any>(null);

  /**
   * Menu List
   */
  private currentMenu: any = new ReplaySubject<any>(null);

  /**
   * Current Role
   */
  private currentRole: any = new ReplaySubject<any>(null);

  /**
   * List Uploaded Files
   */
  private filesList: any = new ReplaySubject<any>(null);

  /**
 * List Uploaded Files
 */
  private isVisible: any = new ReplaySubject<any>(null);

  private currentInstitution: any = new ReplaySubject<any>(null);

  private currentRegion: any = new ReplaySubject<any>(null);
  
  private currentZone: any = new ReplaySubject<any>(null);

  private currentWoreda: any = new ReplaySubject<any>(null);

  private roleByUsername: any = new ReplaySubject<any>(null);

  private academicYearPeriodForValidation: any = new ReplaySubject<any>(null);

  constructor() { }

  /**
    * Changes current lang
    * @param currentLang
    */
  public changeCurrentLang(currentLang: any) {
    this.currentLang.next(currentLang);
  }

  /**
   * Gets current lang
   * @returns current lang
   */
  public getCurrentLang(): Observable<any> {
    return this.currentLang;
  }

  /**
   * Changes id report
   * @param idReport
   */
  public changeIdReport(idReport: any) {
    this.idReport.next(idReport);
  }

  /**
   * Gets id report
   * @returns id report
   */
  public getIdReport(): Observable<any> {
    return this.idReport;
  }

  /**
   * Change acess token
   * @param acessToken acess token
   */
  public changeAcessToken(acessToken: any) {
    this.acessToken.next(acessToken)
  }

  /**
   * Gets acess token
   * @returns acess token
   */
  public getAcessToken(): Observable<any> {
    return this.acessToken;
  }


  /**
   * Changes current menu list
   * @param currentMenu
   */
  public changeMenuList(currentMenu: any) {
    this.currentMenu.next(currentMenu);
  }

  /**
   * Gets current menu
   * @returns current menu
   */
  public getMenuList(): Observable<any> {
    return this.currentMenu;
  }



  /**
  * Changes current menu list
  * @param currentRole
  */
  public changeUserRole(currentRole: any) {
    this.currentRole.next(currentRole);
  }

  /**
   * Gets current menu
   * @returns current menu
   */
  public getUserRole(): Observable<any> {
    return this.currentRole;
  }


  /**
  * Changes Files List
  * @param filesList
  */
  public changeFilesList(filesList: any) {
    this.filesList.next(filesList);
  }

  /**
   * Gets current lang
   * @returns Files List
   */
  public getFilesList(): Observable<any> {
    return this.filesList;
  }


  /**
   * Changes current institution
   * @param currentInstitution 
   */
  public changeCurrentInstitution(currentInstitution: any) {
    this.currentInstitution.next(currentInstitution);
  }

  /**
   * Gets current institution
   * @returns current institution 
   */
  public getCurrentInstitution(): Observable<any> {
    return this.currentInstitution;
  }

  /**
   * Changes current region
   * @param currentRegion
   */
   public changeCurrentRegion(currentRegion: any) {
    this.currentRegion.next(currentRegion);
  }

  /**
   * Gets current region
   * @returns current region
   */
  public getCurrentRegion(): Observable<any> {
    return this.currentRegion;
  }
 
   
  /**
   * Changes current region
   * @param currentRegion
   */
   public changeCurrentZone(currentZone: any) {
    this.currentZone.next(currentZone);
  }

  /**
   * Gets current region
   * @returns current region
   */
  public getCurrentZone(): Observable<any> {
    return this.currentZone;
  } 
   
  /**
   * Changes current region
   * @param currentRegion
   */
   public changeCurrentWoreda(currentWoreda: any) {
    this.currentWoreda.next(currentWoreda);
  }

  /**
   * Gets current region
   * @returns current region
   */
  public getCurrentWoreda(): Observable<any> {
    return this.currentWoreda;
  }

  
  /**
   * Changes role by username
   * @param roleByUsername 
   */
  public changeRoleByUsername(roleByUsername: any) {
    this.roleByUsername.next(roleByUsername);
  }
 
  /** 
   * Gets role by username
   * @returns role by username 
   */
  public getRoleByUsername(): Observable<any> {
    return this.roleByUsername;
  }


  
  /**
   * Changes role by username
   * @param roleByUsername 
   */
   public changeAcademicYearPeriodForValidation(academicYearPeriodForValidation: any) {
    this.academicYearPeriodForValidation.next(academicYearPeriodForValidation);
  }
 
  /** 
   * Gets role by username
   * @returns role by username 
   */
  public getAcademicYearPeriodForValidation(): Observable<any> {
    return this.academicYearPeriodForValidation;
  }
  
}
