<div class="auth-wrapper">
    <div class="auth-content">
      <div class="auth-bg">
        <span class="r"></span>
        <span class="r s"></span>
        <span class="r s"></span>
        <span class="r"></span>
      </div>
      <div class="card">
        <div class="card-body text-center">
            <form [formGroup]="accountInfo" (ngSubmit)="onSubmit()">
          <div class="mb-4">
            <i class="feather icon-mail auth-icon"></i>
          </div>
          <h3 class="mb-4">{{ 'LoginInterface.Email' | translate }}</h3>
          <div class="input-group mb-3">
            <input type="email" class="form-control"  formControlName="email"  placeholder="{{ 'LoginInterface.Email' | translate }}" required >
          </div>
          <button pButton pRipple icon="pi pi-check" label="Reset Password" class="p-button-text" ></button>
          <!-- <button class="btn btn-primary mb-4">Reset Password</button> -->
            </form>
        </div> 
      </div>
    </div>
  </div>
    