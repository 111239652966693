import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { AuthenticationService } from './../../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { Toast, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {

  accountInfo: FormGroup;
  strengthChange: any;
  data;
  submitted: boolean = false;

  disableSendButton: boolean = true;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toasterService: ToasterService,

    private formBuilder: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.data = {};

    this.accountInfo = this.formBuilder.group({
      email: ['', Validators.required],
      passwordSendInEmail: ['', Validators.required],
      newPasswordCreated: ['', Validators.required],
      confPassword: ['', Validators.required],
    })
 

  }

  checkButton() {  
    if (Object.keys(this.data).length == 4) {
      if (this.data.email.length > 0 && this.data.passwordSendInEmail.length > 0 && this.data.password.length > 0 && this.data.confPassword.length > 0) {
        this.disableSendButton = false;
      } else {
        this.disableSendButton = true;
      }
    } else {
      this.disableSendButton = true;
    }
  }

  strengthChanges(value) {
    this.strengthChange = value;
  }

  onSubmit() {
    console.log(this.data)

    this.submitted = true;
    if (this.strengthChange < 3) {
      this.callToaster('error', null, "Please enter a stronger password",10000);
    }
    else if ((this.data.email == null || this.data.email == undefined) || (this.data.passwordSendInEmail == null || this.data.passwordSendInEmail == undefined) || (this.data.password == null || this.data.password == undefined)) {
      this.callToaster('error', null, "All field is required",10000);

    } else if (this.data.password != this.data.confPassword) {
      this.callToaster('error', null, "New password and confirmation password do not match",10000)
    }
    else {
      let payload = {
          "email":  this.data.email,
          "passwordSendInEmail": this.data.passwordSendInEmail,
          "newPasswordCreated": this.data.password
      }
      this.authenticationService.getConfirmAccounts(payload).then(res => {
        this.submitted = false;
        this.router.navigate(["/auth/login"]);
      })
    }

  }

  callToaster(type, toasterTitle, toasterBody,timeout) {
    const toaster: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
    };
    this.toasterService.pop(toaster);
  }

}

