<ul class="navbar-nav">
  <li>
    <div class="language">
      <div class="form-row">
        <div class="dropdowns m-0 form-group col-md-6">
          <div *ngIf="selectedInstitution !=null" class="form-group col">
            <ng-select class="select-institution" [items]="institutionList" id='University' bindLabel="name" autofocus
              [clearable]="false" placeholder="Select Institution" bindValue="institutionId"
              [(ngModel)]="selectedInstitution" (change)="institutionChanged()">
            </ng-select>
          </div>
          <div *ngIf="selectedRegion !=null" class="form-group col">
            <ng-select class="select-institution" [items]="regionList" id='University' bindLabel="regionName" autofocus
              [clearable]="false" placeholder="Select Region" [(ngModel)]="selectedRegion"
              (change)="regionChanged()">
            </ng-select>
          </div>
          <div *ngIf="selectedZone !=null" class="form-group col">
            <ng-select class="select-institution" [items]="zoneList" id='University' bindLabel="zoneName" autofocus [clearable]="false"
              placeholder="Select Zone"  
              [multiple]="false" 
              groupBy="regionName"
              [selectableGroup]="false"
              [selectableGroupAsModel]="false"
              [closeOnSelect]="true" 
              
              [(ngModel)]="selectedZone"
              (change)="zoneChanged()">
            </ng-select>
          </div>
          <div *ngIf="selectedWoreda !=null" class="form-group col">
            <ng-select class="select-institution" [items]="woredaList" id='woreda' bindLabel="districtLevel1Name" [clearable]="false"
              placeholder="Select a woreda" 
              [multiple]="false" 
              groupBy="zoneName"
              [selectableGroup]="false"
              [selectableGroupAsModel]="false"
              [closeOnSelect]="true" 
              [(ngModel)]="selectedWoreda" (change)="woredaChanged()">>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li>
    <div class="language">
      <div class="form-row">
        <div class="form-group">
          <ng-select appearance="outline" [searchable]="false" [clearable]="false" labelForId="heroId"
            (change)="changeLanguage()" [(ngModel)]="selected">
            <ng-option value="en">
              <!-- <img src="../../../../../../assets/flags/en.svg" width="20px" height="20px" />   -->
              {{ 'Lang.English' | translate}}
            </ng-option>
            <ng-option value="am">
              <!-- <img src="../../../../../../assets/flags/am.svg" width="20px" height="20px" />   -->
              {{ 'Lang.Amhari' | translate}}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </li>
  <!--   <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i class="icon feather icon-bell"></i></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
          <div class="float-right">
            <a href="javascript:" class="m-r-10">mark as read</a>
            <a href="javascript:">clear all</a>
          </div>
        </div>
        <ul class="noti-body">
          <li class="n-title">
            <p class="m-b-0">NEW</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>John Doe</strong><span class="n-time text-muted"><i
                      class="icon feather icon-clock m-r-10"></i>now</span></p>
                <p>New ticket Added</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Joseph William</strong><span class="n-time text-muted"><i
                      class="icon feather icon-clock m-r-10"></i>10 min</span></p>
                <p>Prchace New Theme and make payment</p>
              </div>
            </div>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i
                      class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>currently login</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="noti-footer">
          <a href="javascript:">show all</a>
        </div>
      </div>
    </div>
  </li> -->
  <!--   <li (click)="this.visibleUserList = !this.visibleUserList;"><a href="javascript:" class="displayChatbox">
    <i class="icon feather icon-mail"></i></a>
  </li> -->
  <li>
    <div class="dropdown drp-user" ngbDropdown placement='auto'>
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-settings"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img style="display: inline-block;"
            [src]="userDetails && userDetails.photo ? userDetails.photo : 'assets/images/user/avatar-1.svg'"
            class="img-radius" alt="User-Profile-Image">
          <span>{{username}}</span>
          <a href="javascript:" class="dud-logout" title="Logout" (click)="logout()">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-settings"></i>  {{ 'NavRight.settings' | translate }}</a></li> -->
          <li><a href="javascript:" (click)="profile()" class="dropdown-item"><i class="feather icon-user"></i> {{
              'NavRight.profile' | translate }}</a></li>
          <li><a href="javascript:" (click)="logo()" label="Show" class="dropdown-item"><i
                class="feather icon-edit"></i>{{ 'NavRight.customization' | translate }}</a></li>

        </ul>
      </div>
    </div>
  </li>
</ul>
<section class="header-user-list" *ngIf="visibleUserList && !this.dattaConfig['rtl-layout']"
  [ngClass]="{'open': visibleUserList}" [@slideInOutLeft]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)"
    (onChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage && !this.dattaConfig['rtl-layout']" [ngClass]="{'open': chatMessage}"
  [@slideInOutLeft]>
  <app-chat-msg (onChatToggle)="this.chatMessage = !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>

<section class="header-user-list" *ngIf="visibleUserList && this.dattaConfig['rtl-layout']"
  [ngClass]="{'open': visibleUserList}" [@slideInOutRight]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)"
    (onChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage && this.dattaConfig['rtl-layout']" [ngClass]="{'open': chatMessage}"
  [@slideInOutRight]>
  <app-chat-msg (onChatToggle)="this.chatMessage = !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>

<section class="header-chat" [@slideInOutRight]>

</section>


<p-dialog #dialDetail [(visible)]="dataDialog" [baseZIndex]="10000" [style]="{width: '50%'}"
  header="{{ 'NavRight.customization' | translate }}" [resizable]="true" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div id="styleSelector" class="menu-styler" [ngClass]="{'open': styleSelectorToggle}">
      <!--<div class="style-toggler">
          <a href="javascript:" (click)="styleSelectorToggle = !styleSelectorToggle"></a>
        </div>-->
      <div class="style-block">
        <ngb-tabset type="pills">
          <ngb-tab title="{{ 'NavRight.logo' | translate }}">
            <ng-template ngbTabContent>
              <mat-form-field>
                <div>
                  <mat-toolbar class="file-div">
                    <div class="wrapper">
                      <div class="file-upload">
                        <input type="file" id="fileInput" name="fileInput" accept=".png, .jpg, .jpeg, .gif"
                          (change)="selectFiles($event)" />
                        <input class="file-input" type="file" />
                        <i style="font-size: 45px;" class="fa fa-arrow-up"></i>
                        <span class="span-upload">{{selectedFileNames.length ? selectedFileNames :
                          'NavRight.UploadYourLogo' | translate }}</span>
                      </div>
                    </div>
                    <!-- <input pInputText [value]="selectedFileNames.length ? selectedFileNames : 'Select Images'" /> -->
                  </mat-toolbar>

                  <div *ngIf="selectedFileNames.length" class="div-preview row">
                    <img [src]="registrationForm.value.file" class="preview">

                    <button pButton pRipple label="{{ 'NavRight.UpdateLogo' | translate }}" class="add-button"
                      [disabled]="!selectedFiles" (click)="uploadFiles()">
                    </button>
                  </div>
                </div>
              </mat-form-field>

            </ng-template>
          </ngb-tab>
          <ngb-tab title="{{ 'NavRight.MenuCustomizer' | translate }}">
            <ng-template ngbTabContent>
              <h6 *ngIf="dattaConfig['pre-layout'] !== 'layout-8'">{{ 'NavRight.Layouts' | translate }}</h6>
              <div *ngIf="dattaConfig['pre-layout'] !== 'layout-8'" class="theme-color layout-type">
                <a href="javascript:" [ngClass]="{'active': layoutType === 'menu-dark'}"
                  (click)="setLayout('menu-dark')" data-value="menu-dark"
                  ngbTooltip="{{ 'NavRight.DefaultLayout' | translate }}"><span></span><span></span></a>
                <a href="javascript:" [ngClass]="{'active': layoutType === 'menu-light'}"
                  (click)="setLayout('menu-light')" data-value="menu-light"
                  ngbTooltip="{{ 'NavRight.Light' | translate }}"><span></span><span></span></a>
                <!--<a href="javascript:" [ngClass]="{'active': layoutType === 'dark'}" (click)="setLayout('dark')" data-value="dark" ngbTooltip="Dark"><span></span><span></span></a> -->
                <a href="javascript:" [ngClass]="{'active': layoutType === 'reset'}" (click)="setLayout('reset')"
                  data-value="reset" ngbTooltip="{{ 'NavRight.Reset' | translate }}">{{ 'NavRight.ResetToDefault' |
                  translate }}</a>
              </div>

              <ngb-tabset type="pills">
                <ngb-tab title="{{ 'NavRight.Color' | translate }}" class="mb-3">
                  <ng-template ngbTabContent *ngIf="dattaConfig['pre-layout'] !== 'layout-6'">
                    <perfect-scrollbar ngClass="datta-scroll" [style.max-height]="'calc(100vh - 350px)'"
                      [usePSClass]="'datta'" [disabled]="null" [autoPropagation]="true">
                      <h6>{{ 'NavRight.HeaderBackground' | translate }}</h6>
                      <div class="theme-color header-color">
                        <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-default'}"
                          (click)="setHeaderBackground('header-default')"
                          data-value="header-default"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-blue'}"
                          (click)="setHeaderBackground('header-blue')"
                          data-value="header-blue"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-red'}"
                          (click)="setHeaderBackground('header-red')"
                          data-value="header-red"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-purple'}"
                          (click)="setHeaderBackground('header-purple')"
                          data-value="header-purple"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-lightblue'}"
                          (click)="setHeaderBackground('header-lightblue')"
                          data-value="header-lightblue"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-dark'}"
                          (click)="setHeaderBackground('header-dark')"
                          data-value="header-dark"><span></span><span></span></a>
                          <a href="javascript:" [ngClass]="{'active': headerBackgroundColor === 'header-white'}"
                          (click)="setHeaderBackground('header-white')"
                          data-value="header-white"><span></span><span></span></a> 
                      </div>
                      <h6>{{ 'NavRight.MenuBackground' | translate }}</h6>
                      <div class="theme-color navbar-color">
                        <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-default'}"
                          (click)="setNavbarBackground('navbar-default')"
                          data-value="navbar-default"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-blue'}"
                          (click)="setNavbarBackground('navbar-blue')"
                          data-value="navbar-blue"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-red'}"
                          (click)="setNavbarBackground('navbar-red')"
                          data-value="navbar-red"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-purple'}"
                          (click)="setNavbarBackground('navbar-purple')"
                          data-value="navbar-purple"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-lightblue'}"
                          (click)="setNavbarBackground('navbar-lightblue')"
                          data-value="navbar-lightblue"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-dark'}"
                          (click)="setNavbarBackground('navbar-dark')"
                          data-value="navbar-dark"><span></span><span></span></a>
                          <a href="javascript:" [ngClass]="{'active': navbarBackgroundColor === 'navbar-forestgreen'}"
                          (click)="setNavbarBackground('navbar-forestgreen')"
                          data-value="navbar-forestgreen"><span></span><span></span></a> 
                      </div>
                      <h6 *ngIf="dattaConfig['layout'] !== 'horizontal'">{{ 'NavRight.MenuBrandColor' | translate }}
                      </h6>
                      <div *ngIf="dattaConfig['layout'] !== 'horizontal'" class="theme-color brand-color">
                        <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-default'}"
                          (click)="setBrandBackground('brand-default')"
                          data-value="brand-default"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-blue'}"
                          (click)="setBrandBackground('brand-blue')"
                          data-value="brand-blue"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-red'}"
                          (click)="setBrandBackground('brand-red')"
                          data-value="brand-red"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-purple'}"
                          (click)="setBrandBackground('brand-purple')"
                          data-value="brand-purple"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-lightblue'}"
                          (click)="setBrandBackground('brand-lightblue')"
                          data-value="brand-lightblue"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-dark'}"
                          (click)="setBrandBackground('brand-dark')"
                          data-value="brand-dark"><span></span><span></span></a>
                          <a href="javascript:" [ngClass]="{'active': brandBackgroundColor === 'brand-white'}"
                          (click)="setBrandBackground('brand-white')"
                          data-value="brand-white"><span></span><span></span></a>
                      </div>
                      <h6 *ngIf="dattaConfig['layout'] !== 'horizontal'">{{ 'NavRight.NavbarImage' | translate }}</h6>
                      <div *ngIf="dattaConfig['layout'] !== 'horizontal'" class="theme-color navbar-images">
                        <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-1'}"
                          (click)="setBackgroundImage('navbar-image-1')"
                          data-value="navbar-image-1"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-2'}"
                          (click)="setBackgroundImage('navbar-image-2')"
                          data-value="navbar-image-2"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-3'}"
                          (click)="setBackgroundImage('navbar-image-3')"
                          data-value="navbar-image-3"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-4'}"
                          (click)="setBackgroundImage('navbar-image-4')"
                          data-value="navbar-image-4"><span></span><span></span></a>
                        <a href="javascript:" [ngClass]="{'active': navBackgorundImage === 'navbar-image-5'}"
                          (click)="setBackgroundImage('navbar-image-5')"
                          data-value="navbar-image-5"><span></span><span></span></a>
                      </div>
                    </perfect-scrollbar>
                  </ng-template>
                  <ng-template ngbTabContent *ngIf="dattaConfig['pre-layout'] === 'layout-6'">
                    <perfect-scrollbar ngClass="datta-scroll" [style.max-height]="'calc(100vh - 350px)'"
                      [usePSClass]="'datta'" [disabled]="null" [autoPropagation]="true">
                      <h6>{{ 'NavRight.BackgroundColor' | translate }}</h6>
                      <div class="theme-color laybg-color small">
                        <a href="javascript:" [ngClass]="{'active': headerBackColor === '#04a9f5'}"
                          (click)="setHeaderBackColor('#04a9f5')" data-value="#04a9f5" style="background:#04a9f5"></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackColor === '#ff5252'}"
                          (click)="setHeaderBackColor('#ff5252')" data-value="#ff5252" style="background:#ff5252"></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackColor === '#9575CD'}"
                          (click)="setHeaderBackColor('#9575CD')" data-value="#9575CD" style="background:#9575CD"></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackColor === '#23b7e5'}"
                          (click)="setHeaderBackColor('#23b7e5')" data-value="#23b7e5" style="background:#23b7e5"></a>
                        <a href="javascript:" [ngClass]="{'active': headerBackColor === '#424448'}"
                          (click)="setHeaderBackColor('#424448')" data-value="#424448" style="background:#424448"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #1de9b6 0%, #1dc4e9 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #1de9b6 0%, #1dc4e9 100%)')"
                          style="background:linear-gradient(to right, #1de9b6 0%, #1dc4e9 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #899FD4 0%, #A389D4 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #899FD4 0%, #A389D4 100%)')"
                          style="background:linear-gradient(to right, #899FD4 0%, #A389D4 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #4facfe 0%, #00f2fe 100%)')"
                          style="background:linear-gradient(to right, #4facfe 0%, #00f2fe 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #667eea 0%, #764ba2 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #667eea 0%, #764ba2 100%)')"
                          style="background:linear-gradient(to right, #667eea 0%, #764ba2 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #f77062 0%, #fe5196 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #f77062 0%, #fe5196 100%)')"
                          style="background:linear-gradient(to right, #f77062 0%, #fe5196 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #9be15d 0%, #00e3ae 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #9be15d 0%, #00e3ae 100%)')"
                          style="background:linear-gradient(to right, #9be15d 0%, #00e3ae 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #b224ef 0%, #7579ff 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #b224ef 0%, #7579ff 100%)')"
                          style="background:linear-gradient(to right, #b224ef 0%, #7579ff 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #0acffe 0%, #495aff 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #0acffe 0%, #495aff 100%)')"
                          style="background:linear-gradient(to right, #0acffe 0%, #495aff 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #01a9ac 0%, #01dbdf 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #01a9ac 0%, #01dbdf 100%)')"
                          style="background:linear-gradient(to right, #01a9ac 0%, #01dbdf 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #fe5d70 0%, #fe909d 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #fe5d70 0%, #fe909d 100%)')"
                          style="background:linear-gradient(to right, #fe5d70 0%, #fe909d 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #0ac282 0%, #0df3a3 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #0ac282 0%, #0df3a3 100%)')"
                          style="background:linear-gradient(to right, #0ac282 0%, #0df3a3 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #fe9365 0%, #feb798 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #fe9365 0%, #feb798 100%)')"
                          style="background:linear-gradient(to right, #fe9365 0%, #feb798 100%)"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)'}"
                          (click)="setHeaderBackColor('linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)')"
                          data-value="linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)"
                          style="background:linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)"></a>
                      </div>
                      <h6>{{ 'NavRight.BackgroundImage' | translate }}</h6>
                      <hr>
                      <div class="theme-color bg-images">
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/bg1.jpg\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/bg1.jpg\')')"
                          style="background-image:url('assets/images/bg-images/bg1.jpg'); background-size: 45px 30px;"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/bg3.jpg\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/bg3.jpg\')')"
                          style="background-image:url('assets/images/bg-images/bg3.jpg'); background-size: 45px 30px;"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/bg4.jpg\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/bg4.jpg\')')"
                          style="background-image:url('assets/images/bg-images/bg4.jpg'); background-size: 45px 30px;"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/bg5.jpg\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/bg5.jpg\')')"
                          style="background-image:url('assets/images/bg-images/bg5.jpg'); background-size: 45px 30px;"></a>
                      </div>
                      <h6>{{ 'NavRight.BackgroundPattern' | translate }}</h6>
                      <hr>
                      <div class="theme-color bg-images pattern">
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/1.png\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/1.png\')')"
                          style="background-image:url('assets/images/bg-images/1.png'); background-size: 45px 30px;"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/2.png\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/2.png\')')"
                          style="background-image:url('assets/images/bg-images/2.png'); background-size: 45px 30px;"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/3.png\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/3.png\')')"
                          style="background-image:url('assets/images/bg-images/3.png'); background-size: 45px 30px;"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/4.png\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/4.png\')')"
                          style="background-image:url('assets/images/bg-images/4.png'); background-size: 45px 30px;"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/5.png\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/5.png\')')"
                          style="background-image:url('assets/images/bg-images/5.png'); background-size: 45px 30px;"></a>
                        <a href="javascript:"
                          [ngClass]="{'active': headerBackColor === 'url(\'assets/images/bg-images/6.png\')'}"
                          (click)="setHeaderBackColor('url(\'assets/images/bg-images/6.png\')')"
                          style="background-image:url('assets/images/bg-images/6.png'); background-size: 45px 30px;"></a>
                      </div>
                    </perfect-scrollbar>
                  </ng-template>
                </ngb-tab>
                <ngb-tab title="{{ 'NavRight.Layouts' | translate }}" *ngIf="dattaConfig['layout'] !== 'horizontal'">
                  <ng-template ngbTabContent>
                    <perfect-scrollbar ngClass="datta-scroll" [style.max-height]="'calc(100vh - 350px)'"
                      [usePSClass]="'datta'">
                      <!-- <div *ngIf="dattaConfig['pre-layout'] !== 'layout-8' && dattaConfig['pre-layout'] !== 'layout-6'"
                        class="form-group mb-0">
                        <div class="switch switch-primary d-inline m-r-10">
                          <input type="checkbox" id="theme-rtl" [(ngModel)]='rtlLayout' (change)="setRtlLayout($event)">
                          <label for="theme-rtl" class="cr"></label>
                        </div>
                        <label>{{ 'NavRight.RTL' | translate }}</label>
                      </div> -->
                      <div *ngIf="dattaConfig['pre-layout'] !== 'layout-8' && dattaConfig['pre-layout'] !== 'layout-6'"
                        class="form-group mb-0">
                        <div class="switch switch-primary d-inline m-r-10">
                          <input type="checkbox" id="menu-fixed" [(ngModel)]='menuFixedLayout'
                            (change)="setMenuFixedLayout($event)">
                          <label for="menu-fixed" class="cr"></label>
                        </div>
                        <label>{{ 'NavRight.MenuFixed' | translate }}</label>
                      </div>
                      <div *ngIf="dattaConfig['pre-layout'] !== 'layout-8' && dattaConfig['pre-layout'] !== 'layout-6'"
                        class="form-group mb-0">
                        <div class="switch switch-primary d-inline m-r-10">
                          <input type="checkbox" id="header-fixed" [(ngModel)]='headerFixedLayout'
                            (change)="setHeaderFixedLayout($event)">
                          <label for="header-fixed" class="cr"></label>
                        </div>
                        <label>{{ 'NavRight.HeaderFixed' | translate }}</label>
                      </div>
                      <div class="form-group mb-0">
                        <div class="switch switch-primary d-inline m-r-10">
                          <input type="checkbox" id="box-layouts" [(ngModel)]='boxLayout'
                            (change)="setBoxLayout($event)">
                          <label for="box-layouts" class="cr"></label>
                        </div>
                        <label>{{ 'NavRight.BoxLayouts' | translate }}</label>
                      </div>
                    </perfect-scrollbar>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>

            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>

  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="{{ 'NavRight.Exit' | translate }}" icon="pi pi-times" class="p-button-text"
      (click)="hideDialog()"></button>
    <!-- <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveData()"></button> -->
  </ng-template>
</p-dialog>