import { Injectable } from '@angular/core';
  import { environment } from './../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppSettings } from './../common/app.settings';
import { error } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http:HttpClient) { }

  getRoles(defaultLang): Promise<any> {
    return this.http.get(`${environment.admin.getRoles}`).toPromise().then(response => response as any);
  } 

  pullDefaultTwoFactorAuth(data): Promise<any> {
    return this.http.patch(`${environment.admin.pullDefaultTwoFactorAuth}`, data).toPromise().then(response => response as any).catch(error => {
      return error
    });
  }

  getRefTables(excelFile, defaultLang): Promise<any>{
    let url =  `${environment.apiPowerbiUrl}/api/RefData/${excelFile}/${defaultLang}`;
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }

  deleteRefPrograms(programId): Promise<any> {
    return this.http.delete(`${environment.deleteRefPrograms}/${programId}`).toPromise().then(response => response as any).catch(error => {
      return error
    });
  }

  addStudyProgram(data): Promise<any> {
    return this.http.post(`${environment.addStudyProgram}`, data).toPromise().then(response => response as any).catch(error => {return error});
  }

  updateStudyProgram(data): Promise<any> {
    return this.http.put(`${environment.updateStudyProgram}`, data).toPromise().then(response => response as any).catch(error => {return error});
  }

  getStudyPrograms(lang): Promise<any> {
    return this.http.get(`${environment.getStudyPrograms}/${lang}`).toPromise().then(response => response as any).catch(error => {return error});;
  } 

  getAllIscedBands(lang): Promise<any> {
    return this.http.get(`${environment.getAllIscedBands}/${lang}`).toPromise().then(response => response as any).catch(error => {return error});;
  } 

  getTrainingStudyFields(lang): Promise<any> {
    return this.http.get(`${environment.getTrainingStudyFields}/${lang}`).toPromise().then(response => response as any).catch(error => {return error});;
  } 

  getTvetSectors(lang): Promise<any> {
    return this.http.get(`${environment.getTvetSectors}/${lang}`).toPromise().then(response => response as any).catch(error => {return error});;
  } 

  getIsicIndustrySectors(lang): Promise<any> {
    return this.http.get(`${environment.getIsicIndustrySectors}/${lang}`).toPromise().then(response => response as any).catch(error => {return error});;
  } 

  getLocalBands(lang): Promise<any> {
    return this.http.get(`${environment.getLocalBands}/${lang}`).toPromise().then(response => response as any).catch(error => {return error});;
  } 

  getCategories(lang): Promise<any> {
    return this.http.get(`${environment.getCategories}/${lang}`).toPromise().then(response => response as any).catch(error => {return error});;
  } 

  calculateKPI(academicYear, academicPeriod): Promise<any> {
    return this.http.post(`${environment.calculateKPI}/${academicYear}/${academicPeriod}`, null).toPromise().then(response => response as any).catch(error => {return error});;
  } 
}
