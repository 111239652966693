import { Injectable } from "@angular/core";
import { AppSettings } from "./common/app.settings";
import { AESEncryptDecryptService } from "./services/security/aesencrypt-decrypt.service";



export class DattaConfig {
  static instance: DattaConfig;
  static customization: any = JSON.parse(localStorage.getItem(AppSettings.customizationKey));;

  public static config = {
    layout: DattaConfig.customization ? DattaConfig.customization['layout'] : 'vertical', // vertical, horizontal
    'pre-layout': DattaConfig.customization ? DattaConfig.customization['pre-layout'] : null, // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
    'collapse-menu': DattaConfig.customization ? DattaConfig.customization['collapse-menu'] : false,
    'layout-type': DattaConfig.customization ? DattaConfig.customization['layout-type'] : 'menu-dark', // menu-dark, menu-light, dark
    'nav-icon-color': DattaConfig.customization ? DattaConfig.customization['nav-icon-color'] : false,
    'header-back-color': DattaConfig.customization ? DattaConfig.customization['header-back-color'] : 'header-default', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark, header-white
    'nav-back-color': DattaConfig.customization ? DattaConfig.customization['nav-back-color'] : 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark, navbar-forestgreen
    'nav-brand-color': DattaConfig.customization ? DattaConfig.customization['nav-brand-color'] : 'brand-dark', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark, brand-white
    'nav-back-image': DattaConfig.customization ? DattaConfig.customization['nav-back-image'] : false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
    'rtl-layout': DattaConfig.customization ? DattaConfig.customization['rtl-layout'] : false,
    'nav-fixed-layout': DattaConfig.customization ? DattaConfig.customization['nav-fixed-layout'] : true,
    'header-fixed-layout': DattaConfig.customization ? DattaConfig.customization['header-fixed-layout'] : false,
    'box-layout': DattaConfig.customization ? DattaConfig.customization['box-layout'] : false,
    'nav-dropdown-icon': DattaConfig.customization ? DattaConfig.customization['nav-dropdown-icon'] : 'style1', // style1, style2, style3
    'nav-list-icon': DattaConfig.customization ? DattaConfig.customization['nav-list-icon'] : 'style1', // style1, style2, style3, style4, style5, style6
    'nav-active-list-color': DattaConfig.customization ? DattaConfig.customization['nav-active-list-color'] : 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
    'nav-list-title-color': DattaConfig.customization ? DattaConfig.customization['nav-list-title-color'] : 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
    'nav-list-title-hide': DattaConfig.customization ? DattaConfig.customization['nav-list-title-hide'] : false,
    'layout-6-background' : DattaConfig.customization ? DattaConfig.customization['layout-6-background'] : '#23b7e5' // used only for pre-layout = layout-6
  };
}

/* layout-6 background option*/
/* 1 - background color */
/*
#04a9f5,#ff5252, #9575CD, #23b7e5, #424448
linear-gradient(to right, #1de9b6 0%, #1dc4e9 100%)
linear-gradient(to right, #899FD4 0%, #A389D4 100%)
linear-gradient(to right, #4facfe 0%, #00f2fe 100%)
linear-gradient(to right, #667eea 0%, #764ba2 100%)
linear-gradient(to right, #f77062 0%, #fe5196 100%)
linear-gradient(to right, #9be15d 0%, #00e3ae 100%)
linear-gradient(to right, #b224ef 0%, #7579ff 100%)
linear-gradient(to right, #0acffe 0%, #495aff 100%)
linear-gradient(to right, #01a9ac 0%, #01dbdf 100%)
linear-gradient(to right, #fe5d70 0%, #fe909d 100%)
linear-gradient(to right, #0ac282 0%, #0df3a3 100%)
linear-gradient(to right, #fe9365 0%, #feb798 100%)
linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)
*/

/* 2 - background image */
/*
url("assets/images/bg-images/bg1.jpg")
url("assets/images/bg-images/bg3.jpg")
url("assets/images/bg-images/bg4.jpg")
url("assets/images/bg-images/bg5.jpg")
*/

/* 3 - background pattern */
/*
url("assets/images/bg-images/1.png")
url("assets/images/bg-images/2.png")
url("assets/images/bg-images/3.png")
url("assets/images/bg-images/4.png")
url("assets/images/bg-images/5.png")
url("assets/images/bg-images/6.png")
*/
