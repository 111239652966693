import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {

  secretKey = environment.SecretKeyForEncryptionDescryption;
  constructor() { }

  encrypt(value : string) : string{
    try{
      return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    } catch (error) {
      return null
    }
  }

  decrypt(textToDecrypt : string){
    try{
      return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return null
    }
  }
}
