import { AppUtilities } from './../common/app.utilities';

import { environment } from './../../environments/environment';

import { ExcelFiles } from './../models/ExcelFiles';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';



@Injectable({

  providedIn: 'root'

})

export class UploadService {

  constructor(private http: HttpClient) { }


  /**
   * Gets user id
   * @returns
   */
  getUserId() {
    const token = AppUtilities.getDecodedAccessToken();
    return token.userId;
  }


  /**
   * Pushs files to storage
   * @param files
   * @param institutionId
   * @param excelFile
   * @param academicYearCode
   * @param academicPeriodCode
   * @returns files to storage
   */
  pushFilesToStorage(files: [File, File], institutionId, excelFile, academicYearCode, academicPeriodCode): Promise<any> {

    const data: FormData = new FormData();

    //let userId = this.getUserId();



    files.forEach(element => {

      data.append('files', element);

    })



    // let uploadUrl=`${environment.apiPowerbiUrl}/file/upload/${userId}/${excelFile}/${academicYearCode}/${academicPeriodCode}`;

    let uploadUrl = `${environment.apiPowerbiUrl}/file/upload/${institutionId}/${excelFile}/${academicYearCode}/${academicPeriodCode}`;



    return this.http.post(uploadUrl, data, { observe: 'response' }).toPromise()

      .then(response => response)

      .catch((error: any) => console.log(error));

  }


  pushMinistryFilesToStorage(files: [File, File], excelFile, academicYearCode, academicPeriodCode): Promise<any> {

    const data: FormData = new FormData();
    files.forEach(element => {
      data.append('files', element);
    })
    let uploadUrl = `${environment.apiPowerbiUrl}/file/MinistryFilesUpload/${excelFile}/${academicYearCode}/${academicPeriodCode}`;
    // /File/MinistryFilesUpload/{category}/{yearId}/{periodId}
    return this.http.post(uploadUrl, data, { observe: 'response' }).toPromise()
      .then(response => response)
      .catch((error: any) => console.log(error));
  }



  getFileDetails(excelFile, institutionId): Promise<any> {



    let url = `${environment.getFilesByInstitutionUrl}/${institutionId}/${excelFile}`;// excelFile = batch category name

    return this.http.get<any>(url).toPromise()

      .then(response => response as any);



  }


  getMinistryFileOverview(): Promise<any> {
    // /MinistryInstitutionDetails/GetFiles
    let url = `${environment.getMinistryFilesOverview}`;// excelFile = batch category name
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }

  getMinistryFileDetails(): Promise<any> {
    // /MinistryInstitutionDetails/GetFiles
    let url = `${environment.getMinistryFilesDetails}`;// excelFile = batch category name
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }



  // getUploadValidateRoles():Promise<any> {

  //   return this.http.get<any>(`${environment.getUploadValidateRolesByInstitutionUrl}`).toPromise()

  //   .then(response => response as any);

  // }



  getUploadValidateRolesByInstitution(institutionId): Promise<any> {

    return this.http.get<any>(`${environment.getUploadValidateRolesByInstitutionUrl}/${institutionId}`).toPromise()

      .then(response => response as any);

  }





  getAuthGuardUpload(institutionId, category): Promise<any> {

    return this.http.get<any>(`${environment.getAuthGuardUploadUrl}/${institutionId}/${category}`).toPromise()

      .then(response => response as any);

  }





  getAuthGuardValidation(institutionId, category): Promise<any> {

    return this.http.get<any>(`${environment.getAuthGuardValidationUrl}/${institutionId}/${category}`).toPromise()

      .then(response => response as any);

  }



  // getFileDetailsByUniversity(excelFile):Promise<any> {

  //   switch (excelFile) {

  //     case ExcelFiles.StudentOverview:

  //       return this.http.get<any>(environment.students.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StudentApplicants:

  //       return this.http.get<any>(environment.applicants.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StudentGraduate:

  //       return this.http.get<any>(environment.studentsGraduates.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StudentResult:

  //       return this.http.get<any>(environment.studentsResults.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StudentAttrition:

  //       return this.http.get<any>(environment.studentsAttrition.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StudentEnrollment:

  //       return this.http.get<any>(environment.studentsEnrollment.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StaffOverview:

  //       return this.http.get<any>(environment.staffOverview.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StaffAssignment:

  //       return this.http.get<any>(environment.staffAssignment.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StaffAttrition:

  //       return this.http.get<any>(environment.staffAttrition.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StaffAwards:

  //       return this.http.get<any>(environment.staffAwards.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionResources:

  //       return this.http.get<any>(environment.institutionResources.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionOverview:

  //       return this.http.get<any>(environment.institutionOverview.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionBuildings:

  //       return this.http.get<any>(environment.institutionBuildings.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionRD:

  //       return this.http.get<any>(environment.institutionRD.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionPublications:

  //       return this.http.get<any>(environment.institutionPublications.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionFinancials:

  //       return this.http.get<any>(environment.institutionFinancials.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     // case ExcelFiles.institutionEducationPrograms:

  //     //   return this.http.get<any>(environment.institutionEducationPrograms.getFilesByUniversityUrl).toPromise()

  //     //     .then(response => response as any);

  //     case ExcelFiles.InstitutionPatents:

  //       return this.http.get<any>(environment.institutionPatents.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionCommunityEngagement:

  //       return this.http.get<any>(environment.InstitutionCommunityEngagement.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionInspections:

  //       return this.http.get<any>(environment.InstitutionInspections.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionTechnologyDevelopment:

  //       return this.http.get<any>(environment.InstitutionTechnologyDevelopment.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionIndustryCooperation:

  //       return this.http.get<any>(environment.InstitutionIndustryCooperation.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionIndustryExtension:

  //       return this.http.get<any>(environment.InstitutionIndustryExtension.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.InstitutionIndigenousKnowledge:

  //       return this.http.get<any>(environment.InstitutionIndigenousKnowledge.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.MiniInstitution:

  //       return this.http.get<any>(environment.miniInstitution.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.GraduateEmployment:

  //       return this.http.get<any>(environment.graduateEmployment.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StudentInternship:

  //       return this.http.get<any>(environment.studentsInternship.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StudentCooperativeTraining:

  //       return this.http.get<any>(environment.studentsCooperativeTraining.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     case ExcelFiles.StudentsApprenticeship:

  //       return this.http.get<any>(environment.studentsApprenticeship.getFilesByUniversityUrl).toPromise()

  //         .then(response => response as any);

  //     default:

  //       break;

  //   }

  // }



  processFile(excelFile, idFile: any, institutionId: any): Promise<any> {

    //institutionOverview/process/13

    var lowerExcelFile = excelFile.toLowerCase();

    let url = `${environment.apiPowerbiUrl}/${lowerExcelFile}/process/${idFile}/${institutionId}`;

    return this.http.get(url)

      .toPromise().then(response => response as any);

  }

  processOverviewFiles(idFile: any): Promise<any> {
    let url = `${environment.processOverviewFiles}/${idFile}`;// excelFile = batch category name
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }


  processDetailsFiles(idFile: any): Promise<any> {
    let url = `${environment.processDetailsFiles}/${idFile}`;// excelFile = batch category name
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }



  deleteProcessedFileRecords(excelFile, executionId: any, idFile: any): Promise<any> {

    let url = `${environment.apiPowerbiUrl}/${excelFile}/deleteRecordsFile/${idFile}`;

    return this.http.delete<any>(url).toPromise()

      .then(response => response as any);

  }



  getValidRecords(excelFile, jobId): Promise<any> {

    //http://20.52.55.75:9081/institutionOverview/getInvalid/1



    let url = `${environment.apiPowerbiUrl}/${excelFile}/getValid/${jobId}`;

    return this.http.get<any>(url).toPromise()

      .then(response => response as any);



  }
  getMinistryValidOverviewRecords(jobId): Promise<any> {
    //http://20.52.55.75:9081/institutionOverview/getInvalid/1
    // /MinistryInstitutionOverview/GetValid/{jobId}
    let url = `${environment.apiPowerbiUrl}/MinistryInstitutionOverview/GetValid/${jobId}`;
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }
  getMinistryValidDetailsRecords(jobId): Promise<any> {
    //http://20.52.55.75:9081/institutionOverview/getInvalid/1
    // /MinistryInstitutionDetails/GetValid/{jobId}
    let url = `${environment.apiPowerbiUrl}/MinistryInstitutionDetails/GetValid/${jobId}`;
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }



  getInvalidRecords(excelFile, jobId): Promise<any> {

    let url = `${environment.apiPowerbiUrl}/${excelFile}/getInvalid/${jobId}`;

    return this.http.get<any>(url).toPromise()

      .then(response => response as any);

  }
  getMinistryInvalidOverviewRecords(jobId): Promise<any> {
    // /MinistryInstitutionOverview/GetInvalid/{jobId}
    let url = `${environment.apiPowerbiUrl}/MinistryInstitutionOverview/GetInvalid/${jobId}`;
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }
  getMinistryInvalidDetailsRecords(jobId): Promise<any> {
    // /MinistryInstitutionDetails/GetInvalid/{jobId}
    let url = `${environment.apiPowerbiUrl}/MinistryInstitutionDetails/GetInvalid/${jobId}`;
    return this.http.get<any>(url).toPromise()
      .then(response => response as any);
  }




  deleteFileById(idFile: any): Promise<any> {

    let deleteUrl = `${environment.deleteFileByIdUrl}/${idFile}`;

    return this.http.delete<any>(deleteUrl).toPromise()

      .then(response => response as any);

  }



  deleteRecordsByIdAndJobExecutionUrl(intJobExecution: any, stringId: any): Promise<any> {

    let deleteUrl = `${environment.deleteRecordsByIdAndJobExecutionUrl}/${intJobExecution}/${stringId}`;

    return this.http.delete<any>(deleteUrl, { observe: 'response' }).toPromise()

      .then(response => response as any).catch(error => { return error });

  }









  getAllAcademicYears(): Promise<any> {

    return this.http.get<any>(`${environment.getAcademicYearsUrl}`).toPromise()

      .then(response => response as any);

  }



  submitFileForValidation(idFile: any): Promise<any> {

    return this.http.put<any>(`${environment.submitFileForValidationUrl}/${idFile}`, null).toPromise()

      .then(response => response as any);

  }



  getFilesToVlidateByBatchInstitution(batchCategory, institutionId, academicYearId): Promise<any> {

    return this.http.get<any>(`${environment.getFilesToVlidateByBatchInstitutionUrl}/${batchCategory}/${institutionId}/${academicYearId}`).toPromise()

      .then(response => response as any);

  }


  getMinistryFilesToValidateByBatch(batchCategory): Promise<any> {
    return this.http.get<any>(`${environment.getMinistryFilesToValidateByBatch}/${batchCategory}`).toPromise()
      .then(response => response as any);
  }



  validateFile(idFile: any): Promise<any> {

    return this.http.put<any>(`${environment.validateFileUrl}/${idFile}`, null).toPromise()

      .then(response => response as any);

  }

  returnFileForReview(idFile: any): Promise<any> {

    return this.http.put<any>(`${environment.returnFileForReviewUrl}/${idFile}`, null).toPromise()

      .then(response => response as any);

  }



  getAcademicYears(): Promise<any> {

    return this.http.get<any>(`${environment.getAcademicYearsUrl}`).toPromise()

      .then(response => response as any);

  }

    /**
   * Gets current and previous academic years
   * @param token
   * @returns current and previous years
   */
    getCurrentAndPreviousAcademicYears(): Promise<any> {
      return this.http.get(environment.addInstitution.getCurrentAndPreviousAcademicYears).toPromise().then(response => response as any);
    }

    

  getAcademicPeriods(): Promise<any> {

    return this.http.get<any>(`${environment.getAcademicPeriodsUrl}`).toPromise()

      .then(response => response as any);

  }



  getAreaTypes(): Promise<any> {

    return this.http.get<any>(`${environment.getAreaTypesUrl}`).toPromise()

      .then(response => response as any);

  }







  getGenders(): Promise<any> {

    return this.http.get<any>(`${environment.getGendersUrl}`).toPromise()

      .then(response => response as any);

  }



  updateStudentOverview(data): Promise<any> {

    return this.http.put(`${environment.updateStudentOverviewUrl}`, data).toPromise().then(response => {

      return response

    }).catch(error => {

      return error

    });

  }


  getFileDetailsByInstitutionIds(institutionId, academicYearId, academicPeriodId): Promise<any> {

    return this.http.get(`${environment.getFilesByInstitutionIds}/${institutionId}/${academicYearId}/${academicPeriodId}`).toPromise().then(response => {

      return response

    }).catch(error => {

      return error

    });

  }



  getParentInstitution(academicYearId, lang): Promise<any> {

    return this.http.get(`${environment.getAllParentInstitution}/${academicYearId}/${lang}`).toPromise().then(response => {

      return response

    }).catch(error => {

      return error

    });

  }





  getConfirmationToDeleteFile(fileId): Promise<any> {

    let url = `${environment.getDeleteFilePublicUrl}/${fileId}`;
    return this.http.delete(url).toPromise().then(response => {
      return response

    }).catch(error => {

      return error

    });

  }





  DeleteFile(fileId): Promise<any> {



    let url = `${environment.getDeleteFilePublicUrl}/${fileId}/confirm`;

    return this.http.delete(url).toPromise().then(response => {

      return response

    }).catch(error => {

      return error

    });

  }

  validateStudentData(data): Promise<any> {
    return this.http.post(`${environment.validateStudentDataUrl}`, data).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }

  downloadFile(file): Promise<any> {
    return this.http.post(`${environment.downloadFile}`, file, { responseType: 'blob' }).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }

  downloadTemplateFile(file): Promise<any> {
    return this.http.post(`${environment.downloadTemplateFileUrl}`, file, { responseType: 'blob' }).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }


  downloadTemplateFiles(fileName): Promise<any> {
    return this.http.post(`${environment.downloadTemplateFileUrl}/${fileName}`, { responseType: 'blob' }).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }
}
