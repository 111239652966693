import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PolicygoalsService {

  constructor(private http: HttpClient) { }





  /**
   * Gets policy objectives by stakeholde and class
   * @param type 
   * @returns policy objectives by stakeholde and class 
   */
  getPolicyObjectivesByStakeholdeAndClass(stakeholde, classHei): Promise<any> {
    return this.http.get(`${environment.kpi.getPolicyObjectivesByStakeholdeAndClassUrl}/${stakeholde}/${classHei}`).toPromise().then(response => response as any);
  }

  /**
   * Gets policy objectives by stakeholde tevt
   * @param stakeholde 
   * @returns policy objectives by stakeholde tevt 
   */
  getPolicyObjectivesByStakeholdeTevt(stakeholde): Promise<any> {
    return this.http.get(`${environment.kpi.getPolicyObjectivesByStakeholdeAndClassUrl}/${stakeholde}`).toPromise().then(response => response as any);
  }


  /**
   * Gets stakeholders
   * @param type 
   * @returns stakeholders 
   */
  getStakeholders(): Promise<any> {
    // return this.http.get(`${environment.kpi.getStakeholdersUrl}/${type}`).toPromise().then(response => response as any);
    return this.http.get(`${environment.kpi.getStakeholdersUrl}`).toPromise().then(response => response as any);
  }

  /**
   * Gets policy objectives
   * @param lang 
   * @returns policy objectives 
   */
  getPolicyObjectives(lang): Promise<any> {
    return this.http.get(`${environment.kpi.getPolicyObjectivesUrl}/${lang}`).toPromise().then(response => response as any);
  }

  /**
   * Gets performance indicators by policy
   * @param policyId 
   * @param lang 
   * @returns performance indicators by policy 
   */
  getPerformanceIndicatorsByPolicy(policyId, classId,lang): Promise<any> {
    return this.http.get(`${environment.kpi.getPerformanceIndicatorsByPolicyUrl}/${policyId}/${classId}`).toPromise().then(response => response as any);
  }


  /**
   * Gets hei classes
   * @param defaultLang 
   * @returns  
   */
  getHeiClasses(defaultLang) {
    return this.http.get(`${environment.addInstitution.getHeiClasses}/${defaultLang}`).toPromise().then(response => response as any);
  }


  /**
   * Updates policy objectives list
   * @param data 
   * @returns policy objectives list 
   */
  UpdatePolicyObjectivesList(data, classHei): Promise<any> {
    return this.http.put(`${environment.kpi.UpdatePolicyObjectivesListUrl}/${classHei}`, data).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }

  /**
   * Updates policy objectives list tevet
   * @param data 
   * @returns policy objectives list tevet 
   */
  UpdatePolicyObjectivesListTevet(data): Promise<any> {
    return this.http.put(`${environment.kpi.UpdatePolicyObjectivesListUrl}`, data).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }


  /**
   * Updates policy objectives
   * @param data 
   * @returns policy objectives 
   */
  UpdatePolicyObjectives(data, classHei): Promise<any> {
    return this.http.put(`${environment.kpi.UpdatePolicyObjectiveUrl}/${classHei}`, data).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }

  /**
   * Updates policy objectives tevet
   * @param data 
   * @returns policy objectives tevet 
   */
  UpdatePolicyObjectivesTevet(data): Promise<any> {
    return this.http.put(`${environment.kpi.UpdatePolicyObjectiveUrl}`, data).toPromise().then(response => {
      return response
    }).catch(error => {
      return error
    });
  }
}
