import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { AuthenticationService } from './../../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { DataService } from './../../../services/data.service';
import { AppUtilities } from './../../../common/app.utilities';
import {  AppSettings } from './../../../common/app.settings';
import { Toast, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-connection-confirmation',
  templateUrl: './connection-confirmation.component.html',
  styleUrls: ['./connection-confirmation.component.scss']
})
export class ConnectionConfirmationComponent implements OnInit {

  accountInfo : FormGroup;
  authDataKey:any;

  disableSendButton :boolean = true ;

  constructor(    
    private authenticationService: AuthenticationService,  
    private router: Router,
    private dataService:DataService,
    private toasterService: ToasterService,

      private formBuilder: FormBuilder,
 
    ) { }

  ngOnInit(): void {
    this.dataService.getAcessToken().subscribe(res=>{
     
   
    this.accountInfo = this.formBuilder.group({
      username: [res.username,Validators.required],
      code:['',Validators.required], 
    });
    this.authDataKey =  localStorage.getItem(AppSettings.authDataKey); 
    localStorage.removeItem(AppSettings.authDataKey); 
   
  })
  } 

  checkButton(){
    console.log( this.accountInfo)
 
    if( this.accountInfo.status == "VALID"){
      this.disableSendButton = false ;

    }else{
      this.disableSendButton = true ; 
    }
  }
   

  onSubmit(){
     this.authenticationService.getTwoFactorAuthenticationVerification(this.accountInfo.value.username,this.accountInfo.value.code).then(res=>{
      
      if(res.status.includes("Check your Two Factor Authentication Code")){
        this.callToaster('error', null, "Incorrect Two-Factor Authentication Code",10000);
      }else{ 
        localStorage.setItem(AppSettings.authDataKey, this.authDataKey);
         this.router.navigate(["/welcome"]); 
      } 
    })
  } 


  callToaster(type, toasterTitle, toasterBody,timeout) {
    const toaster: Toast = {
      type: type,
      title: toasterTitle,
      body: toasterBody,
      showCloseButton: true,
      timeout: timeout
    };
    this.toasterService.pop(toaster);
  }


}
  












 