<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h5>{{ "RoleTwoFactor.Title" | translate }} </h5>
                <div class="card-header-right">
                    <div class="btn-group card-option dropdown">

                    </div>
                </div>
            </div>
            <div>
                <div class="card-block">
                    <div class="form-row">
                        <div class="form-group col-md-4">
                        </div>
                        <div class="form-group col-md-4 label-align">
                        </div>
                        <div class="form-group col-md-4">
                        </div>
                    </div>
                    <section class="header new-summary">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="nav flex-column nav-pills-custom" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <a *ngIf="isInfo" class="nav-link mb-3 p-3 shadow active" id="v-pills-home-tab"
                                            data-toggle="pill" (click)="changeTab('isInfo')" role="tab"
                                            aria-controls="v-pills-home" aria-selected="true">
                                            <span class="font-weight-bold text-uppercase">
                                                {{ "RoleTwoFactor.Configuration" | translate }}
                                            </span></a>
                                        <a *ngIf="!isInfo" class="nav-link mb-3 p-3 shadow" id="v-pills-home-tab"
                                            data-toggle="pill" (click)="changeTab('isInfo')" role="tab"
                                            aria-controls="v-pills-home" aria-selected="true">
                                            <span class="font-weight-bold text-uppercase">
                                                {{ "RoleTwoFactor.Configuration" | translate }}
                                            </span></a>

                                    </div>
                                </div>
                                <div *ngIf="rolesList.length >0" class="col-md-9">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div *ngIf="isInfo" class="tab-pane shadow rounded bg-white show active p-4"
                                            id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                            <h3 class="mb-4 new-summary-subTitle">
                                                {{ "RoleTwoFactor.TwoFactorAuthenticationEnabled" | translate }}
                                            </h3>
                                            <table class="summary-table mb-2">
                                                <tbody class="summary-body">
                                                    <div *ngFor="let role of rolesList">
                                                        <div class="mb-3"></div>
                                                        <tr>
                                                            <td class="summary-subTitle">
                                                                <h4>{{ getNameOfRole(role.name)}} </h4>
                                                            </td>
                                                            <td class="summary-value">
                                                                <!-- <input type="checkbox" class="form-control"
                                                                    id="{{role.roleId}}"
                                                                    [(ngModel)]="role.defaultTwoFactorAuth" autofocus /> -->
                                                                <ui-switch [(ngModel)]="role.defaultTwoFactorAuth">
                                                                </ui-switch>
                                                            </td>
                                                        </tr>
                                                    </div>
                                                    <div class="button-style">
                                                        <button pButton pRipple label="{{ 'StudyProgram.Cancel' | translate }}" icon="pi pi-times"
                                                            class="p-button-text" (click)="cancelRole()"></button>
                                                        <button style="color: green;" pButton pRipple label="{{ 'StudyProgram.Save' | translate }}"
                                                            icon="pi pi-check" class="p-button-text"
                                                            (click)="saveRole()"></button>
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>