import { AppSettings } from 'src/app/common/app.settings';
import { AuthenticationService } from './../services/authentication/authentication.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { AppUtilities } from '../common/app.utilities';
import { concat } from 'rxjs';
import { UploadService } from '../services/upload.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate() {
    if(AppUtilities.isUserAuthenticated()){
      return true;
    }
    this.router.navigate(['auth/login']);
    return false;
  }
}
